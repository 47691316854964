import ExportIcon from '../../../assets/icon/ic-export.png'
import './exportButton.scss'

const ExportButton = ({ onClick }) => {
  return (
    <div className="export-btn-container" onClick={onClick}>
      <img src={ExportIcon} alt="" />
      <p>Export</p>
    </div>
  )
}

export default ExportButton