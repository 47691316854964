import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import './ImportModal.scss'
import { useTranslation } from "react-i18next";
import ImportIcon from '../../../assets/icon/ic-uplaod-file.png'
import SecondaryButton from "../../Button/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../Button/PrimaryButton/PrimaryButton";

const ImportModal = ({ isOpen, toggle, onFinish, title, template, loading }) => {
  const { t } = useTranslation()
  const [dragActive, setDragActive] = useState(false);
  const [dropAreaText, setDropAreaText] = useState('Drop file or click to upload')
  const [selectedFile, setSelectedFile] = useState(null)

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);

    setDropAreaText(e.dataTransfer.files[0].name)
    setSelectedFile(e.dataTransfer.files[0])
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    setDragActive(true);
    setDropAreaText(e.target.files[0].name)
    setSelectedFile(e.target.files[0])
  };

  const handleRemove = () => {
    setSelectedFile(null)
    setDragActive(false)
    setDropAreaText('Drop file or click to upload')
  }



  const applyImport = async () => {
    onFinish(selectedFile)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="import-modal">
      <ModalHeader className="import-modal-header" toggle={toggle}>
        <h1>{title}</h1>
      </ModalHeader>
      <ModalBody className="import-modal-body">
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input type="file" id="input-file-upload" multiple={true} accept=".xlsx" onChange={handleChange} />
          <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
            {selectedFile === null &&
              <img src={ImportIcon} alt="" />
            }
            <p>{dropAreaText}</p>
          </label>
          {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
          {dropAreaText !== 'Drop file or click to upload' &&
            <h6 className="remove-file" onClick={handleRemove}>X {t('removeImport')}</h6>
          }
        </form>
        <p className="download-text">
          {t('importDownloadText')} <a href={`${process.env.REACT_APP_IMG_URL}${template}`} target="_blank" rel="noreferrer noopener" className="download-link">
            {t('importDownload')}
          </a>
        </p>
      </ModalBody>
      <ModalFooter className="import-modal-footer">
        <SecondaryButton
          text={t('cancel')}
          onClick={toggle}
        />
        <PrimaryButton
          text={t('apply')}
          onClick={applyImport}
          loading={loading}
          disabled={selectedFile === null ? true : false}
        />
      </ModalFooter>
    </Modal>
  )
}

export default ImportModal;