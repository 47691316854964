import Pagination from 'rc-pagination';
import ImportButton from '../../Button/ImportButton/ImportButton';
import InputSearch from '../../input/InputSearch/input-search';
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import './Pagination.scss'
import './CustomTable.scss'
import { useEffect, useState } from 'react';
import { LoadingScreenSpinner } from '../../loadingScreen/loadingScreen';
import { EmptyData } from '../../emptyData/EmptyData';

const CustomTable = (props) => {
  const {
    title,
    columns,
    data,
    totalData,
    page,
    setPage,
    pageSize,
    setPageSize,
    loading,
    setLoading,
    onChangePage,
    checkboxes,
    searchFilter,
    setSearch,
    withPagination,
    scrollX,
    selectedData,
    setSelectedData,
    ImportBtn,
    FilterButton,
    MiscButton
  } = props

  const [selectedRow, setSelectedRow] = useState([])
  // const [selectedData, setSelectedData] = useState([])

  const selectAllRow = () => {
    const allId = data.map((dat) => dat.id)
    if (selectedRow.every(element => element === true)) {
      const falseRow = selectedRow.map((set) => false)
      setSelectedRow(falseRow)

      const removeData = selectedData.filter(val => !allId.includes(val));
      setSelectedData(removeData)
    } else {
      const trueRow = selectedRow.map((set) => true)
      setSelectedRow(trueRow)

      let arr = selectedData.concat(allId);
      let allData = [...new Set(arr)]
      setSelectedData(allData)
    }
  }

  const deselectAllRow = () => {
    const falseRow = selectedRow.map((set) => false)
    setSelectedRow(falseRow)
    setSelectedData([])
  }

  const handleRow = (position, dataTarget) => {
    const updateselected = selectedRow.map((item, index) => {
      return index === position ? !item : item
    })
    setSelectedRow(updateselected)
    const findData = selectedData.find((dat) => dataTarget.id === dat)
    if (findData === undefined) {
      setSelectedData((sel) => [...sel, dataTarget.id])
    } else {
      const newData = selectedData.filter((dat) => dataTarget.id !== dat)
      setSelectedData(newData)
    }
  }

  const checkSelectedData = () => {
    let rowIndex = []
    data?.map((e, index) => {
      let check = false
      selectedData.map(raw => {
        if (raw === e.id) {
          check = true
        }
      })
      rowIndex.push(check)
    })
    setSelectedRow(rowIndex)
  }

  useEffect(() => {
    selectedData && checkSelectedData()
    if (data?.length === 0 && page > 1) {
      setPage(page - 1)
    }
  }, [data])

  return (
    <div className="custom-table table-responsive">
      <div className="title-table">
        <h1>{title}</h1>
        <hr />
      </div>
      <div className="modify-table">
        {FilterButton && MiscButton &&
          MiscButton.map((Btn) => {
            return <Btn />
          })
        }
      </div>
      <div className="filter-table">
        <div className='search-filter'>
          {searchFilter &&
            <InputSearch
              placeholder='Masukkan kata kunci...'
              onFinish={(val) => setSearch(val)}
            />
          }
        </div>
        <div className="btn-filter">
          {FilterButton &&
            FilterButton.map((Btn) => {
              return <Btn />
            })
          }
          {!FilterButton && MiscButton &&
            MiscButton.map((Btn) => {
              return <Btn />
            })
          }
        </div>
      </div>
      {withPagination && withPagination === 'top' && data?.length > 0 &&
        <Pagination
          showTotal={(total, range) =>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '2rem'
              }}
            >
              {selectedData?.length > 0 &&
                <p
                  style={{
                    margin: 0,
                    fontSize: '0.875rem'
                  }}
                >
                  <b>{selectedData?.length}</b> Data selected,
                  <span
                    style={{
                      color: '#0196FA',
                      textDecoration: 'underline',
                      marginLeft: '0.3rem'
                    }}
                    onClick={deselectAllRow}
                  >deselect all</span>
                </p>
              }
              <p
                style={{
                  margin: 0,
                  fontSize: '0.875rem'
                }}
              >Showing {range[0]} - {range[1]} of {total} items</p>
            </div>
          }
          total={totalData}
          current={page}
          // defaultCurrent={page}
          pageSize={pageSize}
          nextIcon={<GrFormNext />}
          prevIcon={<GrFormPrevious />}
          onChange={(a) => {
            // setPage(a)
            onChangePage(a)
          }}
        />
      }
      <div
        style={{
          width: '100%',
          ...(scrollX) && { overflowX: 'scroll' }
        }}
      >
        <table
          id="table"
          className="table"
          data-toggle="table"
          data-search="true"
          data-filter-control="true"
          data-show-export="true"
          data-click-to-select="true"
          data-toolbar="#toolbar"
          style={{
            width: `${scrollX && data?.length > 0 ? scrollX + 'rem' : '100%'}`
          }}
        >
          <thead>
            <tr style={{ textAlign: 'center' }}>
              {checkboxes && data?.length > 0 &&
                <th
                  class="bs-checkbox"
                  data-field="state"
                  data-checkbox="true"
                  style={{
                    verticalAlign: 'middle',
                    textAlign: 'left'
                  }}
                >
                  <input
                    data-index="0"
                    name="btSelectItem"
                    type="checkbox"
                    onChange={selectAllRow}
                    checked={selectedRow.every(element => element === true) === true ? true : false}
                  />
                </th>
              }
              {columns.map(col => {
                return (
                  <th
                    data-field="prenom"
                    data-filter-control="input"
                    data-sortable="true"
                    style={{
                      width: col?.width,
                      verticalAlign: 'middle'
                    }}
                  >{col.title}</th>
                )
              })}
            </tr>
          </thead>
          {!loading && data !== '' &&
            <tbody>
              {data?.map((dat, index) => {
                return (
                  <tr
                    key={index}
                  >
                    {checkboxes &&
                      <td
                        class="bs-checkbox "
                        style={{
                          verticalAlign: 'middle'
                        }}
                      >
                        <input
                          data-index="0"
                          name="btSelectItem"
                          type="checkbox"
                          onChange={() => handleRow(index, dat)}
                          checked={selectedRow[index]}
                        />
                      </td>
                    }
                    {columns.map((col, i) => {
                      if (col.render) {
                        const cek = col?.render(dat[col.dataIndex], dat)
                        return (
                          <td
                            key={i}
                            style={{
                              textAlign: col.align ? col.align : 'left',
                              verticalAlign: 'middle'
                            }}
                          >{cek}</td>
                        )
                      }
                      return (
                        <td
                          key={i}
                          style={{
                            textAlign: col.align ? col.align : 'left',
                            verticalAlign: 'middle'
                          }}
                        >{dat[col.dataIndex]}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          }
        </table>
      </div>
      {loading &&
        <LoadingScreenSpinner />
      }
      {data?.length == 0 &&
        <EmptyData />
      }
      {withPagination && withPagination === 'bottom' &&
        <Pagination
          showTotal={(total, range) =>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: '2rem'
              }}
            >
              {selectedData?.length > 0 &&
                <p
                  style={{
                    margin: 0,
                    fontSize: '0.875rem'
                  }}
                >
                  <b>{selectedData?.length}</b> Data selected,
                  <span
                    style={{
                      color: '#0196FA',
                      textDecoration: 'underline',
                      marginLeft: '0.3rem'
                    }}
                    onClick={deselectAllRow}
                  >deselect all</span>
                </p>
              }
              <p
                style={{
                  margin: 0,
                  fontSize: '0.875rem'
                }}
              >Showing {range[0]} - {range[1]} of {total} items</p>
            </div>
          }
          total={totalData}
          current={page}
          defaultCurrent={page}
          pageSize={pageSize}
          nextIcon={<GrFormNext />}
          prevIcon={<GrFormPrevious />}
          onChange={(a) => {
            // setPage(a)
            onChangePage(a)
          }}
        />
      }
    </div>
  )
};

export default CustomTable