import axios from "axios";
import { format } from "date-fns";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import * as xlsx from "xlsx";
import './SuccessModal.scss'
import { errorPopup, networkErrorPopup } from "../../Alert/PopUp/ErrorPopUp";
import { useTranslation } from "react-i18next";
import SuccessIcon from '../../../assets/icon/popup/ic-popup-success.png'
import { LoadingScreen } from "../../loadingScreen/loadingScreen";
import PrimaryButton from "../../Button/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../Button/SecondaryButton/SecondaryButton";

const SuccessModal = ({ isOpen, toggle }) => {
  const { t } = useTranslation()
  const [loadingScreen, setLoadingScreen] = useState(false)

  useEffect(() => {
    if (isOpen.duration) {
      setTimeout(() => {
        toggle()
      }, isOpen.duration);
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen.visible} toggle={toggle} className="success-modal">
      {loadingScreen && <LoadingScreen />}
      <ModalBody className="success-modal-body">
        <img src={SuccessIcon} alt="" />
        <p className="modal-text">{isOpen.message}</p>
      </ModalBody>
      {!isOpen.duration &&
        <ModalFooter className="success-modal-footer">
          <PrimaryButton
            text={t('gotit')}
            onClick={toggle}
          />
        </ModalFooter>
      }
    </Modal>
  )
}

export default SuccessModal;