import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import '../styles/EditModalSKU.scss'
import { useTranslation } from "react-i18next";
import { LoadingScreen } from "../../../../Component/loadingScreen/loadingScreen";
import SecondaryButton from "../../../../Component/Button/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../../../Component/Button/PrimaryButton/PrimaryButton";
import { useForm } from "react-hook-form";
import InputNumber from "../../../../Component/input/InputNumber";
import InputText from "../../../../Component/input/InputText";
import InputTextArea from "../../../../Component/input/InputTextArea";

const EditModalSKU = ({ isOpen, toggle, onFinish, loading, title }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useForm();
  const { t } = useTranslation()
  const [loadingScreen, setLoadingScreen] = useState(false)

  const onSubmit = (val) => {
    onFinish(val)
  }

  useEffect(() => {
    if (isOpen.data) {
      setValue('name', isOpen?.data?.name)
      setValue('code', isOpen?.data?.code)
      setValue('temperature', isOpen?.data?.temperature)
      setValue('length', isOpen?.data?.length)
      setValue('height', isOpen?.data?.height)
      setValue('width', isOpen?.data?.width)
      setValue('weight', isOpen?.data?.weight)
      setValue('material_description', isOpen?.data?.material_description)
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen.visible} toggle={toggle} className="edit-sku">
      {loadingScreen && <LoadingScreen />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="edit-sku-header" toggle={toggle}>
          <h1>{title}</h1>
        </ModalHeader>
        <ModalBody className="edit-sku-body">
          <Row>
            <Col>
              <InputText
                label={`${t('crossdock.nameSKU')}`}
                name="name"
                placeholder={t('crossdock.nameSKUPlaceholder')}
                errors={errors}
                register={register}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputText
                label={`${t('crossdock.codeSKU')}`}
                name="code"
                placeholder={t('crossdock.codeSKUPlaceholder')}
                errors={errors}
                register={register}
              />
            </Col>
            <Col>
              <InputNumber
                label={t('temperature')}
                name="temperature"
                placeholder={t('temperaturePlaceholder')}
                errors={errors}
                register={register}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputNumber
                label={t('length')}
                name="length"
                placeholder={t('lengthPlaceholder')}
                errors={errors}
                register={register}
              />
            </Col>
            <Col>
              <InputNumber
                label={t('width')}
                name="width"
                placeholder={t('widthPlaceholder')}
                errors={errors}
                register={register}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputNumber
                label={t('height')}
                name="height"
                placeholder={t('heightPlaceholder')}
                errors={errors}
                register={register}
              />
            </Col>
            <Col>
              <InputNumber
                label={t('weight')}
                name="weight"
                placeholder={t('weightPlaceholder')}
                errors={errors}
                register={register}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputTextArea
                label={`${t('crossdock.descSKU')}`}
                name="material_description"
                placeholder={t('crossdock.descSKUPlaceholder')}
                errors={errors}
                register={register}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="edit-sku-footer">
          <SecondaryButton
            text={t('cancel')}
            onClick={toggle}
          />
          <PrimaryButton
            text={t('apply')}
            loading={loading}
          />
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default EditModalSKU;