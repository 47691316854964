import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import '../../../styles/deliveryService/orderConfirm/modal/summaryOrder.scss'
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../authContext/AuthContext";
import SuccessIcon from '../../../assets/icon/ic-invoice-paid.png'
import { numberFormat } from "../../numberFormat/numberFormat";
import PrimaryButton from "../../Button/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../Button/SecondaryButton/SecondaryButton";
import { useEvent } from "@dnd-kit/utilities";

const SummaryOrder = ({ isOpen, toggle }) => {
  const { data } = isOpen
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal className="order-summary" isOpen={isOpen?.visible}>
      <ModalHeader className="order-summary-header">
        <img src={SuccessIcon} alt="" />
        <h6>{t('orderSuccess')}</h6>
      </ModalHeader>
      <ModalBody className="order-summary-body">
        <h1 className="title-body">{t('summaryOrder')}</h1>
        <div className="order-info">
          <h1 className="title">{t('serviceInfo')}</h1>
          <div className="date-pickup">
            <h3>{t('serviceType')}</h3>
            <h3>{data?.serviceName}</h3>
          </div>
          <div className="date-pickup">
            <h3>{t('vehicleType')}</h3>
            <h3>{data?.vehicleType}</h3>
          </div>
        </div>
        <div className="trip-info">
          <h1 className="title">{t('tripInfo')}</h1>
          {data?.trip_planning.map((trip, index) => {
            return (
              <>
                <h1 className="number-trip">Trip {index + 1}</h1>
                <div className="trip-detail">
                  <h3>{t('distance')}</h3>
                  <h3>{trip?.distance} Km</h3>
                </div>
                <div className="trip-detail">
                  <h3>{t('totalDrop')}</h3>
                  <h3>{trip?.drop.length} Drop</h3>
                </div>
                <div className="trip-detail">
                  <h3>{t('tripPrice')}</h3>
                  <h3>Rp. {numberFormat(trip?.price)}</h3>
                </div>
              </>
            )
          })}
        </div>
        {data?.additionalService.length !== 0 && data?.additionalService.find((a) => a.is_mandatory !== 1) !== undefined ?
          <div className="additional">
            <h1 className="title">{t('additionalService')}</h1>
            {data.additionalService.map((add) => {
              if (add.price !== null)
                return (
                  add.is_mandatory == 1 ? null :
                    <div className="list-service">
                      <h3>{add.name} x {data.totalDestination} Drop</h3>
                      <h3 className="price-service">Rp {numberFormat(add.price * data.totalDestination)}</h3>
                    </div>
                )
            })
            }
          </div>
          : null
        }
        {data?.totalAdditional > 0 &&
          <>
            <hr />
            <div className="additional">
              <h1 className="title">{t('additionalService')}</h1>
              <div className="list-service">
                <h3>Total {t('additionalService')}</h3>
                <h3 className="price-service">Rp {numberFormat(data?.totalAdditional)}</h3>
              </div>
            </div>
          </>
        }
        {data?.totalPublicHolidayPrice != 0 ?
          <>
            <hr />
            <div className="public-holiday">
              {/* <img src={InfoIconWhite} alt="" /> */}
              <h4>{t('publicHolidayText')}</h4>
            </div>
          </>
          : null}
        <div className="payment-info">
          <h1 className="title">{t('invoiceInfo')}</h1>
          <div>
            <h3>{t('subtotal')}</h3>
            <h3>Rp {numberFormat(data?.subTotal)}</h3>
          </div>
          {data?.promoCode !== " " &&
            <div>
              <h3>{t('discount')}</h3>
              <h3>Rp {numberFormat(data?.promoAmount)}</h3>
            </div>
          }
        </div>
        <div className="total-price">
          <div>
            <h3>{t('total')}</h3>
            <h3>Rp {numberFormat(data?.priceTotal)}</h3>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="order-summary-footer">
        <SecondaryButton
          text={t('seeDetailOrder')}
          style={{
            width: '50%',
          }}
          onClick={() => navigate(`/transaction/detail-transaction/${data?._id}`)}
        />
        <PrimaryButton
          text={t('backHome')}
          style={{
            width: '50%',
          }}
          onClick={() => navigate(`/`)}
        />
      </ModalFooter>
    </Modal>
  )
}

export default SummaryOrder;