import axios from "axios";
import React, { useEffect, useState } from "react";
import DriverAvatar from '../../../../assets/img/img-avatar-1.png'
import PickIconMini from '../../../../assets/icon/ic-location-blue-1.png'
import DropIconMini from '../../../../assets/icon/ic-location-yellow-1.png'
import TestImageTripDone from '../../../../assets/img/img-hero-driver.png'
import { AiFillStar } from "react-icons/ai";
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import '../../../../styles/transactionDetail/transactionViewTrip/transactionDetailPoint/transactionDetailPoint.scss'
import { LoadingScreen } from "../../../loadingScreen/loadingScreen";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../../../numberFormat/numberFormat";
import { format, parse } from "date-fns";

export const DetailPoint = ({ isOpen, toggle, data }) => {
  const { t } = useTranslation()
  const [detailPointData, setDetailPointData] = useState({})

  const url = process.env.REACT_APP_URL_CUST
  const access_token = localStorage.getItem("spkl:c015d311v")
  const headers = {
    'Authorization': `${access_token}`
  }

  useEffect(() => {
    setDetailPointData(data)
  }, [isOpen])

  if (detailPointData === undefined) return null

  return (
    <Modal isOpen={isOpen.visible} toggle={toggle} className="transaction-detail-point">
      <ModalHeader className="transaction-detail-point-header" >
        <div className="header-content">
          <div>
            {detailPointData?.job === 'pickup' ?
              <h3 className="title">PICKUP POINT</h3>
              :
              <h3 className="title">DROP POINT {detailPointData?.defaultOrder}</h3>
            }
          </div>
          {/* {detailPointData.status === "pending" &&
            <Badge className="status pending">{detailPointData.status}</Badge>
          }
          {detailPointData.status === "progress" || detailPointData.status === "onprogress" ?
            <Badge className="status onprogress">{detailPointData.status}</Badge>
            : null
          }
          {detailPointData.status === "done" || detailPointData.status === "finish" ?
            <Badge className="status done">{detailPointData.status}</Badge>
            : null
          } */}
        </div>
      </ModalHeader>
      <ModalBody className="transaction-detail-point-body" style={{ marginTop: '-2rem' }}>
        {/* {detailPointData.job !== 'pickup' &&
          <div className="estimation-actual">
            <div className="estimation">{t('estimation')} <br /> {Math.floor(detailPointData.duration / 3600)} {t('hour')} {Math.floor(detailPointData.duration % 3600 / 60)} {t('minute')} {`(${detailPointData.distance} km)`}
            </div>
          </div>
        } */}
        <div className="address">
          <div className="address-info">
            <img src={detailPointData?.job === 'pickup' ? PickIconMini : DropIconMini} alt="" />
            <div className="address-data">
              <h3 className="address-label">{
                detailPointData?.item?.pickLabel ||
                detailPointData?.item?.dropOff?.dropLabel ||
                detailPointData?.item?.dropLabel
              }
              </h3>
              <p className="address-loc">{
                detailPointData?.item?.pickAddress ||
                detailPointData?.item?.dropOff?.dropAddress ||
                detailPointData?.item?.dropAddress
              }
              </p>
              <h4 className="address-note">{t('notes')}</h4>
              <p className="address-note-content">{
                detailPointData?.item?.pickNotes ||
                detailPointData?.item?.dropOff?.dropNotes ||
                detailPointData?.item?.dropNotes
              }
              </p>
            </div>
          </div>
        </div>
        <div className="contact">
          <h2 className="title">{t('customerContact')}</h2>
          <div className="contact-info-content">
            <div className="name">
              {detailPointData?.job === 'pickup' ?
                <h4>{t('senderName')}</h4>
                :
                <h4>{t('receiverName')}</h4>
              }
              <p className="desc">{
                detailPointData?.item?.senderName ||
                detailPointData?.item?.dropOff?.receiverName ||
                detailPointData?.item?.receiverName
              }
              </p>
            </div>
            <div className="phone-number">
              <h4>{t('phoneNumber')}</h4>
              <p className="desc">{
                detailPointData?.item?.pickPhoneNumber ||
                detailPointData?.item?.dropOff?.dropPhoneNumber ||
                detailPointData?.item?.dropPhoneNumber
              }
              </p>
            </div>
          </div>
        </div>
        <div className="list-packages">
          <div className="packages-header">
            {detailPointData?.job === 'pickup' ?
              null
              :
              <>
                <h2 className="title">{t('listDropPackage')}</h2>
              </>
            }
          </div>
          <div className="packages">
            {detailPointData?.job === 'dropoff' &&
              <>
                <div className="packages-data">
                  <h6>{detailPointData?.item?.itemCategory}</h6>
                  <p>{detailPointData?.item?.lenght} x {detailPointData?.item?.width} x {detailPointData?.item?.height}, {detailPointData?.item?.weight}kg, {detailPointData?.item?.itemTmp}&deg; C</p>
                </div>
                {detailPointData?.item?.soNumber?.length !== 0 &&
                  <div className="packages-data">
                    <h6>SO Number</h6>
                    <p>{detailPointData?.item?.soNumber?.join('; ')}</p>
                  </div>
                }
              </>
            }
          </div>
        </div>
        {detailPointData?.job !== 'pickup' && detailPointData?.additionalService?.length > 0 &&
          <div className="pickup-proven">
            <h1 className="title">{t('additionalService')}</h1>
            <div className="proven-data">
              {detailPointData?.additionalService?.map((add) => {
                return (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="desc" style={{ fontWeight: 700 }}>{add.name}</p>
                    <p className="desc">Rp. {numberFormat(add.price)}</p>
                  </div>
                )
              })}
            </div>
          </div>
        }
      </ModalBody>
      <ModalFooter className="transaction-detail-point-footer">
        <Button className="close" onClick={toggle}>
          {t('close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
