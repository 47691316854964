import React, { useContext, useEffect } from "react";
import "../../styles/addAddress/addAddress.scss";
import { BsFillRecordCircleFill } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { useState } from "react";
import { AddAddress } from "./Modal/AddAddress";
import { EditAddress } from "./Modal/EditAddress";
import { DeleteAddress } from "./Modal/DeleteAddress";
import DeleteIcon from "../../assets/icon/ic-delete.png"
import AlertFailedIcon from "../../assets/icon/alert/ic-alert-failed.png"
import AlertWarningIcon from "../../assets/icon/alert/ic-alert-warning.png"
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { EmptyData } from "../emptyData/EmptyData";
import { errorPopup, networkErrorPopup } from "../Alert/PopUp/ErrorPopUp";
import { AuthContext } from "../authContext/AuthContext";
import ImportButton from "../Button/ImportButton/ImportButton";
import ImportModal from "../Modal/ImportModal/ImportModal";
import SuccessModal from "../Modal/SuccessModal/SuccessModal";
import { sendPostMultipartRequest } from "../../services/request-adapter";
import toast from "react-hot-toast";
import CustomAlert from "../Alert/Alert";
import { Badge } from "reactstrap";

export const SavedAddress = () => {
  const context = useContext(AuthContext)
  const [addAddress, setAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [deleteAddress, setDeleteAddress] = useState(false);
  const [dataAddress, setDataAddress] = useState([])
  const [selectedAddress, setSelectedAddress] = useState({})
  const [placeholder, setPlaceholder] = useState(true)
  const { t } = useTranslation()
  const [toggleImportModal, setToggleImportModal] = useState(false)
  const [successModal, setSuccessModal] = useState({ visible: false })
  const [loadingModal, setLoadingModal] = useState(false)

  const url = process.env.REACT_APP_URL_CUST
  const access_token = localStorage.getItem("spkl:c015d311v")
  const headers = {
    'Authorization': `${access_token}`
  }

  useEffect(() => {
    getDataAddress()
  }, [])


  const getDataAddress = async () => {
    setPlaceholder(true)
    setDataAddress([])
    try {
      const response = await axios.get(`${url}/api/bookmark/${context?.id}`, { headers })
      const data = response.data.data
      setPlaceholder(false)
      setDataAddress(data)
    } catch (error) {
      console.log(error.message)
      // if(error.message === 'Network Error') {
      //   networkErrorPopup(t('networkErrorTitle'),t('networkErrorText'), t('reload'), t('cancel'))
      // } 
    }
  }

  const handleDataImport = async (file) => {
    if (file === null) {
      errorPopup(t('error'), 'Pilih file terlebih dahulu!', t('close'))
      return
    }
    let payload = { file }
    setLoadingModal(true)
    try {
      await sendPostMultipartRequest(`/bookmark-address/import`, payload)


      getDataAddress()
      setToggleImportModal(false)
      setSuccessModal({
        visible: true,
        message: 'Import Address berhasil',
        duration: 3000
      })
    } catch (error) {
      console.log(error)
      if (error.response.data.message === 'The file must be a file of type: xlsx.') {
        toast((t) => (
          <CustomAlert
            id={t.id}
            message={error.response.data.message}
            icon={AlertFailedIcon} />
        ), {
          style: {
            background: '#FFE7E0'
          }
        });
      } else {
        toast((t) => (
          <CustomAlert
            id={t.id}
            message={error.response.data.message}
            icon={AlertWarningIcon} />
        ), {
          style: {
            background: '#FFF8D6'
          }
        });
      }
    } finally {
      setLoadingModal(false)
    }
    // getAllSKUData()
  }


  const handleEdit = (item) => {
    setSelectedAddress(item)
    setEditAddress(true)
  }

  const handleDelete = (item) => {
    setSelectedAddress(item)
    setDeleteAddress(true)
  }
  return (
    <div className="saved-address">
      <div className="header-saved-address">
        <p className="title">{t('savedAddress')}</p>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <ImportButton
            text={`Import ${t('address')}`}
            onClick={() => setToggleImportModal(true)}
          />
          <button className="add-address" onClick={() => setAddAddress(true)}>{t('addAddress')}</button>
        </div>
      </div>
      <div className="body-saved-address">
        {placeholder &&
          <div className="item-address">
            <div className="icon-list">
              <BsFillRecordCircleFill className="list-icon" />
            </div>
            <div className="text-list">
              <p class="placeholder-glow col-8">
                <span class="placeholder col-6 placeholder rounded-1"></span>
                <span class="placeholder col-12  placeholder-xs rounded-2"></span>
              </p>
            </div>
            <div className="btn-container">
              <button className="edit-btn">{t('edit')}</button>
              <button className="delete-btn">
                <img src={DeleteIcon} className="icon-delete" />{t('delete')}
              </button>
            </div>
          </div>
        }
        {
          dataAddress?.length !== 0 ?
            dataAddress?.map((item, index) => (
              <div className="item-address" key={item.id}>
                <div className="icon-list">
                  <BsFillRecordCircleFill className="list-icon" />
                </div>
                <div className="text-list">
                  <p className="text-place" style={{ display: 'flex', alignItems: 'center', gap: "0.3rem" }}>{item.label} <Badge>{item.tag}</Badge>
                  </p>
                  <p className="text-street">
                    {item.address}
                  </p>
                  {item.name === null && item.phone === null && item.notes === null ? null :
                    <p className="text-street">
                      {item.name || '-'}  |  {item.phone === null ? '-' : item.phone}  |  {item.notes || '-'}
                    </p>
                  }
                  {/* <p className="text-note">Note</p>
                <p className="text-detail">{item.detail}</p> */}
                </div>
                <div className="btn-container">
                  <button className="edit-btn" onClick={() => handleEdit(item)}>{t('edit')}</button>
                  <button className="delete-btn" onClick={() => handleDelete(item)}>
                    <img src={DeleteIcon} className="icon-delete" />{t('delete')}
                  </button>
                </div>
              </div>
            ))
            :
            !placeholder && <EmptyData />
        }
      </div>
      <AddAddress open={addAddress} onClose={() => setAddAddress(false)} reload={getDataAddress} />
      <EditAddress openEdit={editAddress} closeEdit={() => setEditAddress(false)} item={selectedAddress} reload={getDataAddress} />
      <DeleteAddress openDelete={deleteAddress} closeDelete={() => setDeleteAddress(false)} item={selectedAddress} reload={getDataAddress} />

      <ImportModal
        title={`Import ${t('savedAddress')}`}
        isOpen={toggleImportModal}
        toggle={() => setToggleImportModal(!toggleImportModal)}
        onFinish={(file) => handleDataImport(file)}
        template="/templates/Template+Import+Bookmark.xlsx"
        loading={loadingModal}
      />

      <SuccessModal
        isOpen={successModal}
        toggle={() => setSuccessModal({ visible: false })}
      />
    </div>

  );
};
