import axios from "axios";
import React, { useEffect, useState } from "react";
import "./UploadFile.scss"
import ImportIcon from '../../../assets/icon/ic-uplaod-file.png'
import FileIcon from '../../../assets/icon/crossdock/ic-document.png'
import RemoveIcon from '../../../assets/icon/crossdock/ic-close.png'

const UploadFile = ({ uploadedFile, setUploadedFile }) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [dropAreaText, setDropAreaText] = useState('Drop file or click to upload')
  const [selectedFile, setSelectedFile] = useState(null)

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);

    setDropAreaText(e.dataTransfer.files[0].name)
    setSelectedFile(e.dataTransfer.files[0])
    setUploadedFile(e.dataTransfer.files[0])
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    setDragActive(true);
    setDropAreaText(e.target.files[0].name)
    setSelectedFile(e.target.files[0])
    setUploadedFile(e.target.files[0])
  };

  const handleRemove = () => {
    setSelectedFile(null)
    setUploadedFile(null)
    setDragActive(false)
    setDropAreaText('Drop file or click to upload')
  }

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <form
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          type="file"
          id="input-file-upload"
          multiple={false}
          accept=".xlsx"
          onChange={handleChange}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? "drag-active" : ""}
        >
          {selectedFile === null ?
            <>
              <img src={ImportIcon} alt="" />
              <p style={{ color: "#1F83BB" }}>
                {dropAreaText}
              </p>
            </>
            :
            <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
              }}
            >
              <img src={FileIcon} alt="" />
              {dropAreaText}
            </p>
          }
        </label>

        {dragActive &&
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        }

        {dropAreaText !== 'Drop file or click to upload' &&
          <div
            className="remove-file"
            onClick={handleRemove}
          >
            <img src={RemoveIcon} alt="" />
            <h4

            >
              Remove
            </h4>
          </div>
        }
      </form>
    </div>
  )
}

export default UploadFile;