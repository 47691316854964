import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useJsApiLoader, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api'
import 'rc-slider/assets/index.css';
import axios from "axios";
import { format } from "date-fns";
import ReactGA from "react-ga4";

import { BsArrowLeftShort } from "react-icons/bs";

// Asset
import Logo from "../assets/img/logo.png";

import PickupIcon from '../assets/icon/ic-location-blue.png'
import DropIcon from '../assets/icon/ic-location-yellow.png'
import GrayIcon from '../assets/icon/ic-location-gray.png'
import ConfirmIcon from '../assets/img/img-state-confirmation.png'
import SuccessIcon from '../assets/img/img-state-success.png'
import SuccessIcon2 from '../assets/icon/ic-success.png'
import FailedIcon from '../assets/icon/ic-failed.png'


// Style
import "../styles/deliveryService/deliveryService.scss";
import '../styles/deliveryService/createOrder/createOrder.scss'
import OrderConfirmation from "../Component/CreateOrder/OrderConfirmation";
import { useTranslation } from "react-i18next";
import { numberFormat } from "../Component/numberFormat/numberFormat";
import { errorPopup, networkErrorPopup } from "../Component/Alert/PopUp/ErrorPopUp";
import { PaymentXendit } from "../Component/UI/modal/Delivery/PaymentXendit";
import { Header } from "../Component/header/Header";
import { AuthContext } from "../Component/authContext/AuthContext";
import { storeHitGoogle } from "../services/storeHitGoogle";
import SuccessModal from "../Component/Modal/SuccessModal/SuccessModal";
import CustomModal from "../Component/Modal/CustomModal/CustomModal";
import SummaryOrder from "../Component/CreateOrder/Modal/SummaryOrder";

export const DeliveryOrderConfirm = ({ idx }) => {
  const context = useContext(AuthContext)
  const { t } = useTranslation()
  const { orderid } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const opt = searchParams.get('opt')
  const drft = searchParams.get('drft')
  const prm = searchParams.get('prm')
  const drftNum = searchParams.get('drftNum')
  const [userType, setUserType] = useState(2)
  const navigate = useNavigate()
  const [location, setLocation] = useState([])
  const [allDataOrder, setAllDataOrder] = useState([])
  const [priceTotal, setPriceTotal] = useState(0)
  const [priceTotalWithPromo, setPriceTotalWithPromo] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingPromo, setLoadingPromo] = useState(false)
  const [promoId, setPromoId] = useState(null)
  const [xenditUrl, setXenditUrl] = useState('test')
  const [openPaymentXendit, setOpenPaymentXendit] = useState(false);
  const togglePaymentXendit = () => {
    if (openPaymentXendit) {
      navigate('/')
    } else {
      setOpenPaymentXendit(!openPaymentXendit)
    }
  }
  const [openSummaryOrder, setOpenSummaryOrder] = useState({ visible: false });
  const toggleSummaryOrder = () => {
    setOpenSummaryOrder({ visible: !openSummaryOrder.visible })
  }
  const [failedModalOpen, setFailedModalOpen] = useState({ visible: false })


  // Auth
  const url = process.env.REACT_APP_URL_CUST
  const url_auth = process.env.REACT_APP_DEV_URL
  const access_token = localStorage.getItem("spkl:c015d311v")
  const headers = {
    'Authorization': `${access_token}`
  }


  const center = {
    lat: -6.1722143,
    lng: 106.7657932
  }
  const [loadingMapScreen, setLoadingMapScreen] = useState(false)
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [libraries] = useState(['places']);

  const tripsssssss = 13
  const coverTripsss = 3


  useEffect(() => {
    setLoading(true)
    setLoadingMapScreen(true)
    getOrderData()
  }, [])

  const getOrderData = async () => {
    // User Type
    try {
      const response = await axios.get(`${url_auth}/profile`, {
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
      const data = response.data.data
      setUserType(data[0].type)
    } catch (error) {
      console.log(error.message)
    }

    try {
      const response = await axios.get(`${url}/api/tmp-order/${orderid}`, { headers })

      setAllDataOrder(response.data.data)
      if (response.data.data.service_name.name.includes('Superkul Truck')) {
        setPriceTotal(response.data.data.superkulPriceTotal)
        setPriceTotalWithPromo(response.data.data.superkulPriceTotal)
      } else {
        if (response.data.data.optimize) {
          setPriceTotal(response.data.data.priceTotalOpt)
          setPriceTotalWithPromo(response.data.data.priceTotalOpt)
          // setPriceTotal(response.data.data.priceTotal + response.data.data.additionalService.reduce((val, element) => {return val + element.price}, 0))
          // setPriceTotalWithPromo(response.data.data.priceTotal + response.data.data.additionalService.reduce((val, element) => {return val + element.price}, 0))
        } else {
          setPriceTotal(response.data.data.priceTotal)
          setPriceTotalWithPromo(response.data.data.priceTotal)
          // setPriceTotal(response.data.data.priceTotalOpt + response.data.data.additionalService.reduce((val, element) => {return val + element.price}, 0))
          // setPriceTotalWithPromo(response.data.data.priceTotalOpt + response.data.data.additionalService.reduce((val, element) => {return val + element.price}, 0))
        }
      }
      setLoadingMapScreen(false)
      setLoadingPromo(false)
      setLoading(false)
    } catch (error) {
      console.log(error.message)
    }
  }


  const handlePayment = () => {
    // setOpenSummaryOrder({ visible: true })
    // return

    const data = {
      tmpOrderId: orderid,
      promoId: promoId,
      optimize: opt == 0 ? false : true
    }



    Swal.fire({
      title: allDataOrder?.service_name?.name.includes('Superkul Truck') ? `${t('continueOrder')}?` : `${t('continuePayment')}?`,
      html: allDataOrder?.service_name?.name.includes('Superkul Truck') ? "Pesanan anda akan diproses oleh Tim Superkul" : t('invoicePayment'),
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: t('no'),
      confirmButtonText: t('yes'),
      confirmButtonColor: '#1F83BB',
      reverseButtons: true,
      imageUrl: ConfirmIcon,
      customClass: {
        popup: 'popup-swal',
        title: 'title-swal',
        htmlContainer: 'text-swal',
        confirmButton: 'confirm-swal',
        cancelButton: 'cancel-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingMapScreen(true)
        // axios get createOrder
        const tmpOrderUrl = context?.userType === 3 && !allDataOrder?.service_name?.name.includes('Superkul Truck') ? '/api/order-corporate' : '/api/order'

        try {
          const response = await axios.post(`${url}${tmpOrderUrl}`, data, { headers })
          setLoadingMapScreen(false)
          let a = response.data.data[1]

          if (response.data.status === 'other') {
            ReactGA.event({
              category: "order_category",
              action: "custweb_order_confirm_api_notavail",
              // label: "start_delivery_label", 
              // value: 99
            });
            // Swal.fire({
            //   title: t('driverNotAvail'),
            //   text: response.data.message,
            //   // title: t('driverNotAvail'),
            //   // text: t('driverNotAvailText'),
            //   imageUrl: ConfirmIcon,
            //   showConfirmButton: true,
            //   confirmButtonColor: '#1F83BB',
            //   confirmButtonText: t('close'),
            //   customClass: {
            //     popup: 'popup-swal',
            //     title: 'title-swal',
            //     htmlContainer: 'text-swal',
            //     confirmButton: 'confirm-swal'
            //   }
            // })
            setFailedModalOpen({
              visible: true,
              confirmText: t('close'),
              icon: ConfirmIcon,
              message:
                <>
                  <p>{t('driverNotAvailText')}</p>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    {Array(Math.ceil(allDataOrder?.trip_planning?.length / 5)).fill(1).map((_, indexSide) => {
                      return (
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          {Array(a).fill(1).map((_, index) => {
                            if (index > 4) {
                              return
                            }
                            a--
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  width: Math.ceil(allDataOrder?.trip_planning?.length / 5) < 3 ? "30%" : "70%",
                                  marginInline: "auto",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  marginTop: "0.8rem",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "1rem",
                                    margin: 0,
                                  }}
                                >Trip {(a - allDataOrder?.trip_planning?.length) * -1}</p>
                                <img
                                  src={(a - allDataOrder?.trip_planning?.length) * -1 <= response.data.data[0] ? SuccessIcon2 : FailedIcon}
                                  alt=""
                                  style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                  }}
                                />
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                </>,
              title: t('driverNotAvail')
            })
            return
          } else {
            const xendit = response.data.data.invoiceLink

            ReactGA.event({
              category: "order_category",
              action: "custweb_order_confirm_api_success",
              // label: "start_delivery_label", 
              // value: 99
            });

            if (userType === 3 && !allDataOrder?.service_name?.name.includes('Superkul Truck')) {
              // Swal.fire({
              //   title: t('orderSuccess'),
              //   timer: 3000,
              //   imageUrl: SuccessIcon,
              //   showConfirmButton: false,
              //   customClass: {
              //     popup: 'popup-swal',
              //     title: 'title-swal'
              //   }
              // }).then(result => {
              //   navigate('/')
              // })
              setOpenSummaryOrder({ visible: true, data: response.data.data })
            } else {
              setXenditUrl(allDataOrder?.service_name?.name.includes('Superkul Truck') ? "" : xendit)
              togglePaymentXendit()
            }
          }

        } catch (error) {
          setLoadingMapScreen(false)
          ReactGA.event({
            category: "order_category",
            action: "custweb_order_confirm_api_error",
            // label: "start_delivery_label", 
            // value: 99
          });
          if (error.message === 'Network Error') {
            networkErrorPopup(t('networkErrorTitle'), t('networkErrorText'), t('reload'), t('cancel'))
          } else {
            errorPopup(t('error'), t('somethingError'), t('close'))
          }
        }

      }
    })
  }

  const handleDiscount = (discountData, isTrue) => {
    if (isTrue) {
      if (discountData.data.discount_type == 0) {
        let subtotal = allDataOrder.priceTotal
        if (opt == 1) {
          subtotal = allDataOrder.priceTotalOpt
        }

        let calculate = subtotal * (discountData.data.amount / 100)

        if (calculate >= discountData.data.max_discount) {
          calculate = discountData.data.max_discount
        }

        setPromoId(discountData?.data.id)
        setPriceTotalWithPromo(priceTotal - calculate)
      }
      if (discountData.data.discount_type == 1) {
        setPromoId(discountData?.data.id)
        setPriceTotalWithPromo(priceTotal - discountData.data.amount)
      }
    } else {
      setLoadingPromo(true)
      setPromoId(null)
      getOrderData()
    }
  }

  const handleDraft = () => {

    Swal.fire({
      title: t('saveToDraft'),
      text: t('saveDraftText'),
      imageUrl: ConfirmIcon,
      confirmButtonColor: '#1F83BB',
      showCancelButton: 'true',
      cancelButtonText: t('no'),
      confirmButtonText: t('yes'),
      reverseButtons: true,
      customClass: {
        popup: 'popup-swal',
        title: 'title-swal',
        htmlContainer: 'text-swal',
        confirmButton: 'confirm-swal',
        cancelButton: 'cancel-swal'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingMapScreen(true)
        try {
          const response = await axios.get(`${url}/api/save-draft-order/${orderid}`, { headers })
          navigate('/')
        } catch (error) {
          if (error.message === 'Network Error') {
            networkErrorPopup(t('networkErrorTitle'), t('networkErrorText'), t('reload'), t('cancel'))
          } else {
            errorPopup(t('error'), t('somethingError'), t('close'))
          }
        }
      }
    })
  }

  // Maps Config
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDfdR5Et5GQSGbLqWjFbXB7JNjLIA3Q9Vw',
    libraries
  })

  if (!isLoaded) {
    return (
      <h1></h1>
    )
  }



  const handleBack = () => {

    if (drft === null) {
      if (prm === null || prm == undefined) {
        navigate(`/delivery/trip-planning/${orderid}`)
      } else {
        navigate(`/delivery/trip-planning/${orderid}?prm=${prm}`)
      }
    } else {
      navigate(`/draft-detail/${orderid}`)
    }
  }


  // * COMPONENT
  const HeaderOrder = () => {
    return (
      <div className="header-order">
        <div className="title">
          <BsArrowLeftShort className="home-btn" onClick={handleBack} />
          <p className="title-text">{t('orderConfirm')}</p>
        </div>
        <div className="header-progress">
          <div className='create active'>
            <h6 className="progress-index">1</h6>
            <h6 className="progress-name">{t('createOrder')}</h6>
          </div>
          <div className='trip-planning active'>
            <h6 className="progress-index">2</h6>
            <h6 className="progress-name">{t('tripPlanning')}</h6>
          </div>
          <div className='order-confirm active'>
            <h6 className="progress-index">3</h6>
            <h6 className="progress-name">{t('orderConfirm')}</h6>
          </div>
          <div className='order-confirm'>
            <h6 className="progress-index">4</h6>
            <h6 className="progress-name">{t('payment')}</h6>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="start-delivery">
      <div className="order-side">
        <PaymentXendit
          isOpen={openPaymentXendit}
          toggle={togglePaymentXendit}
          xenditUrl={xenditUrl}
          customText={allDataOrder?.service_name?.name.includes('Superkul Truck') && 'Mohon menunggu konfirmasi dari Tim Superkul untuk segera menghubungi anda'}
        />
        <SummaryOrder
          isOpen={openSummaryOrder}
          toggle={toggleSummaryOrder}
        />
        <CustomModal
          isOpen={failedModalOpen}
          toggle={() => setFailedModalOpen({ visible: false })}
          onFinish={() => setFailedModalOpen({ visible: false })}
        />
        {loadingPromo && <div className="loading-map-screen"></div>}
        <HeaderOrder />
        {loadingMapScreen ? <div className="loading-map-screen"></div> :
          <>
            <OrderConfirmation
              order={allDataOrder}
              load={loading}
              discount={handleDiscount}
              opt={opt}
              prm={prm}
            />
            <div className="footer-order">
              {allDataOrder?.service_name?.name.includes('Superkul Truck') ?
                <div className="total-cost-order">
                  <div>
                    <h5>{t('totalCostOrder')}</h5>
                    <div className="payment-method">{userType === 2 ? 'Pre-Paid' : 'Post-Paid'}</div>
                  </div>
                  <h4>Rp {numberFormat(allDataOrder?.datePickRepeat.length >= 1 ? priceTotalWithPromo * allDataOrder?.datePickRepeat.length : priceTotalWithPromo)}</h4>
                </div>
                :
                context?.userType === 3 &&
                <div className="total-cost-order">
                  <div className="payment-method">{userType === 2 ? 'Pre-Paid' : 'Post-Paid'}</div>
                </div>
              }
              {/* <div className="total-cost-order">
                <div>
                  {context?.userType !== 3 &&
                    <h5>{t('totalCostOrder')}</h5>
                  }
                  <div className="payment-method">{userType === 2 ? 'Pre-Paid' : 'Post-Paid'}</div>
                </div>
                {}
                {context?.userType !== 3 && allDataOrder?.service_name?.name.includes('Superkul Truck') &&
                  <h4>Rp {numberFormat(allDataOrder?.datePickRepeat.length >= 1 ? priceTotalWithPromo * allDataOrder?.datePickRepeat.length : priceTotalWithPromo)}</h4>
                }
              </div> */}
              <div className="confirmation-button">
                <button className="save-draft btn btn-outline-dark" onClick={handleDraft} >{t('saveToDraft')}</button>
                <button className="continue-payment btn btn-primary" onClick={handlePayment}>{allDataOrder?.service_name?.name.includes('Superkul Truck') ? t('continueOrder').toUpperCase() : t('continuePayment').toUpperCase()}</button>
              </div>
            </div>
          </>
        }
      </div>
      <div className="maps-side">
        {/* <div className="navbar-maps">
          <img src={Logo} alt="logo superkull" />
        </div> */}
        <Header />
        <div className="maps-view">
          {/* <Maps address={markerLoc}/> */}
          <div style={{ positon: 'relative' }}>
            <div className="maps" style={{ width: '100%', height: '100vh' }}>
              <GoogleMap
                // center={center[center.length - 1]}
                center={center}
                zoom={11}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false
                }}
                onZoomChanged={(val) => {
                  storeHitGoogle(context?.id, "web_customer_dynamic_map")
                }}
                onLoad={(map) => setMap(map)}
              >
                {location.map((data) => {
                  if (data === undefined) {
                    return null
                  } else {
                    if (data.point === '') {
                      return (
                        <Marker
                          position={data.latlng}
                          draggable={true}
                          // onDragEnd={(e) => dragEnd(e, data.locationId)}
                          icon={GrayIcon}
                        >
                          {/* <InfoWindow position={data.latlng}>
                            <div>{data.point}</div>
                          </InfoWindow> */}
                        </Marker>
                      )
                    } else {
                      return (
                        <Marker
                          position={data.latlng}
                          draggable={true}
                          // onDragEnd={(e) => dragEnd(e, data.locationId)}
                          icon={data.point === 'Pick' ? PickupIcon : DropIcon}
                        >
                          {/* <InfoWindow position={data.latlng}>
                            <div>{data.point}</div>
                          </InfoWindow> */}
                        </Marker>
                      )
                    }
                  }
                })}
              </GoogleMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
