import { useEffect, useState } from "react"
import { Button, ButtonGroup } from "reactstrap"

import './TabMenu.scss'

const rawMenu = [
  {
    name: 'delivery',
    title: 'Delivery'
  },
  {
    name: 'crossdock',
    title: 'Crossdock'
  }
]

const TabMenu = ({ menu, selectedMenu, setSelectedMenu }) => {
  const [data, setData] = useState(menu)

  return (
    <ButtonGroup className="tab-menu">
      {data.map((dat) => {
        return (
          <Button
            className="btn-tab"
            // color="primary"
            outline
            onClick={() => {
              setSelectedMenu(dat)
            }}
            active={dat.title === selectedMenu.title}
          >{dat.title}</Button>
        )
      })}
      {/* <Button
        className="delivery-service service"
        color="danger"
        outline
        onClick={() => {
        }}
        // onClick={() => {
        //   setStatusFilter('ALL')
        //   setParamsFilter({
        //     ...paramsFilter,
        //     status: "ALL",
        //     page: 1
        //   })
        //   setMenuSelected(1);
        //   setSidebar(statusList.deliveryList);
        //   // getTransactionData('delivery')
        // }}
        active={menuSelected === 1}
      >Delivery Service</Button>
      <Button
        className="dedicated-service service"
        color="primary"
        outline
        // onClick={() => {
        //   setStatusFilter('ALL')
        //   setParamsFilter({
        //     ...paramsFilter,
        //     status: "ALL",
        //     page: 1
        //   })
        //   setMenuSelected(2);
        //   setSidebar(statusList.dedicatedList);
        //   // getTransactionData('dedicated')
        // }}
        active={menuSelected === 2}
      >Dedicated Service</Button> */}
    </ButtonGroup>
  )
}

export default TabMenu;