import { t } from "i18next";
import { useState } from "react";
import { DateRange } from "react-date-range";
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../Button/CustomButton/CustomButton";
import './modalDateRange.scss'

const ModalDateRange = ({ isOpen, toggle, onFinish, loading }) => {
  const [dateChoice, setDateChoice] = useState(1);
  const [daterange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  return (
    <Modal isOpen={isOpen.visible} toggle={toggle} className="modal-date-range">
      <ModalHeader className="modal-date-range-header">
        <h3 className="title">Export {isOpen.title}</h3>
      </ModalHeader>
      <ModalBody className="modal-date-range-body">
        <ButtonGroup className="date-choice">
          <CustomButton
            color="primary"
            outline
            onClick={() => setDateChoice(1)}
            active={dateChoice === 1}
            text="by Order Date"
          />
          <CustomButton
            color="primary"
            outline
            onClick={() => setDateChoice(2)}
            active={dateChoice === 2}
            text="by Pick Date"
          />
        </ButtonGroup>
        <DateRange
          editableDateInputs={true}
          onChange={item => setDateRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={daterange}
        />
      </ModalBody>
      <ModalFooter className="rating-driver-footer">
        <CustomButton
          text={t('cancel')}
          onClick={toggle}
          color="secondary"
        />
        <CustomButton
          text="Export"
          onClick={() => onFinish(daterange, dateChoice)}
          color='primary'
          loading={loading}
          disabled={loading && true}
        />
      </ModalFooter>
    </Modal>
  )
}

export default ModalDateRange;