import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import './Input.scss'
import { t } from "i18next";
import { useEffect, useState } from "react";
import { format } from "date-fns";

const InputTime = ({ label, labelName, placeholder, errors, control, closeOnSelect }) => {
  const [timeData, setTimeData] = useState([])
  useEffect(() => {
    let startHour = 5
    let endHour = 16
    let interval = 15
    let listTime = []

    for (let i = startHour; i < endHour; i++) {
      let hours = i.toString().length === 2 ? i : `0${i}`
      for (let j = 0; j < 60 / interval; j++) {
        const minInterval = interval * j
        let minutes = minInterval.toString().length === 2 ? minInterval : `0${minInterval}`
        const time = `${hours}:${minutes}`
        listTime.push(time)
      }
    }
    listTime.push(`${endHour}:00`)

    let currentDate = new Date()
    let currenttime = format(Date.parse(currentDate), 'HH:mm')

    let finalData = []
    listTime.map((time) => {
      // if (time >= currenttime) {
      finalData.push({
        value: time,
        label: time
      })
      // }
    })
    setTimeData(finalData)
  }, [])
  return (
    <div className="default-input input-select">
      <label htmlFor={labelName}>{label}</label>
      <Controller
        name={labelName}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            {...field}
            classNamePrefix="input-select-content"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: 'border: 1px solid #CFD3D5',
                borderRadius: "0.5rem",
                padding: 0,
              }),
            }}
            className={`${errors[labelName] ? 'select-data-error' : 'select-data'}`}
            options={timeData}
            placeholder={placeholder}
            closeMenuOnSelect={closeOnSelect ?? false}
            isSearchable={false}
          />
        )}
      />
      {errors[labelName]?.type === "required" && <p>{t('fieldRequired')}</p>}
    </div>
  )
}

export default InputTime;