import { useEffect, useState } from "react";
import { t } from "i18next";

import CustomTable from "../../../../Component/Table/CustomTable/CustomTable";
import truncateString from "../../../../utils/truncateString";

import './styles/index.scss'
import { Badge } from "reactstrap";
import ImportButton from "../../../../Component/Button/ImportButton/ImportButton";
import DangerButton from "../../../../Component/Button/DangerButton/DangerButton";
import WarningModal from "../../../../Component/Modal/WarningModal/WarningModal";
import SuccessModal from "../../../../Component/Modal/SuccessModal/SuccessModal";
import { Link } from "react-router-dom";
import { sendGetRequest } from "../../../../services/request-adapter";
import { errorMessage } from "../../../../utils/errorMessage";
import { format } from "date-fns";

const DataCrossdocking = () => {
  const columns = [
    {
      title: "Nomor Order",
      dataIndex: "crossdockNumber",
      render: (data, allData) => (
        <Link style={{ textDecoration: 'none', color: '#212519' }} to={`detail/${allData?.id}`}>
          {data}
        </Link>
      )
    },
    {
      title: "Nomor SO",
      dataIndex: "soNumber",
    },
    {
      title: "Waktu Pembuatan",
      dataIndex: "created_at",
      render: (data) => (
        <>
          {format(new Date(data), 'HH:mm, dd MMM yyyy')}
        </>
      )
    },
    {
      title: "Lokasi Hub",
      dataIndex: "poolName",
    },
    {
      title: "Jadwal Intransit",
      dataIndex: "crossdockDate",
      render: (data, allData) => (
        <>
          {allData.crossdockTime},
          {format(new Date(data), ' dd MMM yyyy')}
        </>
      )
    },
    {
      title: "Total SKU",
      dataIndex: "totalSKU",
      align: 'center'
    },
    {
      title: "Total Trip",
      dataIndex: "totalTrip",
      align: 'center'
    },
    {
      title: "Status Cross Docking",
      dataIndex: "status",
      align: 'center',
      render: (data) => (
        <Badge
          className={
            data === 'SCHEDULED' ?
              'bg-gray' :
              data === 'DONE' ?
                'bg-success' :
                data === 'PARTIAL' ?
                  'bg-success' :
                  data === 'CANCEL' ?
                    'bg-danger' :
                    'bg-danger'
          }
        >{data}</Badge>
      )
    },
  ]

  const [listData, setListData] = useState({})
  const [selectedData, setSelectedData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    pageSize: 15,
    name: ""
  })
  const [editModal, setEditModal] = useState({ visible: false })
  const [warningModal, setWarningModal] = useState({ visible: false })
  const [successModal, setSuccessModal] = useState({ visible: false })

  const getAllDataCrossdocking = async () => {
    setLoading(true)
    try {
      const { data } = await sendGetRequest(`/so-number-customers?page=${params.page}&limit=${params.pageSize}${params.name === '' ? '' : `&search=name:${params.name}`}`)
      setListData(data)
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoading(false)
    }
  }

  const handleCancelOrder = async () => {
    setWarningModal({ visible: false })
    setSuccessModal({
      visible: true,
      message: 'Success',
      duration: 3000
    })
  }

  const onChangePage = (page) => {
    setParams({ ...params, page: page })
  };

  useEffect(() => {
    getAllDataCrossdocking()
  }, [params])

  const CancelBtn = () => {
    return (
      <DangerButton
        text={t('cancelOrder')}
        onClick={() => {
          setWarningModal({ visible: true, message: 'Yakin?' })
        }}
      // loading={loadingScreen}
      // disabled={selectedFile === null ? true : false}
      />
    )
  }

  return (
    <div className="data-crossdocking">
      <div className="default-container">
        <div className="title">
          <h1>Data Cross Docking</h1>
          <hr />
          <p>Menampilkan semua riwayat Cross Docking.</p>
        </div>
      </div>
      <br />
      <div className="default-container">
        <CustomTable
          title="Daftar Sales Order"
          columns={columns}
          data={listData?.data}
          totalData={listData?.meta?.pagination?.total || 1}
          page={listData?.meta?.pagination?.current_page || 1}
          setPage={(size) => setParams({ ...params, page: size })}
          pageSize={listData?.meta?.pagination?.per_page || 15}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setPageSize={(size) => setParams({ ...params, pageSize: size })}
          onChangePage={onChangePage}
          searchFilter
          withPagination="top"
          loading={loading}
          setLoading={setLoading}
          setSearch={(val) => setParams({ ...params, name: val })}
          // checkboxes
          scrollX={100}
          MiscButton={[...selectedData.length > 0 ? [CancelBtn] : []]}
        />
      </div>

      <WarningModal
        isOpen={warningModal}
        toggle={() => setWarningModal({ visible: false })}
        onFinish={handleCancelOrder}
      // loading={loadingModal}
      />

      <SuccessModal
        isOpen={successModal}
        toggle={() => setSuccessModal({ visible: false })}
      />
    </div>
  )
};

export default DataCrossdocking;