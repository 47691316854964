import { useEffect, useRef, useState } from "react";
import { Calendar, DateRange } from "react-date-range";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import * as locales from 'react-date-range/dist/locale';
import { format, getDay } from "date-fns";

import DateIcon from '../../assets/icon/ic-calender-v2.png'

import './Input.scss'
import { t } from "i18next";

const InputDate = ({ onChangeDate, register, label, labelName, errors, defaultDate, minDate, maxDate }) => {
  const [date, setDate] = useState(new Date(defaultDate));

  // useEffect(() => {
  //   setRange(defaultValue)
  // }, [defaultValue])

  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className="default-input calendarWrap">
      <label htmlFor={label}>{label}</label>
      <input
        value={format(new Date(date), 'EEEE, dd MMMM yyyy')}
        readOnly
        className={`inputBox ${errors[labelName] ? 'error' : ''}`}
        onClick={() => setOpen((open) => !open)}
        {...register(labelName, {
          required: true,
        })}
      />
      <img src={DateIcon} alt="date-icon" />

      {errors[labelName]?.type === "required" && <p>{t('fieldRequired')}</p>}

      <div ref={refOne} className='calendar-div'>
        {open && (
          <Calendar
            onChange={item => {
              setDate(item);
              onChangeDate(format(new Date(item), 'yyyy-MM-dd'))
              setOpen(false);
            }}
            locale={locales['enUS']}
            date={date}
            className="calendarElement"
            minDate={minDate && minDate}
            maxDate={maxDate && maxDate}
            disabledDay={current => {
              return getDay(new Date(current)) === 0
            }}
          />
        )}
      </div>
    </div>
  );
};

export default InputDate;