import axios from "axios";
import { format } from "date-fns";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import * as xlsx from "xlsx";
import './CustomModal.scss'
import { errorPopup, networkErrorPopup } from "../../Alert/PopUp/ErrorPopUp";
import { useTranslation } from "react-i18next";
import SuccessIcon from '../../../assets/icon/popup/ic-popup-success.png'
import { LoadingScreen } from "../../loadingScreen/loadingScreen";
import PrimaryButton from "../../Button/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../Button/SecondaryButton/SecondaryButton";

const CustomModal = ({ isOpen, toggle, onFinish }) => {
  const { t } = useTranslation()
  const [loadingScreen, setLoadingScreen] = useState(false)

  useEffect(() => {
    if (isOpen.duration) {
      setTimeout(() => {
        toggle()
      }, isOpen.duration);
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen.visible} toggle={toggle} className="custom-modal">
      {loadingScreen && <LoadingScreen />}
      <ModalBody className="custom-modal-body">
        {isOpen.icon && <img src={isOpen?.icon} alt="" className="modal-icon" />}
        <h3 className="modal-title">{isOpen.title}</h3>
        <p className="modal-text">{isOpen.message}</p>
      </ModalBody>
      {!isOpen.duration &&
        <ModalFooter className="custom-modal-footer">
          <PrimaryButton
            text={isOpen?.confirmText}
            onClick={onFinish}
          />
          {isOpen.cancelText &&
            <SecondaryButton
              text={isOpen?.cancelText}
              onClick={toggle}
            />
          }
        </ModalFooter>
      }
    </Modal>
  )
}

export default CustomModal;