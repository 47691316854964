import { sendGetRequest } from "./request-adapter"
import { errorMessage } from "../utils/errorMessage"
import { t } from "i18next"

export const checkMaintenance = async () => {
  try {
    const { data } = await sendGetRequest('/maintenance')
    if (data == 1) window.location.href = `${process.env.REACT_APP_SUBWEB_URL}/maintenance`
  } catch (error) {
    console.log(error)
    errorMessage(error, t('error'), t('somethingError'), t('close'))
  }
}