import axios from "axios";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import './MultiOrderDatePick.scss'
import CalendarIcon from '../../assets/icon/ic-calendar-input.png'
import AddIcon from '../../assets/icon/ic-add-green.png'
import RemoveIcon from '../../assets/icon/ic-remove.png'
// import { LoadingScreen } from "../../loadingScreen/loadingScreen";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { addDays, format } from "date-fns";
import { useEffect } from "react";
import { errorMessage } from "../../utils/errorMessage";
import { sendGetRequestMobile } from "../../services/request-adapter";

export const MultiOrderDatePick = ({ isOpen, toggle, onFinish, defaultValue }) => {
  const { t } = useTranslation()
  const [loadingScreen, setLoadingScreen] = useState(false)
  const [dateList, setDateList] = useState([])
  const [dateId, setDateId] = useState(3)
  const [publicHoliday, setPublicHoliday] = useState([new Date('2023-08-21'), new Date('2023-08-15')])

  const handleSubmit = async () => {
    const findEmptyDate = dateList.find((a) => a.date === '')
    const filterDate = dateList.filter((a) => a.date !== '')
    if (dateList.length == 2 || filterDate.length < 2) {
      if (dateList[0].date === '' || dateList[1].date === '') {
        errorMessage('', t('error'), t('pickDateMultiMinimal'), t('close'))
        return
      }
    }
    if (findEmptyDate !== undefined) {
      errorMessage('', t('error'), t('pickDateMultiEmpty'), t('close'))
      return
    }

    let duplicateDate = dateList
      .map(e => format(e['date'], 'yyyy-MM-dd'))
      .map((e, i, final) => final.indexOf(e) !== i && i)
      .filter(obj => dateList[obj])
      .map(e => dateList[e]["date"])

    if (duplicateDate.length >= 1) {
      errorMessage('', t('error'), `${t('sameDateMulti')} (${format(duplicateDate[0], 'dd MMMM yyyy')})`, t('close'))
      return
    }

    onFinish(dateList)
    toggle()
  }

  const getPublicHoliday = async () => {
    try {
      const { data } = await sendGetRequestMobile('api/holiday')
      const publicHol = data?.data.map((e) => new Date(e.date))
      setPublicHoliday(publicHol)
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    }
  }

  useEffect(() => {
    getPublicHoliday()
  }, [])

  useEffect(() => {
    setDateList(defaultValue)
  }, [isOpen])

  const handleAddDate = () => {
    setDateId(dateId + 1)
    const newDate = {
      id: dateId,
      date: ''
    }
    setDateList(date => [...date, newDate])
  }

  const handleChangeDate = (targetId, val) => {
    const changeDate = dateList.map(date => {
      let newDate = {}
      if (date?.id === targetId) {
        newDate = {
          ...date,
          date: val
        }
        return newDate
      } else {
        return date
      }
    })
    setDateList(changeDate)
  }

  const handleRemoveDate = (targetId) => {
    const removeDate = dateList.filter((date) => date?.id !== targetId)
    setDateList(removeDate)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={`multiorder-modal ${loadingScreen && 'loading'}`}>
      <ModalHeader className="multiorder-modal-header" >
        {t('pickDate')}
      </ModalHeader>
      <ModalBody className="multiorder-modal-body">
        {dateList.map((date, index) => {
          return (
            <div className="wrapper" key={date?.id}>
              <DatePicker
                selected={date?.date}
                dateFormat="dd MMM yyyy"
                className='input-text'
                placeholderText={t('pickDate')}
                minDate={addDays(new Date(), 1)}
                maxDate={addDays(new Date(), 7)}
                onChange={(val) => handleChangeDate(date?.id, val)}
                excludeDates={publicHoliday}
                filterDate={date => {
                  // Disable weekends (Saturday and Sunday)
                  return date.getDay() !== 0;
                }}
              />
              <div className="icon">
                <img src={CalendarIcon} alt="" />
                {dateList.length > 2 &&
                  <img src={RemoveIcon} alt="" onClick={(val) => handleRemoveDate(date?.id)} />
                }
              </div>
            </div>
          )
        })}
        <br />
        {dateList.length < 7 &&
          <button
            className="btn add-loc"
            onClick={handleAddDate}
          >
            <img src={AddIcon} className="add-loc-icon" />
            {t('addDate')}
          </button>
        }
      </ModalBody>
      <ModalFooter className="multiorder-modal-footer">
        <Button className={`rating-submit ${loadingScreen && 'loading'}`} onClick={handleSubmit}>
          {t('save')}
        </Button>
        <Button className={`rating-skip ${loadingScreen && 'loading'}`} onClick={toggle}>
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}