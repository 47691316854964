import React from "react";
import { numberFormat } from "../../../../../Component/numberFormat/numberFormat";
import { t } from "i18next";
import { Badge } from "reactstrap";

const RightDetailCrossdock = ({ data, exportToCSV }) => {
  return (
    <div className="right-detail">
      {data?.serviceName?.toLowerCase() !== 'dedicated delivery' &&
        <>
          <div className="header-right">
            <div>
              <h1 className="title">{t('payment')}</h1>
              <h2 className="payment-detail">{t('paymentDetails')} <span>POSTPAID</span></h2>
            </div>
            {data?.invoiceStatus?.toLowerCase() === 'paid' ?
              <Badge className={`status paid`}>{t('paid')}</Badge>
              :
              <Badge className={`status unpaid`}>{data?.invoiceStatus === 'CANCELED' ? t('canceled') : t('unpaid')}</Badge>
            }
            {/* <Badge className={`status ${data?.invoiceStatus === 'PAID' ? 'paid' : 'unpaid'}`}>{data?.invoiceStatus}</Badge> */}
          </div>
        </>
      }
      <div className="body-right">
        <div className="fee">
          <p className="title-fee">{t('serviceFee')} Cross Docking</p>
          <p className="price-fee">Rp. {numberFormat(data?.priceTotal)}</p>
        </div>
      </div>
      <div className="footer-right">
        {data?.serviceName?.toLowerCase() !== 'dedicated delivery' &&
          <div className="total">
            <p className="total-cost">{t('totalCostOrder')}</p>
            <p className="price-total">Rp. {numberFormat(data?.priceTotal)}</p>
          </div>
        }
      </div>
    </div>
  )
}

export default RightDetailCrossdock;