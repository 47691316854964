import { t } from "i18next";
import { Link } from "react-router-dom";
import './UnderMaintenance.scss'
import maintenanceImg from '../../assets/img/maintenance.png'

const UnderMaintenance = () => {
  return (
    <div className="maintenance-container">
      <img src={maintenanceImg} alt="" />
      <h4 className="maintenance-title">{t('maintenanceTitle')}</h4>
      <h5 className="maintenance-text">{t('maintenanceText')}</h5>
      <Link to={`${process.env.REACT_APP_WEB_URL}`} className="maintenance-home">{t('backHome')}</Link>
    </div>
  )
}

export default UnderMaintenance;