import { BsArrowRightShort } from "react-icons/bs";
import { ToggleSwitch } from "../../ToggleSwitch/ToggleSwitch";
import { numberFormat } from "../../numberFormat/numberFormat";
import '../../../styles/deliveryService/tripPlanning/tripCard.scss'

const TripListCard = ({ allDataOrder, trip, index, handleToggle, showDetail }) => {
  const checkTemp = (temp) => {
    if (temp <= -11) {
      return 'Deep Freeze'
    } else if (temp <= -1) {
      return 'Frozen'
    } else {
      return 'Chiller'
    }
  }

  return (
    <div className="list-data">
      <div className="list-left">
        {allDataOrder.service_name.name.toLowerCase() === 'superkul truck ftl' ?
          <>
            <h3 className="trip">Trip {index + 1} | <span>{checkTemp(trip.pick.itemTmp)}</span></h3>
          </>
          :
          <>
            <h3 className="trip">Trip {index + 1} | <span>{checkTemp(trip.drop[0].itemTmp)}</span></h3>
          </>
        }
        <div className="location">
          <h4>Pick</h4>
          <h3>{trip.pick[0]?.pickLabel || trip.pick.pickLabel}</h3>
        </div>
      </div>
      <div className="list-right">
        {allDataOrder.totalDestination == 1 || allDataOrder.service_name.name.toLowerCase() === 'instant delivery' ?
          null :
          <h6>Optimize<ToggleSwitch bg='#E6E6E6' opt={handleToggle} currentOpt={trip.optimize} targetTrip={trip._id} /></h6>
        }


        <h4 className="price">Rp. {trip.optimize ? numberFormat(trip.priceOpt) : numberFormat(trip.price)}</h4>
        {/* {allDataOrder.service_name.name.toLowerCase() === 'superkul truck' ?
          <h4 className="price">Rp. {numberFormat(allDataOrder.superkulPriceTotal)}</h4>
          :
          <h4 className="price">Rp. {trip.optimize ? numberFormat(trip.priceOpt) : numberFormat(trip.price)}</h4>
        } */}
        <BsArrowRightShort className="list-detail" onClick={() => showDetail(trip, index, trip._id)} />
      </div>
    </div>
  )
}

export default TripListCard;