import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DEV_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Set the AUTH token (put from localStorage) for any request
axiosInstance.interceptors.request.use((config) => {
  const auth = localStorage.getItem("spkl:c015d311v");
  config.headers.Authorization = `Bearer ${auth}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear()

        window.location = "/login";
      } else if (error.response.status === 503) {
        const pathname = window.location.pathname
        if(!pathname.includes('maintenance')) {
          window.location = `/maintenance`
        }
      }
      console.log(error.response)
    }
    throw error
  }
);

export default axiosInstance;
