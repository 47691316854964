import SearchIcon from '../../../assets/icon/ic-search-black.png'
import './input-search.scss'

const InputSearch = ({ title, placeholder, onFinish }) => {
  return (
    <div className="input-search">
      <input
        placeholder={placeholder}
        className="search-transactions"
        type="text"
        name="first-name"
        onChange={(e) => {
          if (e.target.value.length >= 3 || e.target.value.length < 1) onFinish(e.target.value)
        }}
      />
      {/* <FiSearch className={`icon-search`} /> */}
      <img src={SearchIcon} alt="search-icon" />
    </div>
  )
}

export default InputSearch