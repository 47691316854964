import { Link, useLocation } from 'react-router-dom';
import './SideBar.scss'
import IconOrderPlanning from '../../assets/icon/sidebar/ic-order-planning.png'
import IconOrderPlanningActive from '../../assets/icon/sidebar/ic-order-planning-active.png'
import IconDataCrossdocking from '../../assets/icon/sidebar/ic-data-crossdock.png'
import IconDataCrossdockingActive from '../../assets/icon/sidebar/ic-data-crossdock-active.png'
import IconDataSKU from '../../assets/icon/sidebar/ic-data-sku.png'
import IconDataSKUActive from '../../assets/icon/sidebar/ic-data-sku-active.png'
import { useEffect, useState } from 'react';

const SideBar = ({ viewSidebar, setViewSidebar }) => {
  const location = useLocation()
  const menus = [
    {
      name: 'Order Planning',
      path: 'order-planning',
      icon: IconOrderPlanning,
      iconAct: IconOrderPlanningActive,
    },
    {
      name: 'Data Crossdocking',
      path: 'data-crossdocking',
      icon: IconDataCrossdocking,
      iconAct: IconDataCrossdockingActive
    },
    {
      name: 'Data SKU',
      path: 'data-sku',
      icon: IconDataSKU,
      iconAct: IconDataSKUActive
    },
  ]

  useEffect(() => {
    if (location.pathname.includes('order-planning') || location.pathname.includes('data-sku') || location.pathname.includes('data-crossdocking')) {
      setViewSidebar(true)
    } else {
      setViewSidebar(false)
    }
  }, [location.pathname])
  return (
    <div
      style={{
        display: !viewSidebar && 'none'
      }}
      className={`sidebar`}>
      <ul>
        {menus.map((menu) => {
          return (
            <li className={location.pathname.includes(menu.path) && 'active'}><Link to={`/${menu.path}`}><img src={!location.pathname.includes(menu.path) ? menu.icon : menu.iconAct} alt={menu.path} />{menu.name}</Link></li>
          )
        })}
      </ul>
    </div>
  )
};

export default SideBar;