import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { useJsApiLoader, GoogleMap, Marker, OverlayView } from '@react-google-maps/api'
import { Badge, Button, ButtonGroup, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, Row, Tag, } from "reactstrap";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from "axios";
import { addDays, format, isSameDay, subDays } from "date-fns";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactGA from "react-ga4";


// Asset
import Logo from "../assets/img/logo.png";
import RemoveIcon from '../assets/icon/ic-remove.png'
import ToggleIcon from '../assets/icon/ic-chevron-right.png'
import AddIcon from '../assets/icon/ic-add-green.png'
import CheckIcon from '../assets/icon/ic-invoice-paid.png'
import ConfirmIcon from '../assets/img/img-state-confirmation.png'
import LocationTooFarIcon from '../assets/img/location-toofar.png'
import SamedayIconActive from '../assets/icon/ic-service-sameday-active.png'
import SamedayIcon from '../assets/icon/ic-service-sameday-inactive.png'
import InstantIconActive from '../assets/icon/ic-service-instant-active.png'
import InstantIcon from '../assets/icon/ic-service-instant-inactive.png'
import ExpressIconActive from '../assets/icon/ic-service-superexpress-active.png'
import ExpressIcon from '../assets/icon/ic-service-superexpress-inactive.png'
import BikeIconActive from '../assets/icon/ic-vehicle-bike-active.png'
import BikeIcon from '../assets/icon/ic-vehicle-bike-inactive.png'
import L300IconActive from '../assets/icon/ic-vehicle-3-wheel-active.png'
import L300Icon from '../assets/icon/ic-vehicle-3-wheel-inactive.png'
import CDEIconActive from '../assets/icon/ic-vehicle-car-active.png'
import CDEIcon from '../assets/icon/ic-vehicle-car-inactive.png'
import CDDIconActive from '../assets/icon/ic-vehicle-truck-active.png'
import CDDIcon from '../assets/icon/ic-vehicle-truck-inactive.png'
import FUSOIconActive from '../assets/icon/ic-vehicle-truck-active.png'
import FUSOIcon from '../assets/icon/ic-vehicle-truck-inactive.png'

import PickupIcon from '../assets/icon/ic-location-blue.png'
import DropIcon from '../assets/icon/ic-location-yellow.png'
import GrayIcon from '../assets/icon/ic-location-gray.png'
import SearchIcon from '../assets/icon/ic-search.png'
import SavedAddressIcon2 from '../assets/icon/ic-input-saved-address.png'
import SavedAddressIcon from '../assets/icon/ic-select-bookmark.png'
import ImportIcon from '../assets/icon/ic-import.png'
import CalendarIcon from '../assets/icon/ic-calendar-input.png'
import TimeIcon from '../assets/icon/ic-time-input.png'
import SuccessIcon from '../assets/icon/ic-invoice-paid@2x.png'
import ArrowHideIcon from '../assets/icon/ic-arrow-left.png'
import HomeIcon from '../assets/img/superkul-favicon.png'

// Style
import "../styles/deliveryService/deliveryService.scss";
import '../styles/deliveryService/createOrder/createOrder.scss'
import { AuthContext } from "../Component/authContext/AuthContext";
import { InputSavedAddress } from "../Component/UI/modal/Delivery/InputSavedAddress";
import { ImportOrder } from "../Component/UI/modal/Delivery/ImportOrder";
import { errorPopup, networkErrorPopup } from "../Component/Alert/PopUp/ErrorPopUp";
import { numberFormat } from "../Component/numberFormat/numberFormat";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Header } from "../Component/header/Header";
import { Helmet } from "react-helmet";
import { MultiOrderDatePick } from "./Delivery/MultiOrderDatePick";
import { checkMaintenance } from "../services/checkMaintenance";
import { sendGetRequest, sendGetRequestMobile, sendPostRequestMobile } from "../services/request-adapter";
import { errorMessage } from "../utils/errorMessage";
import { LogoSkeleton, TitleSkeleton } from "../Component/Skeleton/Skeleton";
import { MdClose, MdOutlineAdd } from "react-icons/md";
import RedirectWhatsappModal from "../Component/Modal/RedirectWhatsappModal/RedirectWhatsappModal";
import { storeHitGoogle } from "../services/storeHitGoogle";
import { DedicatedService } from "../Component/Modal/DedicatedService/DedicatedService";


const optionTime = ['06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00']
// let optionTime = []
const optionTime2HoursAgo = ['04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00']

const sizeDataDefault = [
  {
    name: 'S',
    weight: 10,
    height: 27,
    width: 24,
    lenght: 36,
    volume: 23
  },
  {
    name: 'M',
    weight: 15,
    height: 30,
    width: 26,
    lenght: 45,
    volume: 35
  },
  {
    name: 'L',
    weight: 30,
    height: 42,
    width: 32,
    lenght: 58,
    volume: 70
  },
]

const sizeDataExpress = [
  {
    name: 'S',
    weight: 20,
    height: 50,
    width: 40,
    lenght: 55,
    volume: 110
  },
  {
    name: 'M',
    weight: 30,
    height: 55,
    width: 46,
    lenght: 65,
    volume: 165
  },
  {
    name: 'L',
    weight: 60,
    height: 75,
    width: 55,
    lenght: 80,
    volume: 330
  },
]

const tempRange = [
  {
    id: 1,
    name: 'Chiller',
    min: 10,
    max: 0,
    avg: 5
  },
  {
    id: 2,
    name: 'Frozen',
    min: -1,
    max: -10,
    avg: -5
  },
  {
    id: 3,
    name: 'Deep Freeze',
    min: -11,
    max: -22,
    avg: -15
  },
]

// let tm2 = []

// for (let i = 6; i <= 16; i++) {
//   if(i >= 10) {
//     optionTime.push(`${i}:00`)
//     tm2.push(`${i - 2}:00`)
//   } else {
//     optionTime.push(`0${i}:00`)
//     tm2.push(`0${i - 2}:00`)
//   }
//   if(i !== 16) {
//     i >= 10 ? 
//       optionTime.push(`${i}:30`) 
//       : 
//       optionTime.push(`0${i}:30`)
//   }
// }

// console.log(tm2)

export const DeliveryService = () => {
  // * VARIABLE / STATE
  // Auth
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useForm();
  const url = process.env.REACT_APP_URL_CUST
  const access_token = localStorage.getItem("spkl:c015d311v")
  const headers = {
    'Authorization': `${access_token}`
  }
  const auth = useContext(AuthContext)

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const drftNum = searchParams.get('drftNum')
  const crossdock = searchParams.get('crossdock')
  const reorder = searchParams.get('ro')
  const locState = useLocation()
  const [index, setIndex] = useState(1)
  const [point, setPoint] = useState('Pick')
  const [selectedService, setSelectedService] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedAdditional, setSelectedAdditional] = useState([]);
  const [services, setServices] = useState([])
  const [vehicles, setVehicles] = useState([])
  const [defaultVehicles, setDefaultVehicles] = useState([])
  const [middleMileVehicles, setmiddleMileVehicles] = useState([])
  const [expressVehicles, setExpressVehicles] = useState([])
  const [isBike, setIsBike] = useState("");
  const [additionalList, setAdditionalList] = useState([])
  const [promoCode, setPromoCode] = useState('')
  const [orderAllow, setOrderAllow] = useState(false)
  const [isRedirectWa, setIsRedirectWa] = useState({ visible: false })

  const [locationId, setlocationId] = useState(0)
  const [targetLocationId, setTargetLocationId] = useState(0)
  const [location, setLocation] = useState([])
  const [recentLocation, setRecentLocation] = useState([])
  const [locViewType, setLocViewType] = useState(0)

  const [itemId, setItemId] = useState(0)
  const [targetItemId, setTargetItemId] = useState(0)
  const [itemList, setItemList] = useState([])

  const [orderId, setOrderId] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [serviceNameId, setServiceNameId] = useState('')
  const [crossdockId, setCrossdockId] = useState(crossdock)
  const [datePick, setDatePick] = useState(new Date())
  const [currentDate, setCurrentDate] = useState('')
  const [minDate, setMinDate] = useState('')
  const [timePick, setTimePick] = useState('pick-time')
  const [currenttime, setCurrenttime] = useState('')
  const [isMultiOrder, setIsMultiOrder] = useState(false)
  const [datePickMulti, setDatePickMulti] = useState([
    {
      id: 0,
      date: addDays(new Date(), 1),
    },
    {
      id: 1,
      date: ''
    }
  ])

  const [vehicleTypeId, setVehicleTypeId] = useState('')
  const [bikeId, setBikeId] = useState('')
  const [additionalService, setAdditionalService] = useState([])
  const [extraService, setExtraService] = useState([])
  const [isCustomeSize, setIsCustomeSize] = useState(false)

  // Sender Receiver State
  const [pointLabel, setPointLabel] = useState('')
  const [tag, setTag] = useState({})
  const [tagsList, setTagsList] = useState([])
  const [notes, setNotes] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isSaveBookmark, setIsSaveBookmark] = useState(false)
  const [bookmarkLabel, setBookmarkLabel] = useState('')
  const [bookmarkReload, setBookmarkReload] = useState(true)
  const isbookmarkref = useRef()

  // Packages State
  const [packagesList, setPackagesList] = useState([])
  const [isOtherPackages, setIsOtherPackages] = useState(false)
  const [packageCategory, setPackageCategory] = useState('')
  const [packageSize, setPackageSize] = useState('')
  const [sizeData, setSizeData] = useState(sizeDataDefault)
  const [temperature, setTemperature] = useState(5)
  const [quantity, setQuantity] = useState(1)
  const [tempInput, setTempInput] = useState('')
  const [maximumPackage, setMaximumPackage] = useState({
    weight: '',
    length: '',
    width: '',
    height: ''
  })
  const [soNumberInput, setSoNumberInput] = useState({
    isAdd: false,
    value: ''
  })
  const [soNumberTemporary, setSoNumberTemporary] = useState([])

  // Toggle
  const [detailOpen, setDetailOpen] = useState(false)
  const [toggleShow, setToggleShow] = useState(false)
  const [additional, setAdditional] = useState(true);
  const [showHideService, setShowHideService] = useState(false)
  const [tagsListOpen, setTagsListOpen] = useState(false)

  const [openMultiDatePick, setopenMultiDatePick] = useState(false);
  const toggleMultiDatePick = () => {
    setopenMultiDatePick(!openMultiDatePick)
  }

  const [importTypeOpen, setImportTypeOpen] = useState(false)
  const [openImportOrder, setOpenImportOrder] = useState(false);

  const [openSavedAddress, setOpenSavedAddress] = useState(false);
  const toggleSavedAddress = (targetId) => {
    if (targetId !== targetLocationId) {
      setTargetLocationId(targetLocationId)
    } else {
      setTargetLocationId(targetId)
    }
    setOpenSavedAddress(!openSavedAddress)
  }


  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')
  const [formattedAddress, setFormattedAddress] = useState('')
  const [center, setCenter] = useState({
    lat: -6.1722143,
    lng: 106.7657932
  })
  const [loadingMapScreen, setLoadingMapScreen] = useState(false)
  const [servicesLoader, setServicesLoader] = useState(false)
  const [vehiclesLoader, setVehiclesLoader] = useState(false)
  const [loading, setLoading] = useState({
    servicesLoader: false,
    vehiclesLoader: false,
    truckLoad: false,
    additionalLoad: false,
  })
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [libraries] = useState(['places']);
  const [sessionToken, setSessionToken] = useState(undefined)

  setTimeout(() => {
    const currentdate = new Date
    // setCurrenttime('16:30')
    setCurrenttime(format(Date.parse(currentdate), 'HH:mm'))
    // console.log('hahahha')
  }, 1000);

  useEffect(() => {
    if (drftNum === null && crossdockId === null) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            })
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }
  }, [])


  // * FUNCTION
  // Initial Render
  // let sessionToken;
  useEffect(() => {
    getAllData()
    // window.google = window.google ? window.google : {}
    const currentdate = new Date
    const fiveminuteslater = currentdate.getTime() + 300 * 1000
    setCurrentDate(currentdate)

    // setTimeout(() => {
    //   // sessionToken = new window.google.maps.places.AutocompleteSessionToken()
    //   setSessionToken(new window.google.maps.places.AutocompleteSessionToken())
    // }, 2000);
  }, [])

  useEffect(() => {
    switch (selectedService) {
      case 'superkul-truck':
        setVehicles(middleMileVehicles)
        break;
      case 'superkul-truck-ftl':
        setVehicles(middleMileVehicles)
        // setMaximumPackage({
        //   weight: "",
        //   height: "",
        //   width: "",
        //   length: "",
        // })
        break;
      case 'superkul-truck-ltl':
        setVehicles(middleMileVehicles)
        setMaximumPackage({
          weight: "",
          height: "",
          width: "",
          length: "",
        })
        break;
      case 'super-express':
        setVehicles(expressVehicles)
        setTemperature(10)
        setMaximumPackage({
          weight: parseInt(expressVehicles[0]?.weight),
          height: parseInt(expressVehicles[0]?.height),
          width: parseInt(expressVehicles[0]?.width),
          length: parseInt(expressVehicles[0]?.length),
        })
        setSizeData(sizeDataExpress)
        break;
      default:
        setVehicles(defaultVehicles)
        setMaximumPackage({
          weight: parseInt(defaultVehicles[0]?.weight),
          height: parseInt(defaultVehicles[0]?.height),
          width: parseInt(defaultVehicles[0]?.width),
          length: parseInt(defaultVehicles[0]?.length),
        })
        setSizeData(sizeDataDefault)
        break;
    }
  }, [selectedService, middleMileVehicles, expressVehicles, defaultVehicles])

  // Get All Requirement Data
  useEffect(() => {
    if (drftNum !== null || crossdockId !== null) {
      setLoadingMapScreen(true)
    }
    checkMaintenance()
    setCustomerId(auth.id)
    getAllServices()
    getAllVehicle()
    getAllTruckVehicle()
    getAllAdditionalService()
    getAllExpressVehicle()
    getAllTags()
  }, [])

  const getAllServices = async () => {
    setServicesLoader(true)
    try {
      const { data } = await sendGetRequestMobile('/api/general-service')
      let filterData
      filterData = data.data.filter(e => e.name.toLowerCase() !== 'dedicated delivery')
      if (auth.userType !== 3) {
        filterData = filterData.filter(e => e.name.toLowerCase() !== 'super express')
      }
      setServices(filterData)

      // if (crossdock) {
      //   const crossdockSameday = filterData.find(e => e.name.toLowerCase() === 'sameday delivery')
      //   console.log(crossdockSameday.id)
      //   setSelectedService('sameday-delivery')
      //   setServiceNameId(crossdockSameday.id)
      // }
    } catch (error) {
      console.log(error.message)
    } finally {
      setServicesLoader(false)
    }
  }

  const getAllVehicle = async () => {
    setVehiclesLoader(true)
    try {
      const { data } = await sendGetRequestMobile('/api/vehicle-type')
      // setVehicles(data.data)
      setDefaultVehicles(data.data)
      setBikeId(data?.data[0]?.id)
    } catch (error) {
      console.log(error.message)
    } finally {
      setVehiclesLoader(false)
    }
  }

  const getAllTruckVehicle = async () => {
    setLoading({ ...loading, truckLoad: true })
    try {
      const { data } = await sendGetRequestMobile('/api/vehicle-type-middle')
      setmiddleMileVehicles(data.data)
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading({ ...loading, truckLoad: false })
    }
  }

  const getAllExpressVehicle = async () => {
    setLoading({ ...loading, truckLoad: true })
    try {
      const { data } = await sendGetRequestMobile('/api/vehicle-type-express')
      setExpressVehicles(data.data)
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading({ ...loading, truckLoad: false })
    }
  }

  const getAllAdditionalService = async () => {
    setLoading({ ...loading, additionalLoad: true })
    try {
      const response = await sendGetRequestMobile('/api/additional-service')
      const data = response.data.data
      setAdditionalList(data)
      setSelectedAdditional(
        new Array(data.length).fill(false)
      )
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading({ ...loading, additionalLoad: false })
    }
  }

  const getAllTags = async () => {
    setLoading({ ...loading, tagsLoad: true })
    try {
      const response = await sendGetRequest('tag-addresses?limit=0')
      const data = response.data.data
      setTagsList(data)

      const primaryData = data.find(dat => dat.is_primary === 1)
      setTag({ name: primaryData?.name, time: primaryData?.time, is_primary: primaryData?.is_primary })
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading({ ...loading, tagsLoad: false })
    }
  }


  const getAllData = async () => {
    // checkMaintenance()
    // // CustomerId
    // // setLoadingMapScreen(true)
    // // console.log('context = ' + auth.id)
    // setCustomerId(auth.id)

    // Item Category List
    try {
      const response = await axios.get(`${url}/api/item-category`, { headers })
      const data = response.data.data
      setPackagesList(data)

      if (drftNum !== null) {
        setLoadingMapScreen(true)
        try {
          const response = await axios.get(`${url}/api/tmp-order/${drftNum}`, { headers })

          transferAllDraftData(response.data.data, data)
        } catch (error) {
          console.log(error.message)
        }
      } else if (crossdockId !== null) {
        setLoadingMapScreen(true)
        try {
          const response = await sendGetRequest(`/customer-order-plannings/${crossdockId}/export`)
          crossdockData(response.data, data)
        } catch (error) {
          console.log(error.message)
        }
      } else {
        setLoadingMapScreen(false)
      }

      if (locState?.state) {
        transferAllDraftData(locState?.state, data)
      }
    } catch (error) {
      console.log(error.message)
    }


  }


  const checkTemp = (temp) => {
    if (temp <= -11) {
      return -15
    } else if (temp <= -1) {
      return -5
    } else {
      return 5
    }
  }


  // Get from existing data
  const transferAllDraftData = async (draftData, itemList) => {
    const name = draftData.service_name.name.toLowerCase().split(' ').join('-')
    setSelectedService(name)
    setServiceNameId(draftData.serviceNameId)

    const currentdate = new Date
    setDatePick(draftData.service_name.name.toLowerCase() === 'instant delivery' ? currentdate : new Date(draftData.datePick))
    setMinDate(currentdate)
    setTimePick(draftData.timePick)
    setCrossdockId(draftData.rawData.crossdockId ?? null)
    if (draftData?.datePickRepeat?.length >= 1) {
      const parseDate = draftData?.datePickRepeat.map((date, index) => {
        return {
          id: index,
          date: new Date(date)
        }
      })
      setDatePickMulti(parseDate)
      setIsMultiOrder(true)
    }

    switch (name) {
      case 'superkul-truck':
        setIsBike(draftData.vehicle_type.name)
        setVehicleTypeId(draftData.vehicleTypeId)
        setSelectedVehicle(draftData.vehicle_type.name)
        break;

      case 'superkul-truck-ftl':
        setIsBike(draftData.vehicle_type.name)
        setVehicleTypeId(draftData.vehicleTypeId)
        setSelectedVehicle(draftData.vehicle_type.name)
        // setMaximumPackage({
        //   weight: null,
        //   height: null,
        //   width: null,
        //   length: null,
        // })
        break;

      case 'superkul-truck-ltl':
        setIsBike(draftData.vehicle_type.name)
        setVehicleTypeId(draftData.vehicleTypeId)
        setSelectedVehicle(draftData.vehicle_type.name)
        setMaximumPackage({
          weight: null,
          height: null,
          width: null,
          length: null,
        })
        break;

      case 'super-express':
        setIsBike(draftData.vehicle_type.name)
        setVehicleTypeId(draftData.vehicleTypeId)
        setSelectedVehicle(draftData.vehicle_type.name)
        setTemperature(10)
        setMaximumPackage({
          weight: parseInt(expressVehicles[0]?.weight),
          height: parseInt(expressVehicles[0]?.height),
          width: parseInt(expressVehicles[0]?.width),
          length: parseInt(expressVehicles[0]?.length),
        })
        setSizeData(sizeDataExpress)
        break

      default:
        setIsBike(draftData.vehicle_type.name)
        setVehicleTypeId(draftData.vehicleTypeId)
        setSelectedVehicle(draftData.vehicle_type.name)
        setMaximumPackage({
          weight: parseInt(defaultVehicles[0]?.weight),
          height: parseInt(defaultVehicles[0]?.height),
          width: parseInt(defaultVehicles[0]?.width),
          length: parseInt(defaultVehicles[0]?.length),
        })
        setSizeData(sizeDataDefault)
        break;
    }

    // if (draftData.service_name.name.toLowerCase() !== 'super express') {
    // console.log('ga masuk harusnya')
    setSelectedVehicle(draftData.vehicle_type.name)
    setVehicleTypeId(draftData.vehicleTypeId)
    setIsBike(draftData.vehicle_type.name)
    // }
    if (!draftData.service_name.name.includes('Superkul Truck')) {
      // Check input ceklis motor
      // 
      // Additional
      // 
      setAdditionalService(draftData.rawData.additionalService)

      if (isSameDay(new Date(draftData.datePick), new Date()) && draftData.service_name.name.toLowerCase() === 'sameday delivery') {
        const timepickvalue = format(Date.parse(currentdate), 'HH:mm')
        const findtime = optionTime2HoursAgo.findIndex((opt, index) => {
          return opt >= timepickvalue
        })
        setTimePick(optionTime[findtime])
      }

      let addIndex = []
      try {
        const response = await axios.get(`${url}/api/additional-service`, { headers })
        const data = response.data.data
        const addArray = new Array(data.length).fill(false)
        const additional = data.map((e, index) => {
          let check = false
          draftData.rawData.additionalService.map(raw => {
            if (raw.id === e.id) {
              check = true
            }
          })
          addIndex.push(check)
        })
        // setSelectedAdditional(addIndex)
      } catch (error) {
        console.log(error.message)
      }



      let transferLocation = []
      let locId = 0
      let itmId = 0


      draftData.rawData.pickUp.map(((loc) => {
        let dropLoc = []
        const pickLoc = {
          point: 'Pick',
          locationId: locId,
          notes: loc.notes,
          name: loc.senderName,
          phoneName: loc.pickPhoneName === null ? loc.senderName : loc.pickPhoneName,
          phoneNumber: loc.pickPhoneNumber.substring(2),
          addressName: loc.pickLabel === null ? loc.pickAddress : loc.pickLabel,
          addressFormat: loc.pickAddress,
          addressLabel: loc.pickLabel === null ? loc.pickAddress : loc.pickLabel,
          lnglat: loc.pickLocation,
          latlng: {
            lat: loc.pickLocation[1],
            lng: loc.pickLocation[0],
          },
          itemId: '',
          item: [],
          additional: loc?.additionalService ?? [],
          tag: loc?.tagAddress || tagsList.find((tag) => tag.is_primary === 1)
        }
        loc.item.map((item) => {
          locId += 1
          itmId += 1
          const checkItem = itemList.find((list) => {
            return list.name.toLowerCase() === item.itemCategory.toLowerCase()
          })
          const findSize = sizeData.find((size) => item.weight == size.weight && item.width == size.width && item.lenght == size.lenght && item.height == size.height)
          setIsCustomeSize(false)
          findSize ? setPackageSize(findSize.name) : setIsCustomeSize(true)
          const drop = {
            point: 'Drop',
            locationId: locId,
            notes: item.dropOff.dropNotes,
            name: item.dropOff.receiverName,
            phoneName: item.dropOff.dropPhoneName === null ? item.dropOff.receiverName : item.dropOff.dropPhoneName,
            phoneNumber: item.dropOff.dropPhoneNumber.substring(2),
            addressName: item.dropOff.dropLabel === null ? item.dropOff.dropAddress : item.dropOff.dropLabel,
            addressFormat: item.dropOff.dropAddress,
            addressLabel: item.dropOff.dropLabel === null ? item.dropOff.dropAddress : item.dropOff.dropLabel,
            lnglat: item.dropOff.dropLocation,
            latlng: {
              lat: item.dropOff.dropLocation[1],
              lng: item.dropOff.dropLocation[0],
            },
            itemId: itmId,
            item: {
              itemId: itmId,
              packageCategory: checkItem === undefined ? "Lainnya" : item.itemCategory,
              itemDesc: checkItem === undefined ? item.itemCategory : "",
              weight: item.weight,
              lenght: item.lenght,
              width: item.width,
              height: item.height,
              temperature: checkTemp(item.itemTmp),
              quantity: item.itemQty,
              ...(item?.soNumber?.length != 0) && { soNumber: item.soNumber },
              ...(item?.soNumberCrossdock?.length != 0) && { soNumberCrossdock: item.soNumberCrossdock },
            },
            additional: item?.additionalService ?? [],
            tag: item?.dropOff?.tagAddress || tagsList.find((tag) => tag.is_primary === 1)
          }
          dropLoc.push(drop)
        })
        locId += 1
        transferLocation.push(pickLoc)
        dropLoc.map((drop) => {
          transferLocation.push(drop)
        })
        setCenter(pickLoc.latlng)
      }))
      setLocation(transferLocation)
      setlocationId(locId + 1)
      setTargetLocationId(locId + 1)
      setItemId(itmId + 1)
      setTargetItemId(itmId + 1)

    } else {
      setMinDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
      let transferLocation = []
      let locId = 0
      let itmId = 0
      let dropLoc = []


      const pickLoc = {
        point: 'Pick',
        locationId: locId,
        notes: draftData.rawData.pickUp.pickNotes,
        name: draftData.rawData.pickUp.senderName,
        phoneName: draftData.rawData.pickUp.pickPhoneName,
        phoneNumber: draftData.rawData.pickUp.pickPhoneNumber.substring(2),
        addressName: draftData.rawData.pickUp.pickLabel,
        addressFormat: draftData.rawData.pickUp.pickAddress,
        addressLabel: draftData.rawData.pickUp.pickLabel,
        lnglat: draftData.rawData.pickUp.pickLocation,
        latlng: {
          lat: draftData.rawData.pickUp.pickLocation[1],
          lng: draftData.rawData.pickUp.pickLocation[0],
        },
        itemId: '',
        item: [],
        temp: checkTemp(draftData.rawData.pickUp.itemTmp),
        weight: draftData.rawData.pickUp.weight,
        height: draftData.rawData.pickUp.height,
        width: draftData.rawData.pickUp.width,
        lenght: draftData.rawData.pickUp.lenght,
        tag: draftData.rawData.pickUp?.tagAddress
      }
      locId += 1
      transferLocation.push(pickLoc)
      setCenter(pickLoc.latlng)

      draftData.rawData.dropOff.map((loc) => {
        locId += 1
        itmId += 1
        const drop = {
          point: 'Drop',
          locationId: locId,
          notes: loc.dropNotes,
          name: loc.receiverName,
          phoneName: loc.dropPhoneName,
          phoneNumber: loc.dropPhoneNumber.substring(2),
          addressName: loc.dropLabel,
          addressFormat: loc.dropAddress,
          addressLabel: loc.dropLabel,
          lnglat: loc.dropLocation,
          latlng: {
            lat: loc.dropLocation[1],
            lng: loc.dropLocation[0],
          },
          itemId: itmId,
          item: {},
          tag: loc?.tagAddress
        }
        dropLoc.push(drop)
      })
      locId += 1
      dropLoc.map((drop) => {
        transferLocation.push(drop)
      })

      setLocation(transferLocation)
      setlocationId(locId + 1)
      setTargetLocationId(locId + 1)
      setItemId(itmId + 1)
      setTargetItemId(itmId + 1)
    }

    setLocViewType(2)
    setOrderAllow(true)
    setLoadingMapScreen(false)
  }

  // Get from crossdock order
  const crossdockData = async (draftData, itemList) => {
    const name = draftData.serviceName.toLowerCase().split(' ').join('-')
    setSelectedService(name)
    setServiceNameId(draftData.serviceNameId)

    const currentdate = new Date
    setDatePick(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
    setMinDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))

    setSelectedVehicle(draftData.vehicleName)
    setVehicleTypeId(draftData.vehicleTypeId)
    setIsBike(draftData.vehicleName)

    // setAdditionalService(draftData.rawData.additionalService)

    // if (isSameDay(new Date(draftData.datePick), new Date()) && draftData.service_name.name.toLowerCase() === 'sameday delivery') {
    //   const timepickvalue = format(Date.parse(currentdate), 'HH:mm')
    //   const findtime = optionTime2HoursAgo.findIndex((opt, index) => {
    //     return opt >= timepickvalue
    //   })
    //   setTimePick(optionTime[findtime])
    // }

    // let addIndex = []
    // try {
    //   const response = await axios.get(`${url}/api/additional-service`, { headers })
    //   const data = response.data.data
    //   const addArray = new Array(data.length).fill(false)
    //   const additional = data.map((e, index) => {
    //     let check = false
    //     draftData.rawData.additionalService.map(raw => {
    //       if (raw.id === e.id) {
    //         check = true
    //       }
    //     })
    //     addIndex.push(check)
    //   })
    //   // setSelectedAdditional(addIndex)
    // } catch (error) {
    //   console.log(error.message)
    // }



    let transferLocation = []
    let locId = 0
    let itmId = 0

    let dropLoc = []
    const pickLoc = {
      point: 'Pick',
      locationId: locId,
      notes: draftData.pickUp.notes,
      name: draftData.pickUp.senderName,
      phoneName: draftData.pickUp.pickPhoneName === null ? draftData.pickUp.senderName : draftData.pickUp.pickPhoneName,
      phoneNumber: draftData.pickUp.pickPhoneNumber.substring(2),
      addressName: draftData.pickUp.pickLabel === null ? draftData.pickUp.pickAddress : draftData.pickUp.pickLabel,
      addressFormat: draftData.pickUp.pickAddress,
      addressLabel: draftData.pickUp.pickLabel === null ? draftData.pickUp.pickAddress : draftData.pickUp.pickLabel,
      lnglat: draftData.pickUp.pickLocation,
      latlng: {
        lat: draftData.pickUp.pickLocation[1],
        lng: draftData.pickUp.pickLocation[0],
      },
      itemId: '',
      item: [],
      additional: draftData.pickUp?.additionalService ?? [],
      tag: tagsList.find((tag) => tag.is_primary === 1)
    }
    draftData.pickUp.item.map((item) => {
      locId += 1
      itmId += 1
      const checkItem = itemList.find((list) => {
        return list.name.toLowerCase() === item.itemCategory.toLowerCase()
      })
      const findSize = sizeData.find((size) => item.weight == size.weight && item.width == size.width && item.lenght == size.lenght && item.height == size.height)
      setIsCustomeSize(false)
      findSize ? setPackageSize(findSize.name) : setIsCustomeSize(true)
      const drop = {
        point: 'Drop',
        locationId: locId,
        notes: item.dropOff.dropNotes,
        name: item.dropOff.receiverName,
        phoneName: item.dropOff.dropPhoneName === null ? item.dropOff.receiverName : item.dropOff.dropPhoneName,
        phoneNumber: item.dropOff.dropPhoneNumber?.toString().substring(2),
        addressName: item.dropOff.dropLabel === null ? item.dropOff.dropAddress : item.dropOff.dropLabel,
        addressFormat: item.dropOff.dropAddress,
        addressLabel: item.dropOff.dropLabel === null ? item.dropOff.dropAddress : item.dropOff.dropLabel,
        lnglat: item.dropOff.dropLocation,
        latlng: {
          lat: item.dropOff.dropLocation[1],
          lng: item.dropOff.dropLocation[0],
        },
        itemId: itmId,
        item: {
          itemId: itmId,
          packageCategory: checkItem === undefined ? "Lainnya" : item.itemCategory,
          itemDesc: checkItem === undefined ? item.itemCategory : "",
          weight: item.weight,
          lenght: item.lenght,
          width: item.width,
          height: item.height,
          temperature: checkTemp(item.itemTmp),
          quantity: item.itemQty,
          ...(item?.soNumber?.length != 0) && { soNumber: item.soNumber },
          ...(item?.soNumberCrossdock?.length != 0) && { soNumberCrossdock: item.soNumberCrossdock },
        },
        additional: item?.additionalService ?? [],
        tag: item?.dropOff?.tagAddress || tagsList.find((tag) => tag.is_primary === 1)
      }
      dropLoc.push(drop)
    })
    locId += 1
    transferLocation.push(pickLoc)
    dropLoc.map((drop) => {
      transferLocation.push(drop)
    })
    setCenter(pickLoc.latlng)


    setLocation(transferLocation)
    setlocationId(locId + 1)
    setTargetLocationId(locId + 1)
    setItemId(itmId + 1)
    setTargetItemId(itmId + 1)



    setLocViewType(2)
    setOrderAllow(true)
    setLoadingMapScreen(false)
  }

  // Get from importOrder
  const handleImportOrder = (data) => {
    setOpenImportOrder(false)
    setLoadingMapScreen(true)
    setCrossdockId(data?.pickUp[0]?.crossdockId ?? null)
    // setPromoCode(data?.promo)
    // transferAllDraftData(data.temp_order, packagesList)
    // return

    let transferLocation = []
    let locId = 0
    let itmId = 0



    data.pickUp.map(((loc) => {

      let dropLoc = []
      const pickLoc = {
        point: 'Pick',
        locationId: locId,
        notes: loc.notes === null ? "" : loc.notes,
        name: loc.senderName,
        phoneName: loc.pickPhoneName === null ? loc.senderName : loc.pickPhoneName,
        phoneNumber: loc.pickPhoneNumber.toString().substring(2),
        addressName: loc.pickLabel === "" ? loc.pickAddress : loc.pickLabel,
        addressFormat: loc.pickAddress,
        addressLabel: loc.pickLabel === "" ? loc.pickAddress : loc.pickLabel,
        lnglat: loc.pickLocation,
        latlng: {
          lat: loc.pickLocation[1],
          lng: loc.pickLocation[0],
        },
        itemId: '',
        item: [],
        additional: loc?.additionalService ?? [],
        tag: loc?.tagAddress || tagsList.find((tag) => tag.is_primary === 1)
      }
      loc.item.map((item, i) => {
        locId += 1
        itmId += 1
        const checkItem = packagesList.find((list) => {
          return list.name.toLowerCase() === item.itemCategory.toLowerCase()
        })
        const drop = {
          point: 'Drop',
          locationId: locId,
          notes: item.dropOff.dropNotes === null ? "" : item.dropOff.dropNotes,
          name: item.dropOff.receiverName,
          phoneName: item.dropOff.dropPhoneName === "" ? item.dropOff.receiverName : item.dropOff.dropPhoneName,
          phoneNumber: item.dropOff.dropPhoneNumber.toString().substring(2),
          addressName: item.dropOff.dropLabel === "" ? item.dropOff.dropAddress : item.dropOff.dropLabel,
          addressFormat: item.dropOff.dropAddress,
          addressLabel: item.dropOff.dropLabel === "" ? item.dropOff.dropAddress : item.dropOff.dropLabel,
          lnglat: item.dropOff.dropLocation,
          latlng: {
            lat: item.dropOff.dropLocation[1],
            lng: item.dropOff.dropLocation[0],
          },
          itemId: itmId,
          item: {
            itemId: itmId,
            packageCategory: checkItem === undefined ? "Lainnya" : item.itemCategory,
            itemDesc: checkItem === undefined ? item.itemCategory : "",
            weight: item.weight,
            lenght: item.lenght,
            width: item.width,
            height: item.height,
            temperature: checkTemp(item.itemTmp),
            quantity: item.itemQty,
            ...(item.soNumber.length != 0) && { soNumber: item.soNumber },
            ...(item.soNumberCrossdock.length != 0) && { soNumberCrossdock: item.soNumberCrossdock },
          },
          additional: item?.additionalService ?? [],
          tag: item?.dropOff?.tagAddress || tagsList.find((tag) => tag.is_primary === 1)
        }
        dropLoc.push(drop)
      })
      locId += 1
      transferLocation.push(pickLoc)
      dropLoc.map((drop) => {
        transferLocation.push(drop)
      })
      setCenter(pickLoc.latlng)
    }))
    setLocation(transferLocation)
    setlocationId(locId + 1)
    setTargetLocationId(locId + 1)
    setItemId(itmId + 1)
    setTargetItemId(itmId + 1)

    setLocViewType(2)
    setOrderAllow(true)
    setLoadingMapScreen(false)
  }


  const handleServices = (e, service) => {
    // if (service?.name.toLowerCase().split(' ').join('-') === 'superkul-truck' || service?.name.toLowerCase().split(' ').join('-') === 'superkul-truck-ftl') {
    //   setIsRedirectWa({
    //     visible: true,
    //     title: 'Pemesanan Superkul Truck FTL',
    //     message: 'Silahkan langsung menghubungi Sales Superkul untuk mendapatkan Quotation dari kami.',
    //     link: 'https://wa.me/6282130000298?text=Halo%20Superkul.%20Saya%20ingin%20bertanya%20mengenai%20layanan%20Superkul%20Truck%2C%20apakah%20bisa%20dibantu%3F'
    //   })
    //   return
    // }


    setIsMultiOrder(false)
    setLocViewType(0)
    setOrderAllow(false)
    const currentdate = new Date
    setMinDate(currentdate)
    const timepickvalue = format(Date.parse(currentdate), 'HH:mm')
    const findtime = optionTime2HoursAgo.findIndex((opt, index) => {
      return opt >= timepickvalue
    })
    setDatePick(currentdate)
    if (new Date(currentdate).getDay() === 0) {
      // setMinDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
      setDatePick(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
    }
    if (new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getDay() === 0) {
      // setMinDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
      setDatePick(new Date(new Date().getTime()))
    }
    setTimePick(optionTime[findtime])
    if (optionTime[findtime] === undefined) {
      setTimePick('pick-time')
    }
    setTempInput('')

    const selectedservice = service?.name.toLowerCase().split(' ').join('-');
    setAdditionalService([])
    setSelectedService(selectedservice);
    setServiceNameId(service?.id)


    setLocation([])
    setNotes('')
    setName('')
    setPhoneNumber('')
    setItemList([])
    switch (selectedservice) {
      case 'superkul-truck':
        setMinDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
        setDatePick(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
        // setVehicles(middleMileVehicles)
        setIsBike('')
        setVehicleTypeId('')
        setSelectedVehicle('')
        break;

      case 'superkul-truck-ftl':
        setMinDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
        setDatePick(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
        // setVehicles(middleMileVehicles)
        setIsBike('')
        setVehicleTypeId('')
        setSelectedVehicle('')
        // setMaximumPackage({
        //   weight: parseInt(middleMileVehicles[0]?.weight),
        //   height: parseInt(middleMileVehicles[0]?.height),
        //   width: parseInt(middleMileVehicles[0]?.width),
        //   length: parseInt(middleMileVehicles[0]?.length),
        // })
        break;

      case 'superkul-truck-ltl':
        setMinDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
        setDatePick(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
        // setVehicles(middleMileVehicles)
        setIsBike('CDE')
        setVehicleTypeId(middleMileVehicles[0]?.id)
        setSelectedVehicle('CDE')
        setMaximumPackage({
          weight: parseInt(middleMileVehicles[0]?.weight),
          height: parseInt(middleMileVehicles[0]?.height),
          width: parseInt(middleMileVehicles[0]?.width),
          length: parseInt(middleMileVehicles[0]?.length),
        })
        break;

      case 'super-express':
        // setVehicles(expressVehicles)
        setIsBike('Bike Express')
        setVehicleTypeId(expressVehicles[0]?.id)
        setSelectedVehicle('Bike Express')
        setTemperature(10)
        setMaximumPackage({
          weight: parseInt(expressVehicles[0]?.weight),
          height: parseInt(expressVehicles[0]?.height),
          width: parseInt(expressVehicles[0]?.width),
          length: parseInt(expressVehicles[0]?.length),
        })
        setSizeData(sizeDataExpress)
        break

      default:
        // setVehicles(defaultVehicles)
        setIsBike('Bike')
        setVehicleTypeId(bikeId)
        setSelectedVehicle('Bike')
        setMaximumPackage({
          weight: parseInt(defaultVehicles[0]?.weight),
          height: parseInt(defaultVehicles[0]?.height),
          width: parseInt(defaultVehicles[0]?.width),
          length: parseInt(defaultVehicles[0]?.length),
        })
        setSizeData(sizeDataDefault)
        break;
    }

    if (e.target.value === 'superkul-truck-ftl' || e.target.value === 'superkul-truck-ltl') {
      setMinDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
      setDatePick(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
      // setIsBike('')
      // setVehicleTypeId('')
      // setSelectedVehicle('')
    }

    setToggleShow(false)
    setDetailOpen(false)
  };

  const handleIsBike = (e, vehicledata) => {
    const isbike = e.target.value;
    if (isbike === "Bike") {
      setMaximumPackage({
        weight: parseInt(vehicledata.weight),
        height: parseInt(vehicledata.height),
        width: parseInt(vehicledata.width),
        length: parseInt(vehicledata.length),
      })
    }
    setIsBike(isbike);
    setSelectedVehicle(isbike)
    setVehicleTypeId(vehicledata?.id)
  };

  const handleAdditional = (e, listid, position) => {
    const updateselected = selectedAdditional.map((item, index) => {
      return index === position ? !item : item
    })
    setSelectedAdditional(updateselected)
    let list = []
    const additionalAdd = additionalList.filter((e, indexList) => {
      updateselected.map((select, i) => {
        if (i === indexList) {
          if (select === true) {
            const check = {
              id: e.id,
            }
            list.push(check)
          }
        }
      })
    })
    setAdditionalService(list)
  }

  const handleAdditionalDrop = (position) => {
    const updateselected = selectedAdditional.map((item, index) => {
      return index === position ? !item : item
    })
    setSelectedAdditional(updateselected)
    let list = []
    additionalList.filter((e, indexList) => {
      updateselected.map((select, i) => {
        if (i === indexList) {
          if (select === true) {
            const check = {
              id: e.id,
            }
            list.push(check)
          }
        }
      })
    })
    setAdditionalService(list)
  }

  const handlePickTime = () => {
    if (datePick.getDate() === currentDate.getDate() && datePick.getMonth() === currentDate.getMonth() && datePick.getFullYear() === currentDate.getFullYear() && format(currentDate, "HH:mm") > "16:00") {
      errorPopup(t('error'), t('pickupTimeNotAvail'), t('close'))
    }
  }

  // Input Location Function
  const handleAddLocation = () => {
    if (serviceNameId === '') {
      errorPopup(t('error'), t('selectServiceFirst'), t('close'))
      return
    }
    if (vehicleTypeId === '') {
      errorPopup(t('error'), t('selectVehicleFirst'), t('close'))
      return
    }
    if (customerId === '') {
      errorPopup(t('error'), t('somethingError'), t('close'))

      return
    }
    if (locViewType !== 2 && location.length > 0) {
      errorPopup(t('error'), t('fillDataCorrectly'), t('close'))
      return
    }

    const tagsDefault = tagsList.find(tag => tag.is_primary === 1)

    let addloc = {}
    if (location.length > 0) {
      if (point === 'Pick') {
        if (location[0].name === '' || location[0].phoneNumber === '') {
          errorPopup(t('error'), t('fillPickupDataCorrectly'), t('close'))
          return
        }
      }

      if (point === 'Drop') {
        if (selectedService.includes('superkul-truck')) {
          if (location[location.length - 1].name === '' || location[location.length - 1].phoneNumber === '') {
            errorPopup(t('error'), t('fillDropDataCorrectly'), t('close'))
            return
          }
        } else {
          if (
            location[location.length - 1].name === '' ||
            location[location.length - 1].phoneNumber === '' ||
            location[location.length - 1].item.length === 0
          ) {
            errorPopup(t('error'), t('fillDropDataCorrectly'), t('close'))
            return
          }
        }
      }
      setPoint('Drop')
      addloc = {
        point: 'Drop',
        locationId: locationId,
        notes: '',
        name: '',
        phoneName: '',
        phoneNumber: '',
        addressName: '',
        addressFormat: '',
        addressLabel: '',
        lnglat: [],
        latlng: {},
        item: [],
        itemId: '',
        additional: [],
        tag: {
          name: tagsDefault?.name,
          time: tagsDefault?.time,
          is_primary: tagsDefault?.is_primary
        }
      }
      ReactGA.event({
        category: "order_category",
        action: "custweb_order_add_drop_location",
        // label: "start_delivery_label", 
        // value: 99
      });
    } else {
      setPoint('Pick')
      addloc = {
        point: 'Pick',
        locationId: locationId,
        notes: '',
        name: '',
        phoneName: '',
        phoneNumber: '',
        addressName: '',
        addressFormat: '',
        addressLabel: '',
        lnglat: [],
        latlng: {},
        item: [],
        itemId: '',
        additional: [],
        tag: {
          name: tagsDefault?.name,
          time: tagsDefault?.time,
          is_primary: tagsDefault?.is_primary
        }
      }
      // Automatic Hide Service
      setShowHideService(!showHideService)
      ReactGA.event({
        category: "order_category",
        action: "custweb_order_add_pick_location",
        // label: "start_delivery_label", 
        // value: 99
      });
    }
    setTargetLocationId(locationId)
    setLocation(loc => [...loc, addloc])
    setlocationId(locationId + 1)
    setIsSaveBookmark(false)
    setItemList([])

    // Set Initial Data
    setPackageCategory('')
    setTemperature(0)
    setValue('notes', '')
    setValue('name', '')
    setValue('phoneNumber', '')
    setValue('bookmarkLabel', '')
    setValue('itemDesc', '')
    setPackageSize('')
    setIsCustomeSize(false)
    setValue('weight', '')
    setValue('lenght', '')
    setValue('width', '')
    setValue('height', '')
    setSoNumberTemporary([])

    // Set Additional Service
    setAdditionalService([])
    setSelectedAdditional(
      new Array(additionalList.length).fill(false)
    )

    // Set Detail View
    setToggleShow(false)
    setDetailOpen(false)
  }

  const handleChooseSavedAddress = (data) => {
    setOpenSavedAddress(false)
    setLoadingMapScreen(true)
    const addressformat = data.address
    const addressname = data.label
    const lat = parseFloat(data.lan)
    const lng = parseFloat(data.lon)
    const changeAddress = location.map(loc => {
      if (loc.locationId === targetLocationId) {
        loc.point === 'Pick' ? setPoint('Pick') : setPoint('Drop')
        const newLoc = { ...loc, addressFormat: addressformat, addressName: addressname, addressLabel: addressname, lnglat: [lng, lat], latlng: { lat, lng } }
        return newLoc
      } else {
        return loc
      }
    })
    setFormattedAddress(addressformat)
    setLat(lat)
    setLng(lng)
    if (data.notes !== undefined && data.name !== undefined && data.phone !== undefined) {
      setValue('notes', data.notes === null ? '' : data.notes)
      setValue('name', data.name === null ? '' : data.name)
      setValue('phoneNumber', data.phone === null ? '' : data.phone.substring(2))
      setTag({
        name: data?.tags?.name,
        time: data?.tags?.time,
        is_primary: data?.tags?.is_primary
      } || tagsList.find((tag) => tag.is_primary === 1))
    }
    setCenter({ lat, lng })
    setPointLabel(addressname)
    setLocation(changeAddress)
    setLocViewType(1)
    setLoadingMapScreen(false)
    setToggleShow(true)
    setDetailOpen(true)
  }

  const handleRemoveLocation = (inputData) => {

    if (inputData.point === 'Pick') {
      const findDrop = location.find(loc => {
        return loc.point === 'Drop'
      })
      if (findDrop === undefined) {
        const removePick = location.filter(loc => loc.locationId !== inputData.locationId)
        setLocation(removePick)
      } else {
        Swal.fire({
          title: t('error'),
          text: t('removeDropFirst'),
          imageUrl: ConfirmIcon,
          showConfirmButton: true,
          confirmButtonColor: '#1F83BB',
          confirmButtonText: t('close'),
          customClass: {
            popup: 'popup-swal',
            title: 'title-swal',
            htmlContainer: 'text-swal',
            confirmButton: 'confirm-swal'
          }
        })
        return
      }
    } else {
      const removeDrop = location.filter(loc => loc.locationId !== inputData.locationId)

      setLocation(removeDrop)
    }
    if (location.length < 3) {
      setOrderAllow(false)
    }

  }

  const handleSearchLocation = (inputData) => {
    setTargetLocationId(inputData.locationId);
    setLocViewType(0);
    clearErrors()
    if (targetLocationId !== inputData.locationId) {
      setDetailOpen(false)
      setLoadingMapScreen(true)
      setTimeout(() => {
        setLoadingMapScreen(false)
        setPointLabel(inputData.addressLabel)
        setPoint(inputData.point)
        setValue('notes', inputData.notes)
        setValue('name', inputData.name)
        setValue('phoneNumber', inputData.phoneNumber)
        setValue('bookmarkLabel', '')
        setTag(inputData.tag || tagsList.find((tag) => tag.is_primary === 1))
        if (inputData.point === 'Pick') {
          setTemperature(inputData.temp === undefined ? 0 : inputData.temp)
          setValue('weight', inputData?.weight)
          setValue('lenght', inputData?.lenght)
          setValue('width', inputData?.width)
          setValue('height', inputData?.height)
        }
        if (inputData.point === 'Drop') {

          setPackageCategory(inputData.item.packageCategory)
          const findSize = sizeData.find((size) => inputData.item.weight == size.weight && inputData.item.width == size.width && inputData.item.lenght == size.lenght && inputData.item.height == size.height)
          setPackageSize('')
          setIsCustomeSize(false)
          findSize ? setPackageSize(findSize.name) : setIsCustomeSize(true)
          setValue('itemDesc', inputData.item.itemDesc)
          setValue('weight', inputData.item.weight)
          setValue('lenght', inputData.item.lenght)
          setValue('width', inputData.item.width)
          setValue('height', inputData.item.height)
          setValue('soNumber', inputData.item.soNumber)
          setSoNumberTemporary(inputData.item.soNumber || [])
          setTemperature(inputData.item.temperature === undefined ? 0 : inputData.item.temperature)


          let addIndex = []
          additionalList.map((e, index) => {
            let check = false
            inputData.additional.map(raw => {
              if (raw.id === e.id) {
                check = true
              }
            })
            addIndex.push(check)
          })
          setAdditionalService(inputData.additional)
          setSelectedAdditional(addIndex)
        }
        setItemList(inputData.item)
        setTargetItemId(inputData.itemId)
        setToggleShow(true);
        setDetailOpen(true)
        setCenter(inputData.latlng)
      }, 1000);
    }
    else {
      setPoint(inputData.point)
      setValue('notes', inputData.notes)
      setValue('name', inputData.name)
      setValue('phoneNumber', inputData.phoneNumber)
      setValue('bookmarkLabel', '')
      setTag(inputData.tag || tagsList.find((tag) => tag.is_primary === 1))
      if (inputData.point === 'Drop') {
        setPackageCategory(inputData.item.packageCategory)
        setValue('itemDesc', inputData.item.itemDesc)
        setValue('weight', inputData.item.weight)
        setValue('lenght', inputData.item.lenght)
        setValue('width', inputData.item.width)
        setValue('height', inputData.item.height)
        setValue('soNumber', inputData.item.soNumber)
        setSoNumberTemporary(inputData.item.soNumber || [])
        setTemperature(inputData.item.temperature === undefined ? 0 : inputData.item.temperature)
      }
      setCenter(inputData.latlng)
      setItemList(inputData.item)
      setTargetItemId(inputData.itemId)
      // setToggleShow(true)
      // setDetailOpen(true)
    }
    setTargetLocationId(inputData.locationId);
  }

  const handleClickLocation = (inputData) => {

    setTargetLocationId(inputData.locationId);
    setLocViewType(0);
    clearErrors()
    if (targetLocationId !== inputData.locationId) {
      setDetailOpen(false)
      setLoadingMapScreen(true)
      setTimeout(() => {
        setLoadingMapScreen(false)
        setPointLabel(inputData.addressLabel)
        setPoint(inputData.point)
        setValue('notes', inputData.notes)
        setValue('name', inputData.name)
        setValue('phoneNumber', inputData.phoneNumber)
        setValue('bookmarkLabel', '')
        setTag(inputData.tag)
        if (inputData.point === 'Pick') {
          setTemperature(inputData.temp === undefined ? 0 : inputData.temp)
        }
        if (inputData.point === 'Drop') {
          setPackageCategory(inputData.item.packageCategory)
          setValue('itemDesc', inputData.item.itemDesc)
          setValue('weight', inputData.item.weight)
          setValue('lenght', inputData.item.lenght)
          setValue('width', inputData.item.width)
          setValue('height', inputData.item.height)
          setValue('soNumber', inputData.item.soNumber?.join(';'))
          setTemperature(inputData.item.temperature === undefined ? 0 : inputData.item.temperature)
          let addIndex = []
          additionalList.map((e, index) => {
            let check = false
            inputData.additional.map(raw => {
              if (raw.id === e.id) {
                check = true
              }
            })
            addIndex.push(check)
          })
          setAdditionalService(inputData.additional)
          setSelectedAdditional(addIndex)
        }
        setItemList(inputData.item)
        setTargetItemId(inputData.itemId)
        setToggleShow(true);
        setDetailOpen(true)
        setCenter(inputData.latlng)
      }, 1000);
    } else {
      setPoint(inputData.point)
      setValue('notes', inputData.notes)
      setValue('name', inputData.name)
      setValue('phoneNumber', inputData.phoneNumber)
      setValue('bookmarkLabel', '')
      setTag(inputData.tag)
      if (inputData.point === 'Drop') {
        setPackageCategory(inputData.item.packageCategory)
        setValue('itemDesc', inputData.item.itemDesc)
        setValue('weight', inputData.item.weight)
        setValue('lenght', inputData.item.lenght)
        setValue('width', inputData.item.width)
        setValue('height', inputData.item.height)
        setValue('soNumber', inputData.item.soNumber?.join(';'))
        setTemperature(inputData.item.temperature === undefined ? 0 : inputData.item.temperature)
      }
      setCenter(inputData.latlng)
      setItemList(inputData.item)
      setTargetItemId(inputData.itemId)
      // setToggleShow(true)
      // setDetailOpen(true)
    }
    setTargetLocationId(inputData.locationId);
  }


  const handleChangeAddressName = (e, id) => {
    storeHitGoogle(auth?.id, "web_customer_autocomplete")
    const changeAddress = location.map(loc => {
      if (loc.locationId === id) {
        const newLoc = { ...loc, addressName: e, addressLabel: e }
        return newLoc
      } else {
        return loc
      }
    })
    setLocation(changeAddress)
  }

  const handleBookmark = (e) => {
    if (e.target.checked) {
      setIsSaveBookmark(true)
    } else {
      setIsSaveBookmark(false)
    }
  }

  const saveToBookmark = async (label, locBookmark) => {
    const addData = {
      user_id: customerId,
      address: formattedAddress,
      label: label,
      name: getValues().name,
      phone: `62${getValues().phoneNumber}`,
      notes: getValues().notes,
      lan: lat,
      lon: lng,
      tag: tag?.name
    }
    try {
      const response = await axios.post(`${url}/api/bookmark`, addData, { headers })
      if (response.data.status === 'failed') {
        errorPopup(t('error'), response.data.message, t('close'))
        return
      }
      Swal.fire({
        title: t('addressSavedSuccess'),
        timer: 2000,
        imageUrl: SuccessIcon,
        showConfirmButton: false,
        customClass: {
          popup: 'popup-swal',
          title: 'title-swal'
        }
      })

      const changeAddress = locBookmark.map(loc => {
        if (loc.locationId === targetLocationId) {
          const newLoc = { ...loc, addressLabel: label }
          return newLoc
        } else {
          return loc
        }
      })
      setLocation(changeAddress)
    } catch (error) {
      console.log(error.message)
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    }
    setBookmarkReload(!bookmarkReload)
    setIsSaveBookmark(false)
    setTargetLocationId('')
  }


  // Location Function
  const savePoint = async (form) => {

    setLoadingMapScreen(true)
    // * IF SAVE BOOKMARK CHECKED
    if (isSaveBookmark) {
      if (bookmarkLabel === '') {
        Swal.fire({
          title: t('fillLabelBookmark'),
          timer: 2000,
          icon: 'error',
          showConfirmButton: false,
          customClass: {
            popup: 'popup-swal',
            title: 'title-swal'
          }
        })
        return
      }
    }


    let locationForBookmark

    if (point === 'Pick') {
      const updateData = location.map(loc => {
        if (loc.locationId === targetLocationId) {
          const newData = {
            ...loc,
            point: point,
            notes: form.notes,
            name: form.name,
            phoneName: form.name,
            phoneNumber: form.phoneNumber,
            temp: temperature,
            tag: tag,
            weight: form.weight,
            lenght: form.lenght,
            width: form.width,
            height: form.height,
          }
          return newData
        } else {
          return loc
        }
      })
      locationForBookmark = updateData
      setLocation(updateData)
    } else {
      let selectedDrop
      if (!selectedService.includes('superkul-truck')) {
        if (packageCategory === '' || packageCategory === undefined) {
          setLoadingMapScreen(false)
          errorPopup(t('error'), t('selectPackagesCategoryFirst'), t('close'))
          return
        }
        if (!isCustomeSize && selectedService !== 'superkul-truck-ltl') {
          if (packageSize === '') {
            setLoadingMapScreen(false)
            errorPopup(t('error'), t('selectPackagesSize'), t('close'))
            return
          }
        }
      }

      const newItem = {
        itemId: targetItemId,
        packageCategory: packageCategory,
        itemDesc: form.itemDesc,
        weight: form.weight,
        lenght: form.lenght,
        width: form.width,
        height: form.height,
        temperature: temperature,
        quantity: quantity,
        // soNumber: form?.soNumber?.split(';')
        soNumber: soNumberTemporary
      }

      const updateData = location.map(loc => {
        if (loc.locationId === targetLocationId) {
          const newData = {
            ...loc,
            point: point,
            notes: form.notes,
            name: form.name,
            phoneName: form.name,
            phoneNumber: form.phoneNumber,
            item: newItem,
            itemId: targetItemId,
            additional: additionalService,
            tag: tag
          }
          selectedDrop = newData
          return newData
        } else {
          return loc
        }
      })

      setLocation(updateData)
      locationForBookmark = updateData
      setOrderAllow(true)
    }

    if (isSaveBookmark) {
      // saveToBookmark(bookmarkLabel, locationForBookmark)
      const addData = {
        user_id: customerId,
        address: formattedAddress,
        label: bookmarkLabel,
        name: getValues().name,
        phone: `62${getValues().phoneNumber}`,
        notes: getValues().notes,
        lan: lat,
        lon: lng,
        tag: tag?.name
      }
      try {
        const response = await axios.post(`${url}/api/bookmark`, addData, { headers })
        if (response.data.status === 'failed') {
          errorPopup(t('error'), response.data.message, t('close'))
          return
        }
        Swal.fire({
          title: t('addressSavedSuccess'),
          timer: 2000,
          imageUrl: SuccessIcon,
          showConfirmButton: false,
          customClass: {
            popup: 'popup-swal',
            title: 'title-swal'
          }
        })

        const changeAddress = locationForBookmark.map(loc => {
          if (loc.locationId === targetLocationId) {
            const newLoc = { ...loc, addressLabel: bookmarkLabel }
            return newLoc
          } else {
            return loc
          }
        })
        setLocation(changeAddress)

        setBookmarkReload(!bookmarkReload)
        setIsSaveBookmark(false)
        setTargetLocationId('')
      } catch (error) {
        console.log(error.message)
        errorMessage(error, t('error'), t('somethingWrong'), t('close'))
        return
      } finally {
        setLoadingMapScreen(false)
      }
    }

    setTimeout(() => {
      setLoadingMapScreen(false)
      setLocViewType(2)
      setToggleShow(false)
      setDetailOpen(false)
      if (!isSaveBookmark) {
        setTargetLocationId('')
      }
      isbookmarkref.current.checked = false
    }, 1000);
  }

  const cancelPoint = (e) => {
    setTargetLocationId('')
    setLocViewType(2)
    setToggleShow(false);
    setDetailOpen(false)
    isbookmarkref.current.checked = false
  }



  // Maps Config
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDfdR5Et5GQSGbLqWjFbXB7JNjLIA3Q9Vw',
    libraries
  })

  if (!isLoaded) {
    return (
      <h1></h1>
    )
  }

  // Geocode
  const storeGeocode = async (data) => {
    try {
      await sendPostRequestMobile('/api/map-response', data)
    } catch (error) {
      errorMessage(error, t('error'), t('somethingError'), t('close'))
    }
  }

  const superkulGeocode = async (e, targetId) => {

    setLoadingMapScreen(true)
    const recentLocal = JSON.parse(localStorage.getItem('recentAddr'))

    try {
      const { data } = await sendGetRequestMobile("/api/geolocation", {
        params: {
          query: e
        }
      })
      // console.log(data["0"]?.results[0])
      // console.log(e)
      // console.log(data["0"]?.results[0]?.formatted_address)
      // console.log(data["0"]?.results[0]?.geometry.location.lat)
      // console.log(data["0"]?.results[0]?.geometry.location.lng)
      const addressformat = data["0"]?.results[0]?.formatted_address
      const addressname = e
      const lat = data["0"]?.results[0]?.geometry.location.lat
      const lng = data["0"]?.results[0]?.geometry.location.lng
      const changeAddress = location.map(loc => {
        if (loc.locationId === targetId) {
          loc.point === 'Pick' ? setPoint('Pick') : setPoint('Drop')
          const newLoc = { ...loc, addressFormat: addressformat, addressName: addressname, addressLabel: addressname, lnglat: [lng, lat], latlng: { lat, lng } }
          return newLoc
        } else {
          return loc
        }
      })
      const recent = {
        address: addressformat,
        label: addressname,
        lan: lat,
        lon: lng
      }

      if (recentLocal === null) {
        localStorage.setItem('recentAddr', JSON.stringify([recent]))
      } else {
        localStorage.setItem('recentAddr', JSON.stringify([...recentLocal, recent]))
      }
      setRecentLocation([...recentLocation, recent])
      setFormattedAddress(addressformat)
      setLat(lat)
      setLng(lng)
      setCenter({ lat, lng })
      setPointLabel(addressname)
      setLocation(changeAddress)
      setLocViewType(1)
      setToggleShow(true)
      setDetailOpen(true)
      setLoadingMapScreen(false)
      // console.log("superkul geocode success")
    } catch (error) {
      console.log(error.message)
      // console.log("superkul geocode failed")
      if (error?.response?.status == 404) {
        placeChanged(e, targetId)
      } else {
        errorPopup(t('error'), t('somethingWrong'), t('close'))
      }
    }
  }

  const placeChanged = (e, targetId) => {
    /* eslint-disable */
    setLoadingMapScreen(true)
    const geocoder = new google.maps.Geocoder()
    const recentLocal = JSON.parse(localStorage.getItem('recentAddr'))

    geocoder.geocode({ 'address': e }).then((response) => {
      storeGeocode(response)
      const addressformat = response.results[0].formatted_address
      const addressname = e
      const lat = response.results[0].geometry.location.lat()
      const lng = response.results[0].geometry.location.lng()
      const changeAddress = location.map(loc => {
        if (loc.locationId === targetId) {
          loc.point === 'Pick' ? setPoint('Pick') : setPoint('Drop')
          const newLoc = { ...loc, addressFormat: addressformat, addressName: addressname, addressLabel: addressname, lnglat: [lng, lat], latlng: { lat, lng } }
          return newLoc
        } else {
          return loc
        }
      })
      const recent = {
        address: addressformat,
        label: addressname,
        lan: lat,
        lon: lng
      }

      if (recentLocal === null) {
        localStorage.setItem('recentAddr', JSON.stringify([recent]))
      } else {
        localStorage.setItem('recentAddr', JSON.stringify([...recentLocal, recent]))
      }
      setRecentLocation([...recentLocation, recent])
      setFormattedAddress(addressformat)
      setLat(lat)
      setLng(lng)
      setCenter({ lat, lng })
      setPointLabel(addressname)
      setLocation(changeAddress)
      setLocViewType(1)
      setLoadingMapScreen(false)
      setToggleShow(true)
      setDetailOpen(true)
      // console.log("google geocode success")
    }).catch((error) => {
      console.log(error.message)
      setLoadingMapScreen(false)
      errorPopup(t('error'), t('locationNotFound'), t('close'))
    }).finally(() => {
      storeHitGoogle(auth?.id, "web_customer_geocode")
    })
  }

  // Reverse Geocode
  const dragEnd = (e, targetId) => {
    /* eslint-disable */
    setLoadingMapScreen(true)
    setTargetLocationId(targetId)
    const geocoder = new google.maps.Geocoder()
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    const latlng = {
      lat,
      lng
    }

    setLat(lat)
    setLng(lng)
    geocoder.geocode({ location: latlng })
      .then((response) => {
        const addressformat = response.results[0].formatted_address
        const addressname = response.results[0].name
        setFormattedAddress(addressformat)
        const changeAddress = location.map(loc => {
          if (loc.locationId === targetId) {
            const newLoc = { ...loc, addressFormat: addressformat, addressName: addressformat, addressLabel: addressformat, lnglat: [lng, lat], latlng: { lat, lng } }
            return newLoc
          } else {
            return loc
          }
        })


        setFormattedAddress(addressformat)
        setLat(lat)
        setLng(lng)
        setLocation(changeAddress)
        setLocViewType(0)
        setLoadingMapScreen(false)
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e))
      .finally(() => storeHitGoogle(auth?.id, "web_customer_geocode"));
  }


  // ! FINAL PROCESS
  const handleCreateOrder = async () => {

    // setLoadingMapScreen(true)
    if (serviceNameId === '') {
      errorPopup(t('error'), t('selectServiceFirst'), t('close'))
      return
    }

    let time = timePick

    const currenttime = new Date
    if (selectedService === 'instant-delivery') {
      time = format(Date.parse(currenttime), 'HH:mm')
    } else {
      if (datePick === '' || timePick === 'pick-time' || timePick === undefined || timePick === null || timePick === '') {
        errorPopup(t('error'), t('selectDateTimeFirst'), t('close'))
        return
      }
    }

    if (format(datePick, 'yyyy-MM-dd') < format(currenttime, 'yyyy-MM-dd')) {
      errorPopup(t('error'), t('pickupdateNotAvail'), t('close'))
      return
    }

    // if (new Date(datePick).getDay() === 0) {
    //   errorPopup(t('error'), t('pickupdateNotAvail'), t('close'))
    //   return
    // }

    const optIndex = optionTime.findIndex(optTime => time === optTime)
    const opt2hours = optionTime2HoursAgo.find((optTime, i) => i === optIndex)

    // ! Disini problem import sameday
    if (
      time < format(Date.parse(currenttime) + 120 * 60 * 1000, 'HH:mm') &&
      datePick.getDate() === currenttime.getDate() &&
      datePick.getMonth() === currenttime.getMonth() &&
      datePick.getFullYear() === currenttime.getFullYear() &&
      selectedService === 'superkul-truck-ftl') {
      errorPopup(t('error'), t('pickuptimeNotAvail'), t('close'))
      return
    }

    if (
      time < format(Date.parse(currenttime) + 120 * 60 * 1000, 'HH:mm') &&
      datePick.getDate() === currenttime.getDate() &&
      datePick.getMonth() === currenttime.getMonth() &&
      datePick.getFullYear() === currenttime.getFullYear() &&
      selectedService === 'superkul-truck-ltl') {
      errorPopup(t('error'), t('pickuptimeNotAvail'), t('close'))
      return
    }

    if (
      datePick.getDate() === currenttime.getDate() &&
      datePick.getMonth() === currenttime.getMonth() &&
      datePick.getFullYear() === currenttime.getFullYear() && selectedService === 'superkul-truck-ftl') {
      errorPopup(t('error'), t('superkultruckOnlyTommorow'), t('close'))
      return
    }

    if (
      datePick.getDate() === currenttime.getDate() &&
      datePick.getMonth() === currenttime.getMonth() &&
      datePick.getFullYear() === currenttime.getFullYear() && selectedService === 'superkul-truck-ltl') {
      errorPopup(t('error'), t('superkultruckOnlyTommorow'), t('close'))
      return
    }

    if (time > '16:00') {
      errorPopup(t('error'), t('pickupBefore16'), t('close'))
      return
    }

    // Get only pickLocation
    const pickLocation = location.filter(loc => {
      return loc.point === 'Pick' && loc.addressFormat !== ''
    })

    // Get only dropLocation for middle mile/ super truck
    const dropLocation = location.filter(loc => {
      return loc.point === 'Drop' && loc.addressFormat !== ''
    })


    if (vehicleTypeId === '') {
      errorPopup(t('error'), t('selectServiceFirst'), t('close'))
      return
    }
    if (customerId === '') {
      errorPopup(t('error'), t('somethingWrong'), t('close'))
      return
    }
    if (pickLocation.length === 0) {
      errorPopup(t('error'), t('addPickupFirst'), t('close'))
      return
    }
    if (dropLocation.length === 0) {
      errorPopup(t('error'), t('addDropFirst'), t('close'))
      return
    }

    let orderData
    if (selectedService === 'superkul-truck' || selectedService === 'superkul-truck-ftl' || selectedService === 'superkul-truck-ltl') {
      orderData = {
        serviceNameId: serviceNameId,
        customerId: customerId,
        datePick: format(datePick, 'yyyy-MM-dd'),
        timePick: time,
        vehicleTypeId: vehicleTypeId,
        pickUp: {
          pickNotes: pickLocation[0].notes === "" ? "-" : pickLocation[0].notes,
          senderName: pickLocation[0].name,
          pickPhoneName: pickLocation[0].name,
          pickPhoneNumber: `62${pickLocation[0].phoneNumber}`,
          pickAddress: pickLocation[0].addressFormat,
          pickLabel: pickLocation[0].addressLabel,
          pickLocation: pickLocation[0].lnglat,
          itemTmp: parseInt(pickLocation[0].temp),
          weight: parseInt(pickLocation[0]?.weight),
          height: parseInt(pickLocation[0]?.height),
          width: parseInt(pickLocation[0]?.width),
          lenght: parseInt(pickLocation[0]?.lenght),
          tagAddress: pickLocation[0]?.tag || tagsList.find((tag) => tag.is_primary === 1),
        },
        dropOff:
          dropLocation.map((dropLoc) => {
            return (
              {
                dropNotes: dropLoc.notes === "" ? "-" : dropLoc.notes,
                receiverName: dropLoc.name,
                dropPhoneName: dropLoc.name,
                dropPhoneNumber: `62${dropLoc.phoneNumber}`,
                dropAddress: dropLoc.addressFormat,
                dropLabel: dropLoc.addressLabel,
                dropLocation: dropLoc.lnglat
              }
            )
          })
      }
    } else {
      orderData = {
        serviceNameId: serviceNameId,
        customerId: customerId,
        datePick: format(datePick, 'yyyy-MM-dd'),
        ...(isMultiOrder) && { datePickRepeat: datePickMulti.map((e) => format(e?.date, 'yyyy-MM-dd')) },
        timePick: time,
        vehicleTypeId: vehicleTypeId,
        additionalService: [],
        extraService: extraService,
        ...(crossdockId) && { crossdockId: crossdockId },
        pickUp:
          pickLocation.map(pickLoc => {
            return (
              {
                notes: pickLoc.notes === "" ? "-" : pickLoc.notes,
                senderName: pickLoc.name,
                pickPhoneName: pickLoc.name,
                pickPhoneNumber: `62${pickLoc.phoneNumber}`,
                pickAddress: pickLoc.addressFormat,
                pickLabel: pickLoc.addressLabel,
                pickLocation: pickLoc.lnglat,
                additionalService: [],
                tagAddress: pickLoc?.tag || tagsList.find((tag) => tag.is_primary === 1),
                item:
                  dropLocation.map(dropLoc => {
                    return (
                      {
                        itemCategory: dropLoc.item.packageCategory === "Lainnya" ? dropLoc.item.itemDesc : dropLoc.item.packageCategory,
                        weight: parseInt(dropLoc.item.weight),
                        height: parseInt(dropLoc.item.height),
                        width: parseInt(dropLoc.item.width),
                        lenght: parseInt(dropLoc.item.lenght),
                        itemTmp: selectedService === 'super-express' ? 11 : dropLoc.item.temperature,
                        itemQty: parseInt(dropLoc.item.quantity),
                        ...(dropLoc?.item?.soNumber) && { soNumber: dropLoc.item.soNumber },
                        ...(dropLoc?.item?.soNumberCrossdock) && { soNumberCrossdock: dropLoc.item.soNumberCrossdock },
                        dropOff: {
                          dropNotes: dropLoc.notes === "" ? "-" : dropLoc.notes,
                          receiverName: dropLoc.name,
                          dropPhoneName: dropLoc.name,
                          dropPhoneNumber: `62${dropLoc.phoneNumber}`,
                          dropAddress: dropLoc.addressFormat,
                          dropLabel: dropLoc.addressLabel,
                          dropLocation: dropLoc.lnglat,
                          tagAddress: dropLoc?.tag,
                        },
                        additionalService: dropLoc.additional,
                      }
                    )
                  })
              }
            )
          })
      }
    }

    setOrderId(orderData)

    // console.log(orderData)
    // return

    setLoadingMapScreen(true)

    const lang = localStorage.getItem('lang')

    const tmpOrderUrl = auth?.userType === 3 && !selectedService.includes('superkul-truck') ? '/api/tmp-order-corporate' : '/api/tmp-order'


    try {
      const response = await axios.post(`${url}${tmpOrderUrl}`, orderData, { headers })
      setLoadingMapScreen(false)
      if (response.data.status === 'failed') {
        ReactGA.event({
          category: "order_category",
          action: "custweb_order_temp_api_failed",
          // label: "start_delivery_label", 
          // value: 99
        });
        response.data.data ?
          errorPopup(t('checkAddress'), `<strong>${response.data.data}</strong> ${response.data.message}`, t('gotit'), LocationTooFarIcon)
          :
          errorPopup(t('checkAddress'), lang === "en" ? response?.data?.message_en : response?.data?.message, t('gotit'), LocationTooFarIcon)
        return
      }

      // if (selectedService === 'superkul-truck-ltl') {
      //   setLoadingMapScreen(false)
      //   setIsMultiOrder(false)
      //   setLocViewType(0)
      //   setOrderAllow(false)
      //   setTempInput('')
      //   setAdditionalService([])
      //   setLocation([])
      //   setNotes('')
      //   setName('')
      //   setPhoneNumber('')
      //   setItemList([])
      //   setToggleShow(false)
      //   setDetailOpen(false)

      //   setIsRedirectWa({
      //     visible: true,
      //     title: 'Terima kasih telah melakukan pre-order Superkul Truck LTL',
      //     message: 'Kami akan segera menghubungi Anda. Apabila Anda memiliki pertanyaan, silahkan hubungi via Whatsapp dibawah ini.',
      //     link: 'https://wa.me/6282130000298?text=Halo%20Superkul.%20Saya%20ingin%20bertanya%20mengenai%20layanan%20Superkul%20Truck%2C%20apakah%20bisa%20dibantu%3F'
      //   })
      //   return
      // }

      if (response.data.data !== undefined) {
        const id = response.data.data
        setOrderId(id)
        ReactGA.event({
          category: "order_category",
          action: "custweb_order_temp_api_success",
          // label: "start_delivery_label", 
          // value: 99
        });
        if (promoCode === '' || promoCode === null) {
          navigate(`trip-planning/${id._id}`)
        } else {
          navigate(`trip-planning/${id._id}?prm=${promoCode}`)
        }
      }
      if (response.data.data === undefined) {
        errorPopup(t('error'), response.data.message, t('close'))
      }
    } catch (error) {
      console.log(error.message)
      setLoadingMapScreen(false)
      ReactGA.event({
        category: "order_category",
        action: "custweb_order_temp_api_error",
        // label: "start_delivery_label", 
        // value: 99
      });
      if (error.message === 'Network Error') {
        networkErrorPopup(t('networkErrorTitle'), t('networkErrorText'), t('reload'), t('cancel'))
      } else {
        errorPopup(t('error'), t('somethingError'), t('close'))
      }
    }
  }

  const backButton = () => {
    if (drftNum === null) {
      navigate('/delivery')
    } else {
      navigate(`/draft-detail/${drftNum}`)
    }
  }

  const handleSaveMultiDate = (e) => {
    setDatePick(e[0]?.date)
    setDatePickMulti(e)
  }

  // * REACT COMPONENT
  const HeaderOrder = () => {
    return (
      <div className="header-order">
        <div className="title" >
          <img src={HomeIcon} alt='' className="home-btn" onClick={backButton} />
          <p className="title-text">{t('createOrder')}</p>
        </div>
        <div className="header-progress">
          <div className='create active'>
            <h6 className="progress-index">1</h6>
            <h6 className="progress-name">{t('createOrder')}</h6>
          </div>
          <div className='trip-planning'>
            <h6 className="progress-index">2</h6>
            <h6 className="progress-name">{t('tripPlanning')}</h6>
          </div>
          <div className='order-confirm'>
            <h6 className="progress-index">3</h6>
            <h6 className="progress-name">{t('orderConfirm')}</h6>
          </div>
          <div className='order-confirm'>
            <h6 className="progress-index">4</h6>
            <h6 className="progress-name">{t('payment')}</h6>
          </div>
        </div>
      </div>
    )
  }

  const Input = () => (
    <input
      value={`${datePickMulti[0]?.date === '' ? format(addDays(new Date(), 1), 'dd MMM yyyy') : format(datePickMulti[0]?.date, 'dd MMM yyyy')}, . . .`}
      className='input-text'
      onClick={toggleMultiDatePick}
    />
  );


  return (
    <div className="start-delivery">

      {loadingMapScreen && <div className="loading-map-screen"></div>}

      <InputSavedAddress isOpen={openSavedAddress} toggle={toggleSavedAddress} data={handleChooseSavedAddress} id={customerId} trigger={bookmarkReload} />

      <ImportOrder
        isOpen={openImportOrder}
        toggle={() => setOpenImportOrder({ visible: !openImportOrder.visible })}
        extractData={handleImportOrder}
        id={customerId}
      />

      <MultiOrderDatePick isOpen={openMultiDatePick} toggle={toggleMultiDatePick} onFinish={(e) => handleSaveMultiDate(e)} defaultValue={datePickMulti} />

      <RedirectWhatsappModal
        isOpen={isRedirectWa}
        toggle={() => setIsRedirectWa({ visible: false })}
      />

      <Helmet>
        <title>Superkul</title>
      </Helmet>
      <div className="order-side">
        <HeaderOrder />
        <div className="create-order">
          <div className={`service ${showHideService ? 'hide' : ''}`}>
            <div className="select-service">
              <div className="header-select-service">
                <p className="text-title" >{t('selectService')}</p>
              </div>

              {servicesLoader === true ?
                <div className="loading-skeleton">
                  <Row>
                    <Col
                      style={{
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                        paddingLeft: '1.5rem'
                      }}
                    >
                      <TitleSkeleton
                        style={{
                          width: '2.3rem',
                          height: '2.3rem',
                          borderRadius: '0.5rem',
                        }}
                      />
                      <TitleSkeleton
                        style={{
                          width: '60%',
                          height: '0.9rem',
                          borderRadius: '0.25rem'
                        }}
                      />
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                        paddingLeft: '1.5rem'
                      }}
                    >
                      <TitleSkeleton
                        style={{
                          width: '2.3rem',
                          height: '2.3rem',
                          borderRadius: '0.5rem',
                        }}
                      />
                      <TitleSkeleton
                        style={{
                          width: '60%',
                          height: '0.9rem',
                          borderRadius: '0.25rem'
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                :
                <div className="form-service">
                  {services.map((service, i) => {
                    const label = service.name
                    const name = service.name.toLowerCase().split(' ').join('-')
                    return (
                      <div className={`${name}-option`}>
                        <input
                          type="radio"
                          id={name}
                          name={name}
                          value={name}
                          onClick={(e) => handleServices(e, service)}
                        />
                        <label className={`label-radio ${selectedService === name ? 'active' : ''}`} htmlFor={name}>
                          {name === 'sameday-delivery' &&
                            <img src={selectedService === name ? SamedayIconActive : SamedayIcon} alt="sameday" className={`icon-${name}`} />
                          }
                          {name === 'instant-delivery' &&
                            <img src={selectedService === name ? InstantIconActive : InstantIcon} alt="sameday" className={`icon-${name}`} />
                          }
                          {name === 'superkul-truck' &&
                            <img src={selectedService === name ? CDDIconActive : CDDIcon} alt="sameday" className={`icon-${name}`} />
                          }
                          {name === 'superkul-truck-ftl' &&
                            <img src={selectedService === name ? CDDIconActive : CDDIcon} alt="sameday" className={`icon-${name}`} />
                          }
                          {name === 'superkul-truck-ltl' &&
                            <img src={selectedService === name ? CDDIconActive : CDDIcon} alt="sameday" className={`icon-${name}`} />
                          }
                          {name === 'super-express' &&
                            <img src={selectedService === name ? ExpressIconActive : ExpressIcon} alt="sameday" className={`icon-${name}`} />
                          }
                          {label}
                        </label>
                      </div>
                    )
                  })}
                  {selectedService !== "instant-delivery" && (
                    <>
                      {/* {selectedService === 'sameday-delivery' && auth?.userType == 3 && !crossdockId &&
                        <div className="multiorder w-100">
                          <input
                            type="checkbox"
                            name="multi-order"
                            id="multi-order"
                            onChange={() => {
                              setIsMultiOrder(!isMultiOrder)
                              setDatePick(datePickMulti[0]?.date)
                            }}
                            checked={isMultiOrder}
                          />
                          <label htmlFor="multi-order">{t('multiOrderCheck')}</label>
                        </div>
                      } */}
                      <div className="d-flex flex-wrap gap-md-0 gap-1 justify-content-around w-100">
                        <div className="input-wrapper">
                          <label className="label-input" htmlFor="start">
                            {t('datePickup')}
                          </label>
                          {isMultiOrder ?
                            <DatePicker
                              dateFormat="dd MMM yyyy"
                              className='input-text'
                              customInput={<Input />}
                            />
                            :
                            <DatePicker
                              selected={datePick}
                              dateFormat="dd MMM yyyy"
                              className='input-text'
                              placeholderText="Pick Date"
                              minDate={minDate}
                              onChange={(date) => {
                                setDatePick(date === null ? new Date() : date);
                                setTimePick('pick-time')
                              }}
                            // filterDate={date => {
                            //   // Disable weekends (Saturday and Sunday)
                            //   return date.getDay() !== 0;
                            // }}
                            />
                          }
                          <img src={CalendarIcon} alt="" />
                        </div>
                        <div className="input-wrapper">
                          <label for="package-category" className="label-input">{t('timePickup')}</label>
                          <select name="times" className="input-text" value={timePick} onChange={(e) => setTimePick(e.target.value)} onClick={handlePickTime}>
                            <option value="pick-time" disabled hidden>Pick Time</option>

                            {'jika opt - 2jam lebih kecil dari currenttime'}
                            {currentDate.getDate() === datePick.getDate() && currentDate.getMonth() === datePick.getMonth() && currentDate.getFullYear() === datePick.getFullYear() ?
                              optionTime.map((opt, index) => {
                                if (currenttime >= '14:00' && opt >= currenttime) {
                                  return <option value={opt} >{opt}</option>
                                } else if (currenttime >= optionTime2HoursAgo[index]) {
                                  return null
                                } else {
                                  return <option value={opt} >{opt}</option>
                                }
                                // return <option value={opt} disabled={currenttime >= optionTime2HoursAgo[index] ? 'disabled' : ''}>{opt}</option>
                              })
                              :
                              optionTime.map((opt, index) => {
                                return <option value={opt}>{opt}</option>
                              })
                            }
                          </select>
                          <img src={TimeIcon} alt="" />
                          <br />
                        </div>
                      </div>
                      {/* {selectedService === 'sameday-delivery' &&
                        <div className="w-100" style={{ fontSize: '0.75rem', marginTop: '-1rem', opacity: '0.8' }}>
                          Estimasi driver tiba maksimal 2 jam dari jam pengambilan.
                        </div>
                      } */}
                    </>
                  )}
                </div>
              }
            </div>
            {selectedService === 'superkul-truck-ftl' &&
              <div className="select-vehicles">
                <div className="header-vehicles">
                  <p className="text-title">{t('selectVehicles')}</p>
                </div>
                {vehiclesLoader ?
                  <div className="loading-skeleton">
                    <Row
                      style={{
                        paddingRight: '50%'
                      }}
                    >
                      <Col
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <TitleSkeleton
                          style={{
                            width: '4rem',
                            height: '4rem',
                            borderRadius: '0.5rem',
                            marginBottom: '0.5rem'
                          }}
                        />
                        <TitleSkeleton
                          style={{
                            width: '3rem',
                            height: '0.9rem',
                            borderRadius: '0.3rem',
                          }}
                        />
                      </Col>
                      <Col
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <TitleSkeleton
                          style={{
                            width: '4rem',
                            height: '4rem',
                            borderRadius: '0.5rem',
                            marginBottom: '0.5rem'
                          }}
                        />
                        <TitleSkeleton
                          style={{
                            width: '3rem',
                            height: '0.9rem',
                            borderRadius: '0.3rem',
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  :
                  <div className="body-vehicles">
                    <div className="vehicles-checkbox">
                      {
                        vehicles.map((vehicle) => {
                          return (
                            <label className="custom-vehicles" key={vehicle.id}>
                              <input
                                type="radio"
                                name="check"
                                className="check-vehicles"
                                value={vehicle.name}
                                onClick={(e) => handleIsBike(e, vehicle)}
                                checked={vehicleTypeId === vehicle.id ? 'checked' : ''}
                              />
                              <div className="content">
                                {selectedService === "superkul-truck" || selectedService === "superkul-truck-ltl" || selectedService === "superkul-truck-ftl" ?
                                  vehicle.name.toLowerCase() === 'van' ?
                                    <img src={selectedVehicle === vehicle.name ? CDEIconActive : CDEIcon} alt="" className="icon-vehicles" />
                                    :
                                    <img src={selectedVehicle === vehicle.name ? CDDIconActive : CDDIcon} alt="" className="icon-vehicles" />
                                  :
                                  <img src={selectedVehicle === vehicle.name ? BikeIconActive : BikeIcon} alt="" className="icon-vehicles" />
                                }
                                <img src={CheckIcon} alt="" className="check-icon" />
                              </div>
                              <h5 className="vehicle-name">{vehicle.name}</h5>
                            </label>
                          )
                        })
                      }

                    </div>

                  </div>
                }
              </div>
            }
          </div>
          <p className="show-hide-service" onClick={() => setShowHideService(!showHideService)}>
            <img src={ArrowHideIcon} alt="" className={showHideService ? 'hide-icon' : 'show-icon'} />
            {showHideService ? t('showService') : t('hideService')}
          </p>
          <div className="input-location">
            <div className="header-input-location">
              <p className="text-title">{t('listDeliveryLocation')}</p>
              {selectedService === 'sameday-delivery' || selectedService === 'super-express' ?
                // <button className="import-btn" onClick={toggleImportOrder}>
                //   <img src={ImportIcon} className="import-btn-icon" /> {t('import')}
                // </button>
                <Dropdown className="import-choice" isOpen={importTypeOpen} toggle={(e) => setImportTypeOpen(!importTypeOpen)} >
                  <DropdownToggle className="import-choice-toggle" caret >
                    <img src={ImportIcon} className="import-btn-icon" /> {t('import')}
                  </DropdownToggle>
                  <DropdownMenu className="import-choice-menu">
                    <DropdownItem
                      className="import-choice-item"
                      onClick={() => setOpenImportOrder({ visible: true, title: t('importTitle'), type: 1 })}
                    >Import Manual</DropdownItem>
                    <DropdownItem
                      className="import-choice-item"
                      onClick={() => setOpenImportOrder({ visible: true, title: t('importTitle'), type: 2 })}
                    >Import dengan Alamat Tersimpan</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                : null
              }
            </div>

            <div className="input-field">
              {location.map((input, indexLoc) => {
                return (
                  <>
                    <div className={`input-area ${locViewType === 0 && '',
                      locViewType === 1 && 'dataAddr',
                      locViewType === 2 && 'dataFull'
                      }`}>
                      {targetLocationId !== input.locationId || locViewType === 1 ?
                        <Badge className={`set-as-${input.point.toLowerCase()}`}>{input.point} {indexLoc}</Badge>
                        : null
                      }
                      <PlacesAutocomplete

                        value={input.addressLabel}
                        onChange={(e) => handleChangeAddressName(e, input.locationId)}
                        onSelect={(e) => placeChanged(e, input.locationId)}

                        searchOptions={
                          {
                            componentRestrictions: { country: ['id'] },
                            sessionToken: sessionToken,
                            // location: new google.maps.LatLng(-6.593930, 106.796410),
                            // radius: 500,
                            // types: ['address']

                          }
                        }

                      >

                        {({ getInputProps, getSuggestionItemProps, suggestions }) => (
                          <div
                            className={`input-address`}
                            style={
                              {
                                height: targetLocationId === input.locationId ? locViewType === 1 ?
                                  input.name === '' ? '3rem' : '4rem'
                                  :
                                  '2.5rem'
                                  : '4rem'
                              }
                            }
                          >
                            <input
                              onClick={() => { handleSearchLocation(input) }}
                              {...getInputProps({
                                placeholder: indexLoc === 0 ? t('addPickupPlaceholder') : t('addDropPlaceholder'),
                                className: 'location-search-input',
                              })}
                            />
                            {input.addressLabel.length > 2 &&
                              <div className="autocomplete-dropdown-container">

                                {suggestions.map(suggestion => {
                                  // console.log(suggestion)
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer', fontSize: '1rem' };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      className='suggest'
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            }
                          </div>
                        )}
                      </PlacesAutocomplete>

                      {targetLocationId !== input.locationId || locViewType === 1 ?
                        <div className={`input-data-full full-data ${input.addressLabel === '' ? 'hidden' : ''}`} onClick={() => { handleSearchLocation(input) }}>
                          <p className="address-label">{input.addressLabel}</p>
                          <p className="address-format">{input.addressFormat}</p>
                          {input.name !== '' ?
                            <p className="full-data">{input.name} | 62{input.phoneNumber} | {input.notes}</p>
                            : null
                          }
                        </div>
                        : null
                      }

                      {targetLocationId === input.locationId && input.addressLabel.length <= 2 && input.addressLabel.length > 0 && locViewType == 0 ?
                        <div className="recent-loc">
                          <h6>{t('suggestAddress')}</h6>
                          {JSON.parse(localStorage.getItem('recentAddr')) === null ?
                            recentLocation.map(rcnt => {
                              return (
                                <div className="recent-loc-data" onClick={() => handleChooseSavedAddress(rcnt)}>
                                  <p className="address-label">{rcnt.label}</p>
                                  <p className="address-format">{rcnt.address}</p>
                                </div>
                              )
                            })
                            :
                            JSON.parse(localStorage.getItem('recentAddr')).map(rcnt => {
                              return (
                                <div className="recent-loc-data" onClick={() => handleChooseSavedAddress(rcnt)}>
                                  <p className="address-label">{rcnt.label}</p>
                                  <p className="address-format">{rcnt.address}</p>
                                </div>
                              )
                            })
                          }
                        </div>
                        :
                        null
                      }
                      <div className="icon">
                        {targetLocationId === input.locationId ?
                          <img src={SearchIcon} className="search-icon" />
                          : null
                        }

                        {targetLocationId === input.locationId ?
                          <img src={SavedAddressIcon} alt="" className="saved-address-icon" onClick={() => toggleSavedAddress(input.locationId)} />
                          :
                          <img src={RemoveIcon} className="delete-icon" onClick={() => handleRemoveLocation(input)} />
                        }
                      </div>
                    </div>
                  </>
                )
              })}
            </div>

            {selectedService !== 'instant-delivery' && selectedService !== 'superkul-truck-ltl' ?
              <button className="btn add-loc" onClick={handleAddLocation}>
                <img src={AddIcon} className="add-loc-icon" />
                {location.length > 0 ? t('addDrop') : t('addPickup')}
              </button>
              :
              location.length < 2 ?
                <button className="btn add-loc" onClick={handleAddLocation}>
                  <img src={AddIcon} className="add-loc-icon" />
                  {location.length > 0 ? t('addDrop') : t('addPickup')}
                </button>
                : null
            }
          </div>
          {toggleShow &&
            <img src={ToggleIcon} alt="" className={`toggle-icon ${detailOpen ? '' : 'close'}`} onClick={() => setDetailOpen(!detailOpen)} />
          }
          <form className={`detail-create-order-deliv ${detailOpen ? 'show-detail' : ''}`} onSubmit={handleSubmit(savePoint)}>
            {/* <div className="detail-container"> */}
            <div className="detail-header">
              <h1>{point === 'Pick' ? t('Pickup') : t('Drop')}</h1>
              <Dropdown className="tags-list" isOpen={tagsListOpen} toggle={(e) => setTagsListOpen(!tagsListOpen)}>
                <DropdownToggle className="tags-list-toggle" caret>{tag?.name}</DropdownToggle>
                <DropdownMenu className="tags-list-menu">
                  {tagsList.map((tag, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        value={tag?.name}
                        className="tags-list-item"
                        onClick={() => setTag({ name: tag?.name, time: tag?.time, is_primary: tag?.is_primary })}
                      >{tag?.name}</DropdownItem>
                    )
                  })}
                  {/* <DropdownItem value="Kantor" className="tags-list-item" onClick={() => setTag({ name: 'Kantor', value: 'Kantor', is_primary: 0 })}>Kantor</DropdownItem>
                  <DropdownItem className="tags-list-item" >Toko</DropdownItem>
                  <DropdownItem className="tags-list-item" >Kantor</DropdownItem>
                  <DropdownItem className="tags-list-item" >Mall</DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="detail-content">
              <div className="detail-form">
                <div className="detail-data">
                  {point === 'Pick' ?
                    <>
                      <label htmlFor="sender-name">{t('senderName')}</label>
                      <input
                        type="text"
                        id="sender-name"
                        name="sender-name"
                        className={errors.name ? 'error' : ''}
                        placeholder={t('senderNamePlaceholder')}
                        // value={name} 
                        onInput={(e) => setName(e.target.value)}
                        {...register("name", {
                          required: true
                        })}
                      /><br />
                      {errors?.name?.type === "required" && <p>{t('fieldRequired')}</p>}
                    </>
                    :
                    <>
                      <label htmlFor="receiver-name">{t('receiverName')}</label>
                      <input
                        type="text"
                        id="receiver-name"
                        name="receiver-name"
                        className={errors.name ? 'error' : ''}
                        placeholder={t('receiverNamePlaceholder')}
                        // value={name} 
                        onInput={(e) => setName(e.target.value)}
                        {...register("name", {
                          required: true
                        })}
                      />
                      <br />
                      {errors?.name?.type === "required" && <p>{t('fieldRequired')}</p>}
                    </>
                  }

                  <div>
                    <label htmlFor="phone-number">{t('phoneNumber')}</label>
                    <h6 className="phone-62">62</h6>
                    <input
                      type="text"
                      id="phone-number"
                      name="phone-number"
                      className={errors.phoneNumber ? 'error' : ''}
                      placeholder={point === 'Pick' ? t('phoneNumberPlaceholder') : t('phoneNumberReceiverPlaceholder')}
                      // value={phoneNumber} 
                      onInput={(e) => setPhoneNumber(e.target.value)}
                      {...register("phoneNumber", {
                        required: true,
                        maxLength: 13,
                        minLength: 8,
                        pattern: /^\d+$/,
                        validate: value => !value[0].match(0)
                      })}
                    />
                    <br />
                    {errors?.phoneNumber?.type === "required" && <p>{t('fieldRequired')}</p>}
                    {errors?.phoneNumber?.type === "pattern" &&
                      <p>{t('numbersOnly')}</p>
                    }
                    {errors?.phoneNumber?.type === "minLength" && (
                      <p>{t('minPhoneNum')}</p>
                    )}
                    {errors?.phoneNumber?.type === "maxLength" && (
                      <p>{t('maxPhoneNum')}</p>
                    )}
                    {errors?.phoneNumber?.type === "validate" && (
                      <p>{t('firstNumber0')}</p>
                    )}
                  </div>


                  <label htmlFor="notes">{t('notes')} {'(Optional)'}</label>
                  <input
                    type="text"
                    id="notes"
                    name="notes"
                    placeholder={t('notesPlaceholder')}
                    // value={notes} 
                    onInput={(e) => setNotes(e.target.value)}
                    {...register("notes")}
                  />
                  <br />

                  <input type="checkbox" name="bookmark-address" id="bookmark-address" onChange={handleBookmark} ref={isbookmarkref} />
                  <label htmlFor="bookmark-address" className="bookmark-address">{t('saveAddress')}</label> <br />

                  {isSaveBookmark &&
                    <>
                      <label htmlFor="bookmark-label" className="bookmark-label">{t('label')}</label>
                      <input
                        type="text"
                        id="bookmark-label"
                        name="bookmark-label"
                        className={errors.bookmarkLabel ? 'error' : ''}
                        placeholder={t('labelPlaceholder')}
                        // value={bookmarkLabel} 
                        onInput={(e) => setBookmarkLabel(e.target.value)}
                        {...register("bookmarkLabel", {
                          required: true
                        })}
                      />
                      <br />
                      {errors?.bookmarkLabel?.type === "required" && <p>{t('fieldRequired')}</p>}
                    </>
                  }


                </div>
                {point === 'Pick' && selectedService.includes('superkul-truck') &&
                  <div className="pickup-packages">
                    <div className="packages-title">
                      <h1>{t('pickupPackages')}</h1>
                    </div>
                    <div className="add-packages-body">
                      {isCustomeSize || selectedService === 'superkul-truck-ltl' ?
                        <>
                          <label htmlFor="weight-package">{t('weight')}</label>
                          <input
                            type="text"
                            id="weight-package"
                            name="weight-package"
                            className={errors.weight ? 'error' : ''}
                            // placeholder={maximumPackage?.weight === '' ? '' : `${t('max')} ${maximumPackage?.weight} kg`}
                            placeholder={`${t('min')} 50 kg`}
                            {...register("weight", {
                              required: true,
                              pattern: /^[0-9]+$/,
                              validate: (val) => {
                                console.log(val)
                                if (val >= 50) {
                                  return true
                                }
                                // if (maximumPackage?.weight === '' || val <= maximumPackage?.weight) {
                                //   return true
                                // }
                                return `${t('min')} 50 kg`
                              }
                            })}
                          />
                          <br />
                          {errors?.weight?.type === "required" && <p>{t('fieldRequired')}</p>}
                          {errors?.weight?.type === "pattern" &&
                            <p>{t('numbersOnly')}</p>
                          }
                          {errors?.weight?.type === "validate" &&
                            <p>{errors.weight.message}</p>
                          }


                          <label htmlFor="lenght">{t('length')}</label>
                          {errors?.lenght?.type === "required" &&
                            <label htmlFor="length" className="label-error">{t('fieldRequired')}</label>
                          }
                          {errors?.lenght?.type === "pattern" &&
                            <label htmlFor="length" className="label-error">{t('numbersOnly')}</label>
                          }
                          {errors?.lenght?.type === "validate" &&
                            <label htmlFor="length" className="label-error">{errors.lenght.message}</label>
                          }
                          <input
                            type="text"
                            id="lenght"
                            name="lenght"
                            className={errors.lenght ? 'error' : ''}
                            placeholder={maximumPackage?.length === '' ? '' : `${t('max')} ${maximumPackage?.length} cm`}
                            {...register("lenght", {
                              required: true,
                              pattern: /^[0-9]+$/,
                              validate: (val) => {
                                if (maximumPackage?.length === '' || val <= maximumPackage?.length) {
                                  return true
                                }
                                return `${t('max')} ${maximumPackage?.length} cm`
                              }
                            })}
                          />


                          <label htmlFor="width">{t('width')}</label>
                          {errors?.width?.type === "required" &&
                            <label htmlFor="width" className="label-error">{t('fieldRequired')}</label>
                          }
                          {errors?.width?.type === "pattern" &&
                            <label htmlFor="width" className="label-error">{t('numbersOnly')}</label>
                          }
                          {errors?.width?.type === "validate" &&
                            <label htmlFor="width" className="label-error">{errors.width.message}</label>
                          }
                          <input
                            type="text"
                            id="width"
                            name="width"
                            className={errors.width ? 'error' : ''}
                            placeholder={maximumPackage?.width === '' ? '' : `${t('max')} ${maximumPackage?.width} cm`}
                            {...register("width", {
                              required: true,
                              pattern: /^[0-9]+$/,
                              validate: (val) => {
                                if (maximumPackage?.width === '' || val <= maximumPackage?.width) {
                                  return true
                                }
                                return `${t('max')} ${maximumPackage?.width} cm`
                              }
                            })}
                          />

                          <label htmlFor="height">{t('height')}</label>
                          {errors?.height?.type === "required" &&
                            <label htmlFor="height" className="label-error">{t('fieldRequired')}</label>
                          }
                          {errors?.height?.type === "pattern" &&
                            <label htmlFor="height" className="label-error">{t('numbersOnly')}</label>
                          }
                          {errors?.height?.type === "validate" &&
                            <label htmlFor="height" className="label-error">{errors.height.message}</label>
                          }
                          <input
                            type="text"
                            id="height"
                            name="height"
                            className={errors.height ? 'error' : ''}
                            placeholder={maximumPackage?.height === '' ? '' : `${t('max')} ${maximumPackage?.height} cm`}
                            {...register("height", {
                              required: true,
                              pattern: /^[0-9]+$/,
                              validate: (val) => {
                                if (maximumPackage?.height === '' || val <= maximumPackage?.height) {
                                  return true
                                }
                                return `${t('max')} ${maximumPackage?.height} cm`
                              }
                            })}
                          />
                          <br />
                        </>
                        :
                        <>
                          {/* <label for="package-size">{t('packagesSize')}</label>
                          <ButtonGroup className="package-size">
                            {sizeData.map((size, index) => {
                              return (
                                <Button
                                  color="primary"
                                  outline
                                  className="package-choices"
                                  onClick={() => {
                                    setPackageSize(size?.name);
                                    setValue('weight', size?.weight)
                                    setValue('height', size?.height)
                                    setValue('width', size?.width)
                                    setValue('lenght', size?.lenght)
                                  }}
                                  active={packageSize === size.name}
                                >
                                  <h5
                                    style={{
                                      margin: 0,
                                      marginBottom: "0.5rem",
                                      fontSize: '1rem'
                                    }}
                                  >{size.name}</h5>
                                  <h6
                                    style={{
                                      margin: 0,
                                      fontSize: '0.8rem'
                                    }}
                                  >{t('max')} {size?.weight} KG, {size?.volume}L</h6>
                                </Button>
                              )
                            })}

                          </ButtonGroup>
                          <br /> */}
                        </>
                      }

                      <br />

                      {selectedService !== "super-express" && selectedService !== "superkul-truck" &&
                        <>
                          <label for="package-size">{t('temperature')}</label>
                          <ButtonGroup className="package-size">
                            {tempRange.map((temp, index) => {
                              return (
                                <Button
                                  color="primary"
                                  outline
                                  className="package-choices"
                                  onClick={() => setTemperature(temp?.avg)}
                                  active={temperature === temp?.avg}
                                >
                                  <h5
                                    style={{
                                      margin: 0,
                                      marginBottom: "0.5rem",
                                      fontSize: '1rem'
                                    }}
                                  >{temp.name}</h5>
                                  <h6
                                    style={{
                                      margin: 0,
                                      fontSize: '0.8rem'
                                    }}
                                  >{temp?.min}&deg;C to {temp?.max}&deg;C</h6>
                                </Button>
                              )
                            })}

                          </ButtonGroup>
                          <br />
                        </>
                      }
                    </div>
                  </div>
                }

                {point === 'Drop' && !selectedService.includes('superkul-truck') ?
                  <div className="pickup-packages">
                    <div className="packages-title">
                      <h1>{t('pickupPackages')}</h1>
                    </div>
                    <div className="add-packages-body">
                      <label for="package-category">{t('packagesCategory')}</label>
                      <ButtonGroup className="package-category">
                        {packagesList.map((pack, index) => {
                          return (
                            <Button
                              color="primary"
                              outline
                              className="package-choices"
                              onClick={() => {
                                setIsOtherPackages(false);
                                setPackageCategory(pack.name);
                              }}
                              active={packageCategory === pack.name}
                            >
                              {pack.name}
                            </Button>
                          )
                        })}

                      </ButtonGroup>
                      <br />

                      {packageCategory === 'Lainnya' &&
                        <>
                          <label htmlFor="package-description">{t('otherDescription')}</label>
                          <input
                            type="text"
                            id="package-description"
                            name="package-description"
                            className={errors.itemDesc ? 'error' : ''}
                            placeholder={t('otherDescription')}
                            {...register("itemDesc", {
                              required: true,
                            })}
                          />
                          <br />
                          {errors?.itemDesc?.type === "required" && <p>{t('fieldRequired')}</p>}
                        </>
                      }

                      {selectedService !== 'superkul-truck-ltl' &&
                        <h6
                          className="custom-size-btn"
                          onClick={() => {
                            setIsCustomeSize(!isCustomeSize)
                            setPackageSize("")
                          }}
                        >
                          {isCustomeSize ? t('quickFillBtn') : t('customeSizeBtn')}
                        </h6>
                      }


                      {isCustomeSize || selectedService === 'superkul-truck-ltl' ?
                        <>
                          <label htmlFor="weight-package">{t('weight')}</label>
                          <input
                            type="text"
                            id="weight-package"
                            name="weight-package"
                            className={errors.weight ? 'error' : ''}
                            placeholder={maximumPackage?.weight === '' ? '' : `${t('max')} ${maximumPackage?.weight} kg`}
                            {...register("weight", {
                              required: true,
                              pattern: /^[0-9]+$/,
                              validate: (val) => {
                                if (maximumPackage?.weight === '' || val <= maximumPackage?.weight) {
                                  return true
                                }
                                return `${t('max')} ${maximumPackage?.weight} kg`
                              }
                              // validate: value => value <= maximumPackage?.weight || `${t('max')} ${maximumPackage?.weight} kg`
                            })}
                          />
                          <br />
                          {errors?.weight?.type === "required" && <p>{t('fieldRequired')}</p>}
                          {errors?.weight?.type === "pattern" &&
                            <p>{t('numbersOnly')}</p>
                          }
                          {errors?.weight?.type === "validate" &&
                            <p>{errors.weight.message}</p>
                          }


                          <label htmlFor="lenght">{t('length')}</label>
                          {errors?.lenght?.type === "required" &&
                            <label htmlFor="length" className="label-error">{t('fieldRequired')}</label>
                          }
                          {errors?.lenght?.type === "pattern" &&
                            <label htmlFor="length" className="label-error">{t('numbersOnly')}</label>
                          }
                          {/* {errors?.lenght?.type === "validate" &&
                            <p>{errors.lenght.message}</p>
                          } */}
                          {errors?.lenght?.type === "validate" &&
                            <label htmlFor="length" className="label-error">{errors.lenght.message}</label>
                          }
                          <input
                            type="text"
                            id="lenght"
                            name="lenght"
                            className={errors.lenght ? 'error' : ''}
                            placeholder={maximumPackage?.length === '' ? '' : `${t('max')} ${maximumPackage?.length} cm`}
                            {...register("lenght", {
                              required: true,
                              pattern: /^[0-9]+$/,
                              validate: (val) => {
                                if (maximumPackage?.length === '' || val <= maximumPackage?.length) {
                                  return true
                                }
                                return `${t('max')} ${maximumPackage?.length} cm`
                              }
                              // validate: value => value <= maximumPackage?.length || `${t('max')} ${maximumPackage?.length} cm`
                            })}
                          />


                          <label htmlFor="width">{t('width')}</label>
                          {errors?.width?.type === "required" &&
                            <label htmlFor="width" className="label-error">{t('fieldRequired')}</label>
                          }
                          {errors?.width?.type === "pattern" &&
                            <label htmlFor="width" className="label-error">{t('numbersOnly')}</label>
                          }
                          {errors?.width?.type === "validate" &&
                            <label htmlFor="width" className="label-error">{errors.width.message}</label>
                          }
                          <input
                            type="text"
                            id="width"
                            name="width"
                            className={errors.width ? 'error' : ''}
                            placeholder={maximumPackage?.width === '' ? '' : `${t('max')} ${maximumPackage?.width} cm`}
                            {...register("width", {
                              required: true,
                              pattern: /^[0-9]+$/,
                              validate: (val) => {
                                if (maximumPackage?.width === '' || val <= maximumPackage?.width) {
                                  return true
                                }
                                return `${t('max')} ${maximumPackage?.width} cm`
                              }
                              // validate: value => value <= maximumPackage?.width || `${t('max')} ${maximumPackage?.width} cm`
                            })}
                          />

                          <label htmlFor="height">{t('height')}</label>
                          {errors?.height?.type === "required" &&
                            <label htmlFor="height" className="label-error">{t('fieldRequired')}</label>
                          }
                          {errors?.height?.type === "pattern" &&
                            <label htmlFor="height" className="label-error">{t('numbersOnly')}</label>
                          }
                          {errors?.height?.type === "validate" &&
                            <label htmlFor="height" className="label-error">{errors.height.message}</label>
                          }
                          <input
                            type="text"
                            id="height"
                            name="height"
                            className={errors.height ? 'error' : ''}
                            placeholder={maximumPackage?.height === '' ? '' : `${t('max')} ${maximumPackage?.height} cm`}
                            {...register("height", {
                              required: true,
                              pattern: /^[0-9]+$/,
                              validate: (val) => {
                                if (maximumPackage?.height === '' || val <= maximumPackage?.height) {
                                  return true
                                }
                                return `${t('max')} ${maximumPackage?.height} cm`
                              }
                              // validate: value => value <= maximumPackage?.height || `${t('max')} ${maximumPackage?.height} cm`
                            })}
                          />
                          <br />
                        </>
                        :
                        <>
                          <label for="package-size">{t('packagesSize')}</label>
                          <ButtonGroup className="package-size">
                            {sizeData.map((size, index) => {
                              return (
                                <Button
                                  color="primary"
                                  outline
                                  className="package-choices"
                                  onClick={() => {
                                    setPackageSize(size?.name);
                                    setValue('weight', size?.weight)
                                    setValue('height', size?.height)
                                    setValue('width', size?.width)
                                    setValue('lenght', size?.lenght)
                                  }}
                                  active={packageSize === size.name}
                                >
                                  <h5
                                    style={{
                                      margin: 0,
                                      marginBottom: "0.5rem",
                                      fontSize: '1rem'
                                    }}
                                  >{size.name}</h5>
                                  <h6
                                    style={{
                                      margin: 0,
                                      fontSize: '0.8rem'
                                    }}
                                  >{t('max')} {size?.weight} KG, {size?.volume}L</h6>
                                </Button>
                              )
                            })}

                          </ButtonGroup>
                          <br />
                        </>
                      }

                      <br />

                      {selectedService !== "super-express" && selectedService !== "superkul-truck" &&
                        // <>
                        //   <label htmlFor="temperature" id="temperature-label">{t('temperature')}</label>
                        //   <div className="range">
                        //     <Slider
                        //       min={-22}
                        //       max={10}
                        //       marks={{
                        //         '-22': '-22°C',
                        //         '-14': '-14°C',
                        //         '-6': '-6°C',
                        //         2: '2°C',
                        //         10: '10°C'
                        //       }}
                        //       defaultValue={temperature}
                        //       value={temperature}
                        //       trackStyle={{
                        //         backgroundColor: '#6EB9E3',
                        //         height: '0.5rem'
                        //       }}
                        //       railStyle={{
                        //         backgroundColor: '#E1EAF6',
                        //         height: '0.5rem',
                        //       }}
                        //       handleStyle={{
                        //         borderColor: '#1F83BB',
                        //         height: '1.1rem',
                        //         width: '1.1rem',
                        //         // height: 28,
                        //         // width: 28,
                        //         opacity: 1,
                        //         backgroundColor: '#1F83BB',
                        //       }}
                        //       onChange={(e) => setTemperature(e)}
                        //     />
                        //     <h6>{temperature}&deg; C</h6>
                        //   </div>
                        //   <br />
                        // </>
                        <>
                          <label for="package-size">{t('temperature')}</label>
                          <ButtonGroup className="package-size">
                            {tempRange.map((temp, index) => {
                              return (
                                <Button
                                  color="primary"
                                  outline
                                  className="package-choices"
                                  onClick={() => setTemperature(temp?.avg)}
                                  // onClick={() => {
                                  //   setPackageSize(size?.name);
                                  //   setValue('weight', size?.weight)
                                  //   setValue('height', size?.height)
                                  //   setValue('width', size?.width)
                                  //   setValue('lenght', size?.lenght)
                                  // }}
                                  active={temperature === temp?.avg}
                                >
                                  <h5
                                    style={{
                                      margin: 0,
                                      marginBottom: "0.5rem",
                                      fontSize: '1rem'
                                    }}
                                  >{temp.name}</h5>
                                  <h6
                                    style={{
                                      margin: 0,
                                      fontSize: '0.8rem'
                                    }}
                                  >{temp?.min}&deg;C to {temp?.max}&deg;C</h6>
                                </Button>
                              )
                            })}

                          </ButtonGroup>
                          <br />
                        </>
                      }
                      <br />
                      <br />
                      {!selectedService.includes('superkul-truck-ftl') &&
                        <>
                          {crossdockId && soNumberTemporary.length === 0 ?
                            null :
                            <>
                              <label
                                htmlFor="soNumber"
                              >Sales Order Number</label>
                              <br />
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '0.5rem'
                                }}
                              >
                                {soNumberTemporary.map((soNum) => {
                                  // console.log(so)
                                  // setValue('halahh', [...watch('soNumber'), 'sssdddd'])
                                  return (
                                    <Button
                                      color="primary"
                                      outline
                                      className="soNumber"
                                      disabled={crossdockId ? true : false}
                                    >
                                      <div>{soNum}</div>
                                      {!crossdockId &&
                                        <MdClose
                                          style={{ width: '1.2rem', height: '1.2rem' }}
                                          onClick={() => {
                                            const filterSO = soNumberTemporary.filter((val) => val !== soNum)
                                            setSoNumberTemporary(filterSO)
                                          }}
                                        />
                                      }
                                    </Button>
                                  )
                                })}
                                {!soNumberInput.isAdd && !crossdockId &&
                                  <Button
                                    color="primary"
                                    outline
                                    className="soNumber"
                                    onClick={() => {
                                      setSoNumberInput({ ...soNumberInput, isAdd: true })
                                    }}
                                  >
                                    <MdOutlineAdd style={{ width: '1.2rem', height: '1.2rem' }} />
                                    <div>Tambah</div>
                                  </Button>
                                }
                              </div>
                              <br />
                              {soNumberInput.isAdd &&
                                <div
                                  style={{
                                    display: 'flex'
                                  }}
                                >
                                  <input
                                    type="text"
                                    style={{
                                      borderTopRightRadius: '0',
                                      borderBottomRightRadius: '0'
                                    }}
                                    value={soNumberInput.value}
                                    onChange={(e) => setSoNumberInput({ ...soNumberInput, value: e.target.value })}
                                  // id="soNumber"
                                  // name="soNumber"
                                  // className={errors.soNumber ? 'error' : ''}
                                  // placeholder={`Contoh: AD324532;AD434345`}
                                  // {...register("soNumber", {
                                  // })}
                                  // disabled={crossdockId ? true : false}
                                  />
                                  <Button
                                    style={{
                                      height: '2.5rem',
                                      borderTopLeftRadius: '0',
                                      borderBottomLeftRadius: '0',
                                    }}
                                    color="primary"
                                    outline
                                    className="soNumber"
                                    onClick={() => {
                                      setSoNumberTemporary([...soNumberTemporary, soNumberInput.value])
                                      setSoNumberInput({ isAdd: false, value: '' })
                                    }}
                                  >
                                    <MdOutlineAdd style={{ width: '1.2rem', height: '1.2rem' }} />
                                  </Button>
                                </div>
                              }
                              <br />
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                  : null
                }

                {isBike === "Bike" && point === 'Drop' && additionalList.length > 0 && (
                  <div className="additional-servicess">
                    {additional && (
                      <div>
                        {/* <div className="max-capacity">Maximum capacity 30kg</div> */}
                        <p className="additional-title">
                          {t('selectAdditionalService')}
                        </p>
                        {additionalList.map((list, index) => (
                          <div className="mt-2" key={list.id}>
                            <input
                              type="checkbox"
                              id={list.id}
                              name={list.id}
                              className='additional-input'
                              checked={selectedAdditional[index]}
                              onChange={(e) => handleAdditional(e, list.id, index)}
                            />
                            <label
                              className="label-additional-list"
                              htmlFor={list.id}
                            >
                              <div className="additional-list">
                                <p>{list.name}</p>
                                <p>Rp. {numberFormat(list.price || list.amount)}</p>
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                    {/* <p
                      className="hiden-addservice"
                      onClick={() => setAdditional(!additional)}
                    >
                      {additional
                        ? "hide additional service"
                        : "show additional service"}
                    </p> */}
                  </div>
                )}
              </div>
            </div>
            <div className={`detail-footer ${point === 'Drop' && selectedService !== 'superkul-truck-ftl' ? 'fix-bottom' : ''}`}>
              <div className="back btn" onClick={cancelPoint}>{t('back').toUpperCase()}</div>
              <button type="submit" className="save btn btn-primary" >{t('save').toUpperCase()}</button>
            </div>
            {/* </div> */}
          </form>
        </div>
        {orderAllow && location.length > 1 ?
          <div className="footer-order">
            <button className={`order-1 btn btn-primary`} onClick={handleCreateOrder}>{t('order')}</button>
          </div>
          :
          <div className="footer-order disable">
            <button disabled className={`order-1 btn btn-primary disable`} onClick={handleCreateOrder}>{t('order')}</button>
          </div>
        }
      </div>


      <div className="maps-side">

        <Header />
        <div className="maps-view">
          <div className="maps">
            <GoogleMap
              center={center}
              zoom={11}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                scrollwheel: targetLocationId === '' ? true : false
              }}
              onZoomChanged={(val) => {
                storeHitGoogle(auth?.id, "web_customer_dynamic_map")
              }}
              onLoad={(map) => setMap(map)}
            >
              {location.map((data, index) => {
                if (data === undefined) {
                  return null
                } else {
                  if (data.point === '') {

                    return (
                      <>
                        <Marker
                          position={data.latlng}
                          draggable={targetLocationId === data.locationId ? true : false}
                          onDragEnd={(e) => dragEnd(e, data.locationId)}
                          icon={GrayIcon}
                        >
                        </Marker>
                      </>
                    )
                  } else {
                    return (
                      <>
                        <Marker
                          position={data.latlng}
                          draggable={targetLocationId === data.locationId ? true : false}
                          onDragEnd={(e) => dragEnd(e, data.locationId)}
                          icon={data.point === 'Pick' ? PickupIcon : DropIcon}
                        >
                        </Marker>
                        <OverlayView position={data.latlng} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                          <div
                            style={{
                              backgroundColor: "white",
                              padding: '0.1rem 0.3rem',
                              position: 'relative',
                              top: '-3.2rem',
                              left: '-0.55rem',
                              borderRadius: '50%',
                              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.3)',
                              fontWeight: '700',
                              fontSize: '0.75rem'
                            }}
                          >
                            {Object.keys(data.latlng).length === 0 ? '' : index}
                          </div>
                        </OverlayView>
                      </>
                    )
                  }
                }
              })}
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>
  );
};
