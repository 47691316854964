function truncateString(str, num) {
  if (str?.length <= num) {
    return str
  }
  if(str === null) {
    return str
  }
  return str.slice(0, num) + '...'
}

export default truncateString;