import { useEffect, useState } from "react";
import { t } from "i18next";

import CustomTable from "../../../../Component/Table/CustomTable/CustomTable";
import truncateString from "../../../../utils/truncateString";

import './styles/index.scss'
import { Badge, Col, Container, Row } from "reactstrap";
import ImportButton from "../../../../Component/Button/ImportButton/ImportButton";
import DangerButton from "../../../../Component/Button/DangerButton/DangerButton";
import WarningModal from "../../../../Component/Modal/WarningModal/WarningModal";
import SuccessModal from "../../../../Component/Modal/SuccessModal/SuccessModal";
import { sendGetRequest } from "../../../../services/request-adapter";
import { errorMessage } from "../../../../utils/errorMessage";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

const DetailDataCrossdocking = () => {
  const productColumns = [
    {
      title: "Kode SKU",
      dataIndex: "code",
    },
    {
      title: "Nama Produk",
      dataIndex: "name",
    },
    {
      title: "Berat (KG)",
      dataIndex: "weight",
      align: 'center'
    },
    {
      title: "Panjang (CM)",
      dataIndex: "length",
      align: 'center'
    },
    {
      title: "Lebar (CM)",
      dataIndex: "width",
      align: 'center'
    },
    {
      title: "Tinggi (CM)",
      dataIndex: "height",
      align: 'center'
    },
    {
      title: "Suhu",
      dataIndex: "temperature",
      align: 'center'
    },
    {
      title: "Jumlah",
      dataIndex: "qty",
      align: 'center'
    },
    {
      title: "Jumlah Real",
      dataIndex: "qtyReceived",
      align: 'center'
    }
  ]

  const deliveryColumns = [
    {
      title: "Nomor Trip",
      dataIndex: "tripNumber",
    },
    {
      title: "Nama Driver",
      dataIndex: "driverName",
    },
    {
      title: "Plat Nomor",
      dataIndex: "vehicleNumber",
      align: 'center'
    },
    {
      title: "Nomor HP",
      dataIndex: "phoneNumber",
      align: 'center'
    },
    {
      title: "Jumlah Drop",
      dataIndex: "totalDrop",
      align: 'center'
    },
    {
      title: "Status Pengiriman",
      dataIndex: "tripStatus",
      align: 'center',
      render: (data) => (
        <Badge
          // className={
          //   data === 'SCHEDULED' ?
          //     'bg-gray' :
          //     data === 'TRANSIT' ?
          //       'bg-success' :
          //       data === 'PARTIAL TRANSIT' ?
          //         'bg-success' :
          //         data === 'CANCEL' ?
          //           'bg-danger' :
          //           'bg-danger'
          // }
          className={getStatusStyle(data)}
        // style={getStatusStyle(data)}
        >{data}</Badge>
      )
    },
  ]

  const dummyData = [
    {
      id: '12314bjhbadausy8wyasdad',
      sku_code: 'CD/62837472',
      name: 'Hub Jakarta Barat (HQ)',
      date: "17:46, 25 Sept 2023",
      weight: 8,
      length: 1,
      width: 1,
      height: 1,
      temp: '-10'
    }
  ]

  const dummyData2 = [
    {
      id: '12314bjhbadausy8wyasdad',
      sku_code: 'CD/62837472',
      name: 'Hub Jakarta Barat (HQ)',
      date: "17:46, 25 Sept 2023",
      plat: 8,
      hp: 1,
      length: 8,
    }
  ]

  const { id } = useParams()
  const [detailData, setDetailData] = useState('')
  const [productdata, setProductdata] = useState([])
  const [deliveryData, setDeliveryData] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    pageSize: 15,
    name: ""
  })
  const [editModal, setEditModal] = useState({ visible: false })
  const [warningModal, setWarningModal] = useState({ visible: false })
  const [successModal, setSuccessModal] = useState({ visible: false })

  const getAllDataCrossdocking = async () => {
    setLoading(true)
    try {
      const { data } = await sendGetRequest(`/so-number-customers/${id}`)
      setDetailData(data.data)
      setProductdata(data.data.sku_details)
      setDeliveryData(data.data.detail_trip)
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoading(false)
    }
  }

  const handleCancelOrder = async () => {
    setWarningModal({ visible: false })
    setSuccessModal({
      visible: true,
      message: 'Success',
      duration: 3000
    })
  }

  const onChangePage = (page) => {
    setParams({ ...params, page: page })
  };

  const getStatusStyle = (value) => {
    switch (value) {
      case "Menunggu Pembayaran":
        return "bg-danger";
      case "Order Sedang Diproses":
        return "bg-gray";
      case "Mencari Driver":
        return "bg-gray";
      case "Driver Ditemukan":
        return "bg-blue";
      case "Proses Penjemputan":
        return "bg-warning";
      case "Proses Pengiriman":
        return "bg-warning";
      case "Terkirim":
        return "bg-success";
      case "Dibatalkan":
        return "bg-danger";
      default:
        return {};
    }
  };

  useEffect(() => {
    getAllDataCrossdocking()
  }, [params])

  const CancelBtn = () => {
    return (
      <DangerButton
        text={t('cancelOrder')}
        onClick={() => {
          setWarningModal({ visible: true, message: 'Yakin?' })
        }}
      // loading={loadingScreen}
      // disabled={selectedFile === null ? true : false}
      />
    )
  }

  return (
    <div className="data-crossdocking-detail">
      <div className="default-container">
        <div className="title">
          <h1>Detail Data Cross Docking</h1>
          <hr />
          <p>Menampilkan detail dari data cross docking.</p>
        </div>
      </div>
      <br />
      <div className="default-container data-crossdocking-form">
        <div className="title">
          <h1>Formulir Order Planning</h1>
          <hr />
        </div>
        {detailData !== '' &&
          <Container>
            <Row xs="2">
              <Col>
                <table>
                  <tr>
                    <td>Nomor Order Cross Docking</td>
                    <td>{detailData?.crossdockNumber}</td>
                  </tr>
                  <tr>
                    <td>Nomor Sales Order</td>
                    <td>{detailData?.soNumber}</td>
                  </tr>
                  <tr>
                    <td>Waktu Pembuatan</td>
                    <td>{format(new Date(detailData?.created_at), 'HH:mm, dd MMM yyyy')}</td>
                  </tr>
                  <tr>
                    <td>Lokasi Hub</td>
                    <td>{detailData?.poolName}</td>
                  </tr>
                  <tr>
                    <td>Waktu Transit</td>
                    <td>{detailData?.crossdockTime}, {format(new Date(detailData?.crossdockDate), 'dd MMM yyyy')}</td>
                  </tr>
                </table>
              </Col>
              <Col>
                <table>
                  <tr>
                    <td>Status Cross Docking</td>
                    <td>
                      <Badge
                        className={
                          detailData?.status === 'SCHEDULED' ?
                            'bg-gray' :
                            detailData?.status === 'DONE' ?
                              'bg-success' :
                              detailData?.status === 'PARTIAL' ?
                                'bg-success' :
                                detailData?.status === 'CANCEL' ?
                                  'bg-danger' :
                                  'bg-danger'
                        }
                      >{detailData?.status}</Badge>
                    </td>
                  </tr>
                  <tr>
                    <td>SKU</td>
                    <td>{detailData?.totalSKU}</td>
                  </tr>
                  <tr>
                    <td>Drop</td>
                    <td>{detailData?.totalTrip}</td>
                  </tr>
                </table>
              </Col>
            </Row>
          </Container>
        }
      </div>
      <br />
      <div className="default-container">
        <CustomTable
          title="Daftar Produk"
          columns={productColumns}
          data={productdata}
          // totalData={productdata?.meta?.pagination?.total || 1}
          // pageSize={productdata?.meta?.pagination?.per_page || 15}
          // selectedData={selectedData}
          // setSelectedData={setSelectedData}
          // setPageSize={(size) => setParams({ ...params, pageSize: size })}
          // onChangePage={onChangePage}
          // withPagination="top"
          loading={loading}
          setLoading={setLoading}
        />
      </div>
      <br />
      <div className="default-container">
        <CustomTable
          title="Daftar Pengiriman"
          columns={deliveryColumns}
          data={deliveryData}
          // totalData={deliveryData?.meta?.pagination?.total || 1}
          // pageSize={deliveryData?.meta?.pagination?.per_page || 15}
          // selectedData={selectedData}
          // setSelectedData={setSelectedData}
          // setPageSize={(size) => setParams({ ...params, pageSize: size })}
          // onChangePage={onChangePage}
          // withPagination="top"
          loading={loading}
          setLoading={setLoading}
        />
      </div>

      <WarningModal
        isOpen={warningModal}
        toggle={() => setWarningModal({ visible: false })}
        onFinish={handleCancelOrder}
      // loading={loadingModal}
      />

      <SuccessModal
        isOpen={successModal}
        toggle={() => setSuccessModal({ visible: false })}
      />
    </div>
  )
};

export default DetailDataCrossdocking;