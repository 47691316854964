import AlertFailedIcon from "../../assets/icon/alert/ic-alert-failed.png"
import AlertWarningIcon from "../../assets/icon/alert/ic-alert-warning.png"
import toast, { Toaster } from "react-hot-toast";
import './Alert.scss'

const CustomAlert = (props) => {
  return (
    <div className='alert-popup failed'>
      <div className="alert-popup-header">
        <img src={props?.icon} alt="" />
        <p className='alert-popup-title'><b>{props?.message}</b></p>
        <p className="alert-popup-time" onClick={() => toast.dismiss(props.id)}>&#10005;</p>
      </div>
    </div>
  );
};

export default CustomAlert