import axios from "axios";
import { format } from "date-fns";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import * as xlsx from "xlsx";
import './WarningModal.scss'
import { errorPopup, networkErrorPopup } from "../../Alert/PopUp/ErrorPopUp";
import { useTranslation } from "react-i18next";
import WarningIcon from '../../../assets/icon/popup/ic-popup-warning.png'
import { LoadingScreen } from "../../loadingScreen/loadingScreen";
import PrimaryButton from "../../Button/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../Button/SecondaryButton/SecondaryButton";

const WarningModal = ({ isOpen, toggle, onFinish, loading }) => {
  const { t } = useTranslation()
  const [loadingScreen, setLoadingScreen] = useState(false)

  return (
    <Modal isOpen={isOpen.visible} toggle={toggle} className="warning-modal">
      {loadingScreen && <LoadingScreen />}
      <ModalBody className="warning-modal-body">
        <img src={WarningIcon} alt="" />
        <p className="modal-text">{isOpen.message}</p>
      </ModalBody>
      <ModalFooter className="warning-modal-footer">
        {/* <Button className="apply-file" onClick={toggle}>
          {t('cancel')}
        </Button> */}
        <SecondaryButton
          text={t('cancel')}
          onClick={toggle}
        />
        <PrimaryButton
          text="Ya, Hapus"
          onClick={onFinish}
          loading={loading}
        />
      </ModalFooter>
    </Modal>
  )
}

export default WarningModal;