import { t } from "i18next";
import React, { useState } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Badge } from "reactstrap";
import OrderIdIcon from '../../../../../../assets/icon/ic-order-id.png'
import PriceIcon from '../../../../../../assets/icon/ic-currency.png'
import ArrowExpand from '../../../../../../assets/icon/ic-arrow-left.png'
import { numberFormat } from "../../../../../../Component/numberFormat/numberFormat";

const DetailSO = ({ data }) => {
  const [listTrip, setListTrip] = useState('0')

  const tripAccordion = (id) => {
    if (listTrip === id) {
      setListTrip()
    } else {
      setListTrip(id)
    }
  }

  return (
    <div className="list-trip">
      {data?.soNumber.map((trip, index) => {
        return (
          <Accordion open={listTrip} toggle={tripAccordion} className="cek">
            <AccordionItem className="list-item-paid">
              <AccordionHeader className="header" targetId={index}>
                <div className="list-header">
                  <img src={OrderIdIcon} alt="" className="trip-icon" />
                  <div className="trip-number">
                    <h3>{t('soNumber')}</h3>
                    <h4>#{trip.soNumber}</h4>
                  </div>
                  <div className="price">
                    <Badge
                      className={
                        trip?.status === 'SCHEDULED' ?
                          'bg-gray' :
                          trip?.status === 'DONE' ?
                            'bg-success' :
                            trip?.status === 'PARTIAL' ?
                              'bg-success' :
                              trip?.status === 'CANCEL' ?
                                'bg-danger' :
                                'bg-danger'
                      }
                    >{trip?.status}</Badge>
                  </div>
                </div>
              </AccordionHeader>
            </AccordionItem>
          </Accordion>
        )
      })
      }
    </div>
  )
}

export default DetailSO;