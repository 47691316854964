import { Button, Spinner } from "reactstrap";
import './SecondaryButton.scss'

const SecondaryButton = (props) => {
  return (
    <Button
      className='secondary-button'
      {...props}
    >
      {props?.loading &&
        <Spinner size="sm"></Spinner>
      }
      <span>
        {' '}{props.text}
      </span>
    </Button>
  )
}

export default SecondaryButton;