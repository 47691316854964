import React, { useContext, useEffect, useRef, useState } from "react";

import { useJsApiLoader, GoogleMap, Marker, Autocomplete, DirectionsRenderer } from '@react-google-maps/api'
import { storeHitGoogle } from "../../services/storeHitGoogle";
import { AuthContext } from "../authContext/AuthContext";
import { sendPostRequestMobile } from "../../services/request-adapter";
import { errorMessage } from "../../utils/errorMessage";
import { t } from "i18next";
import { errorPopup } from "../Alert/PopUp/ErrorPopUp";

const BookmarkMaps = ({ dataFromChild, latlng }) => {
  const context = useContext(AuthContext)
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [libraries] = useState(['places']);
  // const [directionResponse, setDirectionResponse] = useState(null)
  // const [distance, setDistance] = useState("")
  // const [duration, setDuration] = useState("")

  const [autocomplete, setAutocomplete] = useState(null)
  const [markerLoc, setMarkerLoc] = useState()
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [formattedAddress, setFormattedAddress] = useState('')
  const [addressName, setAddressName] = useState('')
  const [center, setCenter] = useState(
    {
      lat: -6.1722143,
      lng: 106.7657932
    }
  )



  useEffect(() => {
    dataFromChild({
      formattedAddress,
      latitude,
      longitude
    })
  }, [formattedAddress])

  useEffect(() => {
    if (latlng !== undefined) {
      setCenter(latlng)
    }
  }, [latlng])

  // /** @type React.MutableRefObject<HTMLInputElement> */
  // const originRef = useRef()

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDfdR5Et5GQSGbLqWjFbXB7JNjLIA3Q9Vw',
    libraries
  })

  if (!isLoaded) {
    return (
      <h1>Please wait...</h1>
    )
  }

  const storeGeocode = async (data) => {
    try {
      await sendPostRequestMobile('/api/map-response', data)
    } catch (error) {
      errorMessage(error, t('error'), t('somethingError'), t('close'))
    }
  }

  // Geocode
  const loadAutocomplete = (e) => {
    console.log(e)
    setAutocomplete(e)
  }

  const placeChanged = (e) => {
    console.log(autocomplete)
    // const geocoder = new google.maps.Geocoder()

    // geocoder.geocode({ 'address': e }).then((response) => {
    //   storeGeocode(response)
    //   const address = response.results[0].formatted_address
    //   const lat = response.results[0].geometry.location.lat()
    //   const lng = response.results[0].geometry.location.lng()
    //   setLatitude(lat)
    //   setLongitude(lng)
    //   setFormattedAddress(address)
    //   setCenter({
    //     lat,
    //     lng
    //   })
    // }).catch((error) => {
    //   console.log(error.message)
    //   errorPopup(t('error'), t('locationNotFound'), t('close'))
    // }).finally(() => {
    //   storeHitGoogle(context?.id, "web_customer_geocode")
    // })

    if (autocomplete !== null) {
      const address = autocomplete.getPlace().formatted_address
      const lat = autocomplete.getPlace().geometry.location.lat()
      const lng = autocomplete.getPlace().geometry.location.lng()
      setLatitude(lat)
      setLongitude(lng)
      setFormattedAddress(address)
      setCenter({
        lat,
        lng
      })
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  // Reverse Geocode
  const dragEnd = (e) => {
    /* eslint-disable */
    const geocoder = new google.maps.Geocoder()
    const lat = e.latLng.lat()
    const lng = e.latLng.lng()
    setLatitude(lat)
    setLongitude(lng)
    const latlng = {
      lat,
      lng
    }
    geocoder.geocode({ location: latlng })
      .then((response) => {
        setFormattedAddress(response.results[0].formatted_address)
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  }

  return (
    // <div style={{positon: 'relative'}}>
    <div style={{ positon: 'relative' }}>
      {/* <div className="maps" style={{width: '100%', height: '100%'}}> */}
      <div className="maps">
        <GoogleMap
          center={center}
          zoom={14}
          mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '1.5rem' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false
          }}
          onZoomChanged={(val) => {
            storeHitGoogle(context?.id, "web_customer_dynamic_map")
          }}
          onLoad={(map) => setMap(map)}
        >
          <Marker
            position={center}
            draggable={true}
            onDragEnd={dragEnd}
          />
        </GoogleMap>
      </div>
      <form
        className="input-address"
      >
        <Autocomplete
          // className="input-address"
          onLoad={loadAutocomplete}
          onPlaceChanged={(e) => placeChanged(e)}
          restrictions={{ country: ['id'] }}
        >
          <input
            type="text"
            id="address"
            onChange={(val) => {
              storeHitGoogle(context?.id, "web_customer_autocomplete")
            }}
          />
        </Autocomplete>
      </form>
    </div>
  )
}

export default BookmarkMaps