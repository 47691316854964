import { Button, Spinner } from "reactstrap";

import './PrimaryButton.scss'

const PrimaryButton = (props) => {
  return (
    <Button
      className='primary-button'
      {...props}
      disabled={props?.loading || props?.disabled ? true : false}
    >
      {props?.loading &&
        <Spinner size="sm"></Spinner>
      }

      {props?.icon &&
        <img src={props?.icon} alt="" />
      }
      <p>
        {props.text}
      </p>
    </Button>
  )
}

export default PrimaryButton;