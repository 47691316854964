import { useForm } from "react-hook-form";

import './Input.scss'
import { t } from "i18next";

const InputText = ({ label, name, placeholder, errors, register }) => {
  return (
    <div className="default-input input-number">
      <label htmlFor={name}>{label}</label>
      <input
        type="text"
        id={name}
        name={name}
        className={errors[name] ? 'error' : ''}
        placeholder={placeholder}
        {...register(name, {
          required: true,
        })}
      />
      <br />
      {errors[name]?.type === "required" && <p>{t('fieldRequired')}</p>}
    </div>
  )
}

export default InputText;