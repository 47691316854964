import React, { useRef, useState } from "react";
import "../../styles/newOrderBtn/newOrder.scss";
import Delivery from "../../assets/icon/header/ic-delivery.png";
import Dedicated from "../../assets/icon/header/ic-dedicated-order.png";
import ManageIcon from "../../assets/icon/header/ic-dedicated-delivery.png";
import CrossdockIcon from "../../assets/icon/header/ic-crossdocking.png";
import ConfirmIcon from "../../assets/img/img-state-confirmation.png";
import { DedicatedService } from "../Modal/DedicatedService/DedicatedService";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import SuccessModal from "../Modal/SuccessModal/SuccessModal";
import { DedicatedServiceClone } from "../Modal/DedicatedService/DedicatedService-clone";

export const MakeOrderButton = ({ userType, isDedicated, isCrossdock, openDedicatedModal, setOpenDedicatedModal }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState({ visible: false });
  const [modal, setModal] = useState({ visible: false })
  const menuRef = useRef();
  const btnRef = useRef();

  const handleOpenDedicated = () => {
    userType === 3 ?
      setOpenModal({ visible: true })
      :
      Swal.fire({
        title: t('notCorporateAccount'),
        imageUrl: ConfirmIcon,
        showConfirmButton: true,
        confirmButtonColor: '#1F83BB',
        confirmButtonText: t('close'),
        customClass: {
          popup: 'popup-swal',
          title: 'title-swal',
          htmlContainer: 'text-swal',
          confirmButton: 'confirm-swal'
        }
      })
  }

  window.addEventListener("click", (e) => {
    if (window.innerWidth > 767) {
      if (e.target !== menuRef.current && e.target !== btnRef.current) {
        setOpen(false);
      }
    }
  });
  return (
    <div>
      <button
        className="btn-new-order"
        ref={btnRef}
        onClick={() => setOpen(!open)}
      >
        + {t('makeNewOrder')}
      </button>
      {open && (
        <div className="menu-container-new-order" ref={menuRef}>
          <ul>
            <Link className="menu-item" to={'/delivery'}>
              <img src={Delivery} alt="menu-icon" />{t('deliveryService')}
            </Link>
            <Link className="menu-item" onClick={handleOpenDedicated}>
              <img src={Dedicated} alt="menu-icon" />{t('dedicatedService')}
            </Link>
            {userType === 3 && isDedicated === "dedicated" &&
              <Link className="menu-item" to={'/manage-dedicated'}>
                <img src={ManageIcon} alt="menu-icon" />Dedicated Delivery
              </Link>
            }
            {isCrossdock === 1 &&
              <Link className="menu-item" to={'/order-planning'}>
                <img src={CrossdockIcon} alt="menu-icon" />Cross Docking
              </Link>
            }
          </ul>
        </div>
      )}
      <DedicatedServiceClone open={openModal} onClose={() => setOpenModal({ visible: false })} />
      {/* <SuccessModal
        isOpen={modal}
        toggle={() => setModal({ visible: false })}
      /> */}
    </div>
  );
};
