import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import './RedirectWhatsappModal.scss'
import WarningIcon from '../../../assets/icon/popup/ic-popup-warning-blue.png'
import WhatsappIcon from '../../../assets/icon/ic-whatsapp-white.png'
import PrimaryButton from "../../Button/PrimaryButton/PrimaryButton";

const RedirectWhatsappModal = ({ isOpen, toggle }) => {

  return (
    <Modal isOpen={isOpen.visible} toggle={toggle} className="redirectwa-modal">
      <ModalBody className="redirectwa-modal-body">
        <img src={WarningIcon} alt="" />
        <h1 className="modal-title">{isOpen.title}</h1>
        <p className="modal-text">{isOpen.message}</p>
      </ModalBody>
      <ModalFooter className="redirectwa-modal-footer">
        <PrimaryButton
          text={'Whatsapp Superkul'}
          onClick={() => {
            window.open(`https://wa.me/6285157827276?text=Halo%20Superkul.%20Saya%20ingin%20bertanya%20mengenai%20layanan%20Superkul%20Truck%2C%20apakah%20bisa%20dibantu%3F`, '_blank');
          }}
          icon={WhatsappIcon}
        />
      </ModalFooter>
    </Modal>
  )
}

export default RedirectWhatsappModal;