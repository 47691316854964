import React, { useEffect, useState } from "react";
import {
  Routes,
  Navigate,
  Route,
  BrowserRouter,
  Outlet,
} from "react-router-dom";
import { AuthContext } from "../Component/authContext/AuthContext";
import { Header } from "../Component/header/Header";
import { DeliveryService } from "../Pages/DeliveryService";
import { ManageDedicated } from "../Pages/ManageDedicated";
import { Register } from "../Pages/Register";
import { RequestCoorporate } from "../Pages/RequestCoorporate";
import { Transaction } from "../Pages/Transaction";
import { DraftDetail } from "../Pages/DraftDetail";
import { Footer } from "../Component/footer/Footer";
import { Profile } from "../Pages/Profile";
import { EditProfile } from "../Component/Profile/EditProfile";
import { EditPassword } from "../Component/Profile/EditPassword";
import { SavedAddress } from "../Component/Profile/SavedAddress";
import { Contact } from "../Component/Profile/Contact";
import { TicketComplain } from "../Component/Profile/TicketComplain";
import { TransactionDetail } from "../Pages/TransactionDetail";
import { TransactionViewTrip } from "../Pages/TransactionViewDetail";
import { useContext } from "react";
import { EmailVerification } from "../Pages/EmailVerification";
import { fetchToken } from "../Component/firebaseNotification/firebase";
import { DeliveryTripPlanning } from "../Pages/DeliveryTripPlanning";
import { DeliveryOrderConfirm } from "../Pages/DeliveryOrderConfirm";
import { ManageOrderConfirm } from "../Pages/ManageDedicated/ManageOrderConfirm";
import { ManageTripPlanning } from "../Pages/ManageDedicated/ManageTripPlanning";
import { NotFoundPage } from "../Pages/ErrorPage/ErrorPage";
import { ChangeLanguage } from "../Component/Profile/ChangeLanguage";
import { UpdatePassword } from "../Pages/UpdatePassword";
// import { ContactUs } from "../Pages/ContactUs";
import { TransactionDetailDedicated } from "../Pages/TransactionDetailDedicated";
import { SavedPackages } from "../Component/Profile/SavedPackages";
import { LoginPage } from "../Pages/LoginPage";
import { ApiKey } from "../Component/Profile/ApiKey";
import { LoginPageDataProfile } from "../Pages/LoginPageDataProfile";
import { ComingSoon } from "../Pages/ComingSoon";
import DetailInvoiceIndex from "../Pages/Invoice/DetailInvoice/index";
import AllInvoiceIndex from "../Pages/Invoice/AllInvoice/index";
import UnderMaintenance from "../Pages/Maintenance/UnderMaintenance";
import SideBar from "../Component/SideBar/SideBar";
import OrderPlanning from "../Pages/Crossdock/OrderPlanning";
import SKUData from "../Pages/Crossdock/SKUData";
import DataCrossdocking from "../Pages/Crossdock/DataCrossdock/AllData";
import DetailDataCrossdocking from "../Pages/Crossdock/DataCrossdock/Detail";
import DetailInvoiceIndexCrossdock from "../Pages/Invoice/DetailInvoice/indexCrossdock";

export const Routers = () => {
  const auth = useContext(AuthContext);
  const [viewHeader, setViewHeader] = useState(false)
  const [viewSidebar, setViewSidebar] = useState(false)

  const ProtectedRoute = () => {
    if (auth.status === false) {
      return <Navigate to={"/login"} replace />;
    }
    return <Outlet />;
  };

  const [isTokenFound, setTokenFound] = useState("");
  if (auth.status === false) {
    fetchToken(setTokenFound);
  }

  useEffect(() => {
    auth.storeTokenFcm(isTokenFound)
  }, [isTokenFound])

  // useEffect(() => {
  //   console.log('change header')
  //   if (window.location.pathname.includes('delivery')) {
  //     setViewHeader(false)
  //   } else {
  //     setViewHeader(true)
  //   }
  // }, [window])

  const LoggingEnv = () => {
    const env = {
      DEV_URL: process.env.REACT_APP_DEV_URL,
      URL_CUST: process.env.REACT_APP_URL_CUST,
      WEB_URL: process.env.REACT_APP_WEB_URL,
      SUBWEB_URL: process.env.REACT_APP_SUBWEB_URL,
      IMG_URL: process.env.REACT_APP_IMG_URL,
    }
    console.table(env)
    return (
      <div></div>
    )
  }

  return (
    <BrowserRouter>
      <Header notifToken={isTokenFound} />
      <div
        style={{
          display: 'flex',
          width: '100vw',
        }}
      >
        <SideBar
          viewSidebar={viewSidebar}
          setViewSidebar={setViewSidebar}
        />
        <div
          style={{
            width: viewSidebar ? '85%' : '100%',
          }}
        >
          <Routes>
            <Route path="/" element={<Navigate to="delivery" />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="login/profile-data" element={<LoginPageDataProfile />} />
            <Route path="register" element={<Register />} />
            <Route path="maintenance" element={<UnderMaintenance />} />
            <Route path="4nv1t3st" element={<LoggingEnv />} />
            <Route path="update-password" element={<UpdatePassword />} />
            <Route path="email/verify" element={<EmailVerification />} />
            <Route path="coming-soon" element={<ComingSoon />} />
            <Route element={<ProtectedRoute />}>
              <Route path="delivery" element={<DeliveryService idx={1} />} />
              <Route
                path="delivery/trip-planning/:orderid"
                element={<DeliveryTripPlanning />}
              />
              <Route
                path="delivery/order-confirm/:orderid"
                element={<DeliveryOrderConfirm />}
              />
              <Route path="manage-dedicated" element={<ManageDedicated />} />
              <Route
                path="manage-dedicated/trip-planning/:orderid"
                element={<ManageTripPlanning />}
              />
              <Route
                path="manage-dedicated/order-confirm/:orderid"
                element={<ManageOrderConfirm />}
              />
              <Route path="draft-detail/:orderid" element={<DraftDetail />} />
              <Route path="order-planning" element={<OrderPlanning />} />
              <Route path="data-crossdocking" element={<DataCrossdocking />} />
              <Route path="data-crossdocking/detail/:id" element={<DetailDataCrossdocking />} />
              <Route path="data-sku" element={<SKUData />} />
              <Route path="transaction/:id" element={<Transaction />} />
              <Route
                path="transaction/detail-transaction/:id"
                element={<TransactionDetail />}
              />
              <Route
                path="transaction/detail-transaction-dedicated/:id"
                element={<TransactionDetailDedicated />}
              />
              <Route
                path="transaction/detail-transaction/:orderid/detail-trip/:tripnumber"
                element={<TransactionViewTrip />}
              />
              <Route path="invoice" element={<AllInvoiceIndex />} />
              <Route
                path="invoice/detail-invoice/:id"
                element={<DetailInvoiceIndex />}
              />
              <Route
                path="invoice/detail-invoice/:id/crossdock"
                element={<DetailInvoiceIndexCrossdock />}
              />
              <Route
                path="request-coorporate/:id"
                element={<RequestCoorporate />}
              />
              <Route path="profile/" element={<Profile />}>
                <Route path="/profile/edit-profile" element={<EditProfile />} />
                <Route path="/profile/edit-password" element={<EditPassword />} />
                <Route
                  path="/profile/saved-address"
                  element={<SavedAddress />}
                />
                <Route
                  path="/profile/saved-packages"
                  element={<SavedPackages />}
                />
                <Route path="/profile/contact" element={<Contact />} />
                <Route path="/profile/api-key" element={<ApiKey />} />
                <Route
                  path="/profile/ticket-complain"
                  element={<TicketComplain />}
                />
                <Route
                  path="/profile/change-language"
                  element={<ChangeLanguage />}
                />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
      {/* <Footer /> */}
    </BrowserRouter>
  );
};
