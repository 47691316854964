import React from "react";
import DetailInfo from "./LeftDetailCrossdock/DetailInfo";
import DetailSO from "./LeftDetailCrossdock/DetailSO";

const LeftDetailCrossdock = ({ data }) => {
  return (
    <div className="left-detail">
      <DetailInfo data={data} />
      <DetailSO data={data} />
      {/* <DetailTrips data={data} /> */}
    </div>
  )
}

export default LeftDetailCrossdock;