import { useForm } from "react-hook-form";

import './Input.scss'
import { t } from "i18next";

const InputNumber = ({ label, name, placeholder, errors, register }) => {
  return (
    <div className="default-input input-number">
      <label htmlFor="name">{label}</label>
      <input
        type="text"
        id={name}
        name={name}
        className={errors[name] ? 'error' : ''}
        placeholder={placeholder}
        // onInput={(e) => { setname(e.target.value) }}
        {...register(name, {
          required: true,
          pattern: /^-?[0-9]\d*(\.\d+)?$/,
          // validate: value => value <= 30 || placeholder
        })}
      />
      <br />
      {errors[name]?.type === "required" && <p>{t('fieldRequired')}</p>}
      {errors[name]?.type === "pattern" &&
        <p>{t('numbersOnly')}</p>
      }
      {/* {errors[name]?.type === "validate" &&
        <p>{errors[name]?.message}</p>
      } */}
    </div>
  )
}

export default InputNumber;