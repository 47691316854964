import { BsArrowRightShort } from "react-icons/bs";
import { ToggleSwitch } from "../../ToggleSwitch/ToggleSwitch";
import { numberFormat } from "../../numberFormat/numberFormat";
import '../../../styles/deliveryService/tripPlanning/tripCardCorporate.scss'
import { t } from "i18next";

const TripListCardCorporate = ({ allDataOrder, trip, index, handleToggle, showDetail, optimizeAll }) => {
  const checkTemp = (temp) => {
    if (temp <= -11) {
      return 'Deep Freeze'
    } else if (temp <= -1) {
      return 'Frozen'
    } else {
      return 'Chiller'
    }
  }
  return (
    <div className="list-data-corporate">
      <div className="list-header" >
        {allDataOrder.service_name.name.toLowerCase() === 'superkul truck' ?
          <div className="title">
            <h3>Trip {index + 1}</h3>
            <h5>{checkTemp(trip.pick.itemTmp)}</h5>
          </div>
          :
          <div className="title">
            <h3 >Trip {index + 1} </h3>
            <h5>{checkTemp(trip.drop[0].itemTmp)}</h5>
          </div>
        }
        {/* {allDataOrder.totalDestination == 1 || allDataOrder.service_name.name.toLowerCase() === 'instant delivery' ?
          null :
          <h6>
            Route Optimize
            <ToggleSwitch
              bg='#E6E6E6'
              opt={handleToggle}
              currentOpt={trip.optimize}
              targetTrip={trip._id}
            />
          </h6>
        } */}
      </div>
      <div className="list-content">
        <div className="content-data">
          <table>
            <tr>
              <td>{t('weight2')} & {t('volume')}</td>
              <td>{trip?.drop?.reduce((val, element) => { return val + element.weight }, 0)} Kg & {trip?.drop?.reduce((val, element) => { return Math.ceil(val + (element.width * element.height * element.lenght) / 1000) }, 0)} L</td>
            </tr>
            <tr>
              <td>{t('totalDrop')}</td>
              <td>{trip?.drop?.length}</td>
            </tr>
            {/* <tr>
              <td>{t('distanceEstimation')}</td>
              <td>{optimizeAll ? trip?.distanceOpt : trip?.distance} Km</td>
            </tr> */}
          </table>
        </div>
        <BsArrowRightShort
          className="btn-detail"
          onClick={() => showDetail(trip, index, trip._id)}
        />
      </div>
    </div>
  )
}

export default TripListCardCorporate;