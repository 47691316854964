import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../../../styles/addAddressModal/addAddressModal.scss";
import { LoadingScreen } from "../../loadingScreen/loadingScreen";
import BookmarkMaps from "../../maps/BookmarkMaps";
import { errorPopup, networkErrorPopup } from "../../Alert/PopUp/ErrorPopUp";
import { sendGetRequest } from "../../../services/request-adapter";
import { errorMessage } from "../../../utils/errorMessage";
import Select from "react-select";
import CustomAlert from "../../Alert/Alert";
import AlertFailedIcon from "../../../assets/icon/alert/ic-alert-failed.png"
import toast from "react-hot-toast";
import { AuthContext } from "../../authContext/AuthContext";

export const EditAddress = ({ openEdit, closeEdit, item, reload }) => {
  const { id } = useParams()
  const context = useContext(AuthContext)
  const [address, setAddress] = useState('')
  const [addressName, setAddressName] = useState('')
  const [tag, setTag] = useState('')
  const [notes, setNotes] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [tagsData, setTagsData] = useState([])
  const [isChange, setIsChange] = useState(false)
  const { t } = useTranslation()
  const [loadingScreen, setLoadingScreen] = useState(false)
  const [tagsOpen, setTagsOpen] = useState(false);
  const toggleTags = () => setTagsOpen((prevState) => !prevState);

  const url = process.env.REACT_APP_URL_CUST
  const access_token = localStorage.getItem("spkl:c015d311v")
  const headers = {
    'Authorization': `${access_token}`
  }

  useEffect(() => {
    getTagsData()
  }, [])

  const getTagsData = async () => {
    // setLoading(true)
    try {
      const { data } = await sendGetRequest('tag-addresses?limit=0')
      const modifyData = data.data.map((dat) => {
        return {
          value: dat?.name, label: dat?.name, is_primary: dat?.is_primary
        }
      })
      setTagsData(modifyData)
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      // setLoading(false)
    }
  }

  useEffect(() => {
    setAddress(item?.address)
    setAddressName(item?.label)
    setNotes(item?.notes === null ? '' : item?.notes)
    setName(item?.name === null ? '' : item?.name)
    setPhone(item?.phone === null ? '' : item?.phone?.substring(2))
    setLatitude(item?.lan)
    setLongitude(item?.lon)
    setTag({
      value: item?.tags?.name, label: item?.tags?.name, is_primary: item?.tags?.is_primary
    })
    // getTagsData()
  }, [openEdit, item])

  const handleEditAddress = async (e) => {
    // Delete Address
    e.preventDefault()
    // Add Address
    const addData = {
      user_id: context?.id,
      address,
      label: addressName,
      lan: latitude,
      lon: longitude,
      name,
      phone: phone[0] == 0 ? '62' + phone.substring(1) : '62' + phone,
      notes,
      tag: tag?.value
    }

    setLoadingScreen(true)
    try {
      const response = await axios.patch(`${url}/api/bookmark/${item.id}`, addData, { headers })
      if (response.data.status === 'failed') {
        toast((t) => (
          <CustomAlert
            id={t.id}
            message={response.data.message}
            icon={AlertFailedIcon} />
        ), {
          style: {
            background: '#FFE7E0'
          }
        });
        return
      }

      setAddress('')
      setLatitude('')
      setLongitude('')
      setAddressName('')
      setNotes('')
      closeEdit()
      reload()
    } catch (error) {
      console.log(error.message)
      if (error.message === 'Network Error') {
        networkErrorPopup(t('networkErrorTitle'), t('networkErrorText'), t('reload'), t('cancel'))
      } else {
        errorPopup(t('error'), t('somethingError'), t('close'))
      }
    } finally {
      setLoadingScreen(false)
    }
  }

  const childData = (e) => {
    setAddress(e.formattedAddress)
    setLatitude(e.latitude)
    setLongitude(e.longitude)
  }

  const handleClose = () => {
    // setAddress('')
    // setLatitude('')
    // setLongitude('')
    // setAddressName('')
    // setNotes('')
    closeEdit()
  }

  const triggerChange = () => {
    setAddress(item.address)
    setAddressName(item.label)
    setLatitude(item.lan)
    setLongitude(item.lon)
    setIsChange(true)
  }

  if (!openEdit) return null;
  return (
    <div className="overlay"  >
      {loadingScreen && <LoadingScreen />}
      <div className="main-content" >
        <p className="title">{t('updateAddress')}</p>

        <form className="form-add-address" onSubmit={handleEditAddress}>


          <div className="input-wrapper-location label">
            <label className="label-input-location" htmlFor="address-name">
              {t('label')}
            </label>
            <input
              className="input-text-location"
              type="text"
              name="address-name"
              value={addressName}
              onChange={(e) => setAddressName(e.target.value)}
              required
            />
          </div>

          <div className="input-wrapper-location label">
            <label className="label-input-location" htmlFor="monthly">
              Tags
            </label>
            <div className="mt m-auto w-100">
              <Select
                className="select-data"
                classNamePrefix="select-tag"
                defaultValue={tagsData.filter((tag) => tag.is_primary === 1)}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={tagsData}
                onChange={val => { setTag(val) }}
                value={tag}
                required
              />
              {/* {indo.error && <p>{t('fieldRequired')}</p>} */}
            </div>
          </div>

          <div className="map-view">
            <BookmarkMaps dataFromChild={childData} latlng={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }} />
          </div>

          <div className="input-wrapper-address">
            <h1 className="label-address">{t('address')}</h1>
            <div className="text-address">{address}</div>
          </div>

          <div className="input-wrapper-location">
            <label className="label-input-location" htmlFor="address-data-name">
              Nama
            </label>
            <input
              className="input-text-location"
              type="text"
              name="address-data-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="input-wrapper-location">
            <label className="label-input-location" htmlFor="address-data-phone">
              {t('phoneNumber')}
            </label>
            <h6 className="phone-62">62</h6>
            <input
              id="phoneNumber"
              className="input-text-location"
              type="number"
              name="address-data-phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>

          <div className="input-wrapper-location notes">
            <label className="label-input-location" htmlFor="address-notes">
              {t('notes')}
            </label>
            <input
              className="input-text-location"
              type="text"
              name="address-data-notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          <div className="btn-container">
            <button className="close-btn" onClick={handleClose}>{t('close')}</button>
            <button className="btn-save" >{t('save')}</button>
          </div>
        </form>
      </div>
    </div>
  );
};
