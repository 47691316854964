import { useEffect, useState } from "react";
import { t } from "i18next";
import toast, { Toaster, useToaster } from 'react-hot-toast';

import ImportButton from "../../../Component/Button/ImportButton/ImportButton";
import CustomTable from "../../../Component/Table/CustomTable/CustomTable";
import ImportModal from "../../../Component/Modal/ImportModal/ImportModal";
import EditIcon from "../../../assets/icon/crossdock/ic-edit.png"
import DeleteIcon from "../../../assets/icon/crossdock/ic-delete.png"
import AlertFailedIcon from "../../../assets/icon/alert/ic-alert-failed.png"
import AlertWarningIcon from "../../../assets/icon/alert/ic-alert-warning.png"


import { sendDeleteRequest, sendGetRequest, sendPatchRequest, sendPostDownloadRequest, sendPostMultipartRequest } from "../../../services/request-adapter";
import truncateString from "../../../utils/truncateString";
import { errorMessage } from "../../../utils/errorMessage";

import './styles/index.scss'
import WarningModal from "../../../Component/Modal/WarningModal/WarningModal";
import CustomAlert from "../../../Component/Alert/Alert";
import EditModalSKU from "./modal/EditModalSKU";
import SuccessModal from "../../../Component/Modal/SuccessModal/SuccessModal";
import { errorPopup } from "../../../Component/Alert/PopUp/ErrorPopUp";

const SKUData = () => {
  const columns = [
    {
      // align: "end",
      title: "Kode SKU",
      dataIndex: "code",
      // render: (id, record) => (
      //   <CustomDropdown
      //     setEdit={() =>
      //       setActionDriver({
      //         visible: true,
      //         action: "updated",
      //         title: "Edit Driver",
      //         id: id,
      //       })
      //     }
      //     setStatus={() =>
      //       setStatusModal({
      //         id: id,
      //         name: `${record?.first_name} ${record?.last_name}`,
      //         visible: true,
      //         status: record.status,
      //       })
      //     }
      //     setAvail={() =>
      //       setDriverStatusModal({
      //         id: id,
      //         name: `${record?.first_name} ${record?.last_name}`,
      //         visible: true,
      //         status: record.driver_status,
      //       })
      //     }
      //     setDetachDeviceID={() =>
      //       setDeviceIdModal({
      //         id: id,
      //         name: `${record?.first_name} ${record?.last_name}`,
      //         visible: true,
      //         status: record.device_id,
      //       })
      //     }
      //     status={record.status}
      //     driverStatus={record.driver_status}
      //     deviceId={record.device_id}
      //     permissionUpdate={checkPermission("update-manage-driver")}
      //   />
      // ),
    },
    {
      title: "Nama SKU",
      dataIndex: "name",
      width: '13rem',
      render: (data) => (
        truncateString(data, 50)
      )
    },
    {
      title: "Deskripsi",
      dataIndex: "material_description",
      width: '15rem',
      render: (data) => (
        truncateString(data, 50)
      )
    },
    {
      title: "Berat (KG)",
      dataIndex: "weight",
      align: 'center'
    },
    {
      title: "Panjang (CM)",
      dataIndex: "length",
      align: 'center'
    },
    {
      title: "Lebar (CM)",
      dataIndex: "width",
      align: 'center'
    },
    {
      title: "Tinggi (CM)",
      dataIndex: "height",
      align: 'center'
    },
    {
      title: "Suhu",
      dataIndex: "temperature",
      align: 'center'
    },
    {
      title: "Tindakan",
      dataIndex: "nomor",
      align: 'center',
      render: (_, allData) => (
        <div>
          <img
            src={EditIcon}
            alt="edit"
            style={{
              cursor: 'pointer'
            }}
            onClick={() => setEditModal({
              visible: true,
              data: allData,
            })}
          // onClick={() => {
          //   toast((t) => (
          //     <CustomAlert
          //       id={t.id}
          //       message='Pengisian kolom Panjang yang tidak sesuai, silahkan periksa kembaliasdasd.'
          //       icon={AlertFailedIcon} />
          //   ), {
          //     style: {
          //       background: '#FFE7E0',
          //       zIndex: 99999,
          //     },
          //     containerStyle: {
          //       zIndex: 99999,
          //     }
          //   });
          // }}
          />
          <img
            src={DeleteIcon}
            alt="delete"
            style={{
              cursor: 'pointer'
            }}
            onClick={() => setWarningModal({
              visible: true,
              data: allData,
              message: "Apakah Anda yakin ingin menghapus data SKU?",
              confirmText: ', Hapus'
            })}
          />
        </div>
      )
    },
  ]

  const dummyData = [
    {
      id: '12314bjhbadausy8wyasdad',
      sku_code: 'CD/62837472',
      name: 'Susu Murni varian 1 Liter',
      desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatem itaque error veritatis laborum ipsam dolor quod, minus aspernatur inventore eum, ab veniam quo quos ad expedita reprehenderit eveniet libero iusto?",
      weight: 860,
      lenght: 8,
      width: 8,
      height: 20,
      temp: -10,
    },
    {
      id: '2428ryfshd78yt3hfh98f734f',
      sku_code: 'CD/628374888',
      name: 'Yogurt',
      desc: 'Yogurt yang baik dikonsumsi sebelum',
      weight: 860,
      lenght: 8,
      width: 8,
      height: 20,
      temp: '-10',
    },
    {
      id: 'sadas98dfdjsfu98u',
      sku_code: 'CD/628374888',
      name: 'Yogurt',
      desc: 'Yogurt yang baik dikonsumsi sebelum',
      weight: 860,
      lenght: 8,
      width: 8,
      height: 20,
      temp: '-10',
    },
    {
      id: 'asdeqs9qw898jasssssd',
      sku_code: 'CD/628374888',
      name: 'Yogurt',
      desc: 'Yogurt yang baik dikonsumsi sebelum',
      weight: 860,
      lenght: 8,
      width: 8,
      height: 20,
      temp: '-10',
    }
  ]

  const [SKUData, setSKUData] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    pageSize: 15,
    name: ""
  })
  const [toggleImportModal, setToggleImportModal] = useState(false)
  const [editModal, setEditModal] = useState({ visible: false })
  const [warningModal, setWarningModal] = useState({ visible: false })
  const [successModal, setSuccessModal] = useState({ visible: false })

  const getAllSKUData = async () => {
    setLoading(true)
    try {
      const { data } = await sendGetRequest(`/external-products?page=${params.page}&limit=${params.pageSize}&search=${params.name}`)
      setSKUData(data)
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoading(false)
    }
  }

  const handleEditSKU = async (val) => {
    setLoadingModal(true)
    try {
      const { data } = await sendPatchRequest(`/external-products/${editModal.data.id}`, val)
      getAllSKUData()
      setSuccessModal({
        visible: true,
        message: 'Data SKU berhasil diubah',
        duration: 3000
      })
      setEditModal({ visible: false })
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoadingModal(false)
    }
  }

  const handleDeleteSKU = async () => {
    setLoadingModal(true)
    try {
      await sendDeleteRequest(`/external-products/${warningModal.data.id}`)
      getAllSKUData()
      setWarningModal({ visible: false })
      setSuccessModal({
        visible: true,
        message: 'Data SKU berhasil dihapus',
        duration: 3000
      })
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoadingModal(false)
    }
  }

  const onChangePage = (page) => {
    setParams({ ...params, page: page })
  };

  const handleDataImport = async (file) => {
    if (file === null) {
      errorPopup(t('error'), 'Pilih file terlebih dahulu!', t('close'))
      return
    }
    let payload = { file }
    setLoadingModal(true)
    try {
      await sendPostMultipartRequest(`/external-products/import`, payload)


      getAllSKUData()
      setToggleImportModal(false)
      setSuccessModal({
        visible: true,
        message: 'Import Data SKU berhasil',
        duration: 3000
      })
    } catch (error) {
      console.log(error)
      if (error.response.data.message === 'The file must be a file of type: xlsx.') {
        toast((t) => (
          <CustomAlert
            id={t.id}
            message={error.response.data.message}
            icon={AlertFailedIcon} />
        ), {
          style: {
            background: '#FFE7E0'
          }
        });
      } else {
        toast((t) => (
          <CustomAlert
            id={t.id}
            message={error.response.data.message}
            icon={AlertWarningIcon} />
        ), {
          style: {
            background: '#FFF8D6'
          }
        });
      }
    } finally {
      setLoadingModal(false)
    }
    // getAllSKUData()
  }

  useEffect(() => {
    getAllSKUData()
  }, [params])

  const ImportBtn = () => {
    return (
      <ImportButton
        text="Import SKU"
        onClick={() => setToggleImportModal(true)}
      />
    )
  }

  return (
    <div className="data-sku">
      <div className="default-container">
        <div className="title">
          <h1>Data SKU</h1>
          <hr />
          <p>Menampilkan semua data SKU yang telah Anda tambahkan pada sistem Superkul. Data SKU akan menjadi acuan untuk konversi dari SKU ke BKU pada fitur Order Planning.</p>
        </div>
      </div>
      <br />
      <div className="default-container">
        <CustomTable
          title="Daftar SKU"
          columns={columns}
          data={SKUData?.data}
          totalData={SKUData?.meta?.pagination?.total || 1}
          page={SKUData?.meta?.pagination?.current_page || 1}
          setPage={(size) => setParams({ ...params, page: size })}
          pageSize={SKUData?.meta?.pagination?.per_page || 15}
          setPageSize={(size) => setParams({ ...params, pageSize: size })}
          onChangePage={onChangePage}
          searchFilter
          FilterButton={[ImportBtn]}
          withPagination="top"
          loading={loading}
          setLoading={setLoading}
          setSearch={(val) => setParams({ ...params, name: val })}
        />
      </div>

      <ImportModal
        title="Import Data SKU"
        isOpen={toggleImportModal}
        toggle={() => setToggleImportModal(!toggleImportModal)}
        onFinish={(file) => handleDataImport(file)}
        template="/templates/Template+Import+Product.xlsx"
        loading={loadingModal}
      />

      <EditModalSKU
        title="Edit Data SKU"
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        onFinish={handleEditSKU}
        loading={loadingModal}
      />

      <WarningModal
        isOpen={warningModal}
        toggle={() => setWarningModal({ visible: false })}
        onFinish={handleDeleteSKU}
        loading={loadingModal}
      />

      <SuccessModal
        isOpen={successModal}
        toggle={() => setSuccessModal({ visible: false })}
      />
    </div>
  )
};

export default SKUData;