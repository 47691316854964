import React, { useContext, useEffect, useState } from "react";
import "../../styles/Header/headerStyles.scss";
import Logo from "../../assets/img/logo.png";
import { Button } from "reactstrap";
import { isMobile } from 'react-device-detect';
import ManageIcon from "../../assets/icon/ic-manage-dedicated.png";

import { MakeOrderButton } from "../Button/MakeOrderButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Invoice } from "../Modal/Invoice";
import { Draft } from "../Modal/Draft";
import { Notif } from "../Modal/Notif";
import { UserModal } from "../Profile/Modal/UserModal";
import axios from "axios";
import { AuthContext } from "../authContext/AuthContext";
import { useTranslation } from "react-i18next";
import { networkErrorPopup } from "../Alert/PopUp/ErrorPopUp";
import { TransactionButton } from "../Button/TransactionButton";
import { GrClose } from "react-icons/gr";
import { sendGetRequest } from "../../services/request-adapter";
import { RedirectApp } from "../Modal/RedirectApp";

export const Header = () => {
  const { t } = useTranslation();
  const auth = localStorage.getItem("spkl:c015d311v");
  const locationUrl = useLocation();
  const [profileData, setProfileData] = useState("");
  const [isDedicated, setIsDedicated] = useState("not dedicated");
  const [placeholder, setPlaceholder] = useState(true);
  const [mobileNav, setMobileNav] = useState(false)
  const auth_context = useContext(AuthContext);

  const [openRedirectApp, setOpenRedirectApp] = useState(false);
  const toggleRedirectApp = () => {
    setOpenRedirectApp(!openRedirectApp)
  }

  const url = process.env.REACT_APP_URL_CUST;
  const url_auth = process.env.REACT_APP_DEV_URL;
  const access_token = localStorage.getItem("spkl:c015d311v");
  const headers = {
    Authorization: `Bearer ${access_token}`,
  };

  useEffect(() => {
    if (locationUrl.pathname.includes("home") || locationUrl.pathname.includes("/profile") || locationUrl.pathname.includes("/request-coorporate") || locationUrl.pathname.includes("/invoice")) {
      if (access_token !== null) {
        getProfileData();
      }
    }
    setMobileNav(false)


  }, [locationUrl]);

  useEffect(() => {
    if (access_token !== null) {
      getProfileData();
    }
  }, []);

  const storeContext = (value) => {
    auth_context.storePass(value.is_password_empty)
    auth_context.storeId(value.id);
    auth_context.storeProfileId(value?.profile?.id);
    auth_context.storeUserType(value.type);
    auth_context.storeApiKey(value.api_token)
  }

  const getProfileData = async () => {
    try {
      const response = await sendGetRequest('/profile')
      // const response = await axios.get(`${url_auth}/profile`, { headers });
      const data = response.data.data[0];
      setProfileData(data);
      storeContext(data)
      // auth_context.storePass(data.is_password_empty)
      // auth_context.storeId(data.id);
      // auth_context.storeProfileId(data?.profile?.id);
      // auth_context.storeUserType(data.type);
      // auth_context.storeApiKey(data.api_token)

      // Check dedicated
      try {
        const response = await axios.get(
          `${url}/api/check-dedicated/${data.id}`,
          {
            headers: {
              Authorization: `${access_token}`,
            },
          }
        );
        auth_context.storeDedicatedEndDate(response?.data?.data?.rentEnd)
        setIsDedicated(response.data.message);
        setPlaceholder(false);
      } catch (error) {
        console.log(error.message);
      }
    } catch (error) {
      console.log(error.message);
      if (error.message === "Network Error") {
        networkErrorPopup(
          t("networkErrorTitle"),
          t("networkErrorText"),
          t("reload"),
          t("cancel")
        );
      }
    }
  };



  if (locationUrl.pathname === "/coming-soon") {
    return null
  }

  return (
    locationUrl.pathname === "/email/verify" || locationUrl.pathname === "/maintenance" ?
      <div className="navbar fixed-top">
        <div className="logo-container ">
          <img className="logo" src={Logo} alt="logo"></img>
        </div>
      </div>
      :
      <div className={`navbar fixed-top`}>
        <div className="logo-container">
          <a href="/">
            <img className="logo" src={Logo} alt="logo"></img>
          </a>
        </div>

        {/* Web Menu */}
        <div className="item-container d-none d-md-flex">
          {auth ? (
            placeholder ? (
              <p class="placeholder-glow col-8" style={{ marginRight: "1rem" }}>
                <span
                  class="placeholder col-12 rounded-2"
                  style={{ height: "2rem" }}
                ></span>
              </p>
            ) : (
              <div className="after-login">
                <div className="item-one">
                  <MakeOrderButton
                    userType={profileData.type}
                    style={{ display: 'hidden' }}
                    isCrossdock={profileData?.profile?.is_crossdock}
                    isDedicated={isDedicated}
                  />
                  <TransactionButton />
                </div>
                <div className="item-two">
                  <Invoice data={profileData} />
                  <Draft data={profileData} />
                  <Notif />
                </div>
                {/* {profileData?.profile?.is_crossdock === 1 &&
                  <div className="manage-dedicated">
                    <Link to="/order-planning" className="manage-link">
                      Cross Docking
                    </Link>
                  </div>
                }
                {profileData.type === 3 && isDedicated === "dedicated" ? (
                  <div className="manage-dedicated">
                    <img src={ManageIcon} alt="" />
                    <Link to="/manage-dedicated" className="manage-link">
                      {t("manageDedicated")}
                    </Link>
                  </div>
                ) : null} */}
                <UserModal data={profileData} dedicated={false} />
              </div>
            )
          ) : (
            <div className="before-login">
              {locationUrl.pathname === "/login" || locationUrl.pathname === "/login/profile-data" ? null :
                <Button className="btn-login" href="/login">
                  {t("login")}
                </Button>
              }
              {locationUrl.pathname === "/register" ||
                locationUrl.pathname === "/privacy-policy" ||
                locationUrl.pathname === "/update-password" || locationUrl.pathname === "/terms-conditions" ? null : (
                <Button className="btn-register" href="/register">
                  {t("register")}
                </Button>
              )}
            </div>
          )}
        </div>

        {/* Mobile Menu */}
        <div className="d-block d-md-none pe-4 hamburger" onClick={() => setMobileNav(true)}>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <div
          className={`d-block d-md-none black-screen ${mobileNav ? 'active' : ''}`}
          onClick={() => setMobileNav(false)}
        ></div>

        <div className={`d-block d-md-none hamburger-container ${mobileNav ? 'active' : ''}`} >
          <div className="header-mobile">
            <img className="logo" src={Logo} alt="logo"></img>
            <GrClose className="close-mobile" onClick={() => setMobileNav(false)} />
          </div>
          <div className="before-login">
            {locationUrl.pathname === "/login" || locationUrl.pathname === "/login/profile-data" ? null :
              isMobile || window.innerWidth <= 768 ?
                <Button className="btn-login" onClick={toggleRedirectApp}>
                  {t('login')}
                </Button>
                :
                <Button className="btn-login" href="/login">
                  {t('login')}
                </Button>
            }
            {locationUrl.pathname === "/register" ||
              locationUrl.pathname === "/privacy-policy" ||
              locationUrl.pathname === "/update-password" || locationUrl.pathname === "/terms-conditions" ? null :
              isMobile || window.innerWidth <= 768 ?
                <Button className="btn-register" onClick={toggleRedirectApp}>
                  {t("register")}
                </Button>
                :
                <Button className="btn-register" href="/register">
                  {t("register")}
                </Button>
            }
          </div>
        </div>
        <RedirectApp isOpen={openRedirectApp} toggle={toggleRedirectApp} />
      </div>
  )
};
