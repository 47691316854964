import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { useContext, useEffect } from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Badge, UncontrolledPopover } from "reactstrap"
import { AuthContext } from "../authContext/AuthContext"

export function SortableItem(props) {
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState('')
  const context = useContext(AuthContext)


  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: props.tripsData })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  useEffect(() => {

    setSelectedIndex(props.popoverIndex)
  }, [props.popoverIndex])

  return (
    <>
      <div className="trip-point mb-3" ref={setNodeRef} style={style} {...attributes} {...listeners} draggable={false} >
        {/* <h1 className="loc-name">{props.tripsData.name}</h1>
        <h3 className="loc-address">{props.tripsData.addr}</h3> */}
        {/* {props.service.toLowerCase() !== 'superkul truck' ? */}
        {/* {props?.service.includes('superkul truck') ? */}
        {props?.tripsData?.job === 'pickup' ?
          <>
            <div className="trip-title mb-2">
              <h1 className="trip-name">Pickup Point</h1>
            </div>
            <h1 className="loc-name">{props?.tripsData?.item?.pickLabel}</h1>
            <h3 className="loc-address">{props?.tripsData?.item?.pickAddress}</h3>
            <h3 className="loc-data">{props?.tripsData?.item?.senderName} | {props?.tripsData?.item?.pickPhoneNumber} | {props?.tripsData?.item?.pickNotes}</h3>
          </>
          :
          <>
            <div className="trip-title mb-2">
              <h1 className="trip-name">Drop Point</h1>
              {context?.userType !== 3 &&
                <h3>Move ==</h3>
              }
            </div>
            <h1 className="loc-name">{props?.tripsData?.item?.dropOff?.dropLabel || props?.tripsData?.item?.dropLabel}</h1>
            <h3 className="loc-address">{props?.tripsData?.item?.dropOff?.dropAddress || props?.tripsData?.item?.dropAddress}</h3>
            <h3 className="loc-data">{props?.tripsData?.item?.dropOff?.receiverName || props?.tripsData?.item?.receiverName} | {props?.tripsData?.item?.dropOff?.dropPhoneNumber || props?.tripsData?.item?.dropPhoneNumber} | {props?.tripsData?.item?.dropOff?.dropNotes || props?.tripsData?.item?.dropNotes}</h3>
          </>
        }

        {/* } */}
        {/* {props.service.toLowerCase() !== 'superkul truck ftl' ?
          props.tripsData.item.dropOff === undefined ?
            <>
              <div className="trip-title mb-2">
                <h1 className="trip-name">Pickup Point</h1>
              </div>
              <h1 className="loc-name">{props.tripsData.item.pickLabel}</h1>
              <h3 className="loc-address">{props.tripsData.item.pickAddress}</h3>
              <h3 className="loc-data">{props.tripsData.item.senderName} | {props.tripsData.item.pickPhoneNumber} | {props.tripsData.item.pickNotes}</h3>
            </>
            :
            <>
              <div className="trip-title mb-2">
                <h1 className="trip-name">Drop Point</h1>
                {context?.userType !== 3 &&
                  <h3>Move ==</h3>
                }
              </div>
              <h1 className="loc-name">{props.tripsData.item.dropOff.dropLabel}</h1>
              <h3 className="loc-address">{props.tripsData.item.dropOff.dropAddress}</h3>
              <h3 className="loc-data">{props.tripsData.item.dropOff.receiverName} | {props.tripsData.item.dropOff.dropPhoneNumber} | {props.tripsData.item.dropOff.dropNotes}</h3>
            </>
          :
          props.tripsData.item.dropNotes === undefined ?
            <>
              <div className="trip-title mb-2">
                <h1 className="trip-name">Pickup Point</h1>
              </div>
              <h1 className="loc-name">{props.tripsData.item.pickLabel}</h1>
              <h3 className="loc-address">{props.tripsData.item.pickAddress}</h3>
            </>
            :
            <>
              <div className="trip-title mb-2">
                <h1 className="trip-name">Drop Point</h1>
                {context?.userType !== 3 &&
                  <h3>Move ==</h3>
                }
              </div>
              <h1 className="loc-name">{props.tripsData.item.dropLabel}</h1>
              <h3 className="loc-address">{props.tripsData.item.dropAddress}</h3>
            </>
        } */}
      </div>

    </>
  )
}