import { DndContext, closestCenter } from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable"
import { useContext, useEffect } from "react"
import { AuthContext } from "../../../authContext/AuthContext"
import { SortableItem } from "../../../sortableItem/SortableItem"
import { t } from "i18next"
import TimeIcon from "../../../../assets/icon/ic-view-time.png"
import WeightIcon from "../../../../assets/icon/ic-weight.png"
import { numberFormat } from "../../../numberFormat/numberFormat"

const TripDetailCorporate = ({
  allDataOrder,
  targetTrip,
  targetTripIndex,
  targetRoute,
  handleCancel,
  handleReRoute,
  handleDragEnd,
  popoverIndex,
  optimizeAll
}) => {
  const checkTemp = (temp) => {
    if (temp <= -11) {
      return 'Deep Freeze'
    } else if (temp <= -1) {
      return 'Frozen'
    } else {
      return 'Chiller'
    }
  }
  return (
    <>
      <div className="detail-header">
        <div className="trip-price">
          {allDataOrder.service_name.name.toLowerCase() === 'superkul truck' ?
            <>
              <div className="trip">Trip {targetTripIndex} | <span>{checkTemp(targetTrip.pick.itemTmp)}</span></div>
            </>
            :
            <>
              <div className="trip">Trip {targetTripIndex} | <span>{checkTemp(targetTrip.drop[0].itemTmp)}</span></div>
            </>
          }
          <div className="weights">
            <img src={WeightIcon} alt="" /> {targetTrip.drop.reduce((val, element) => { return val + element.weight }, 0)} Kg & {targetTrip?.drop?.reduce((val, element) => { return Math.ceil(val + (element.width * element.height * element.lenght) / 1000) }, 0)} L
          </div>
        </div>
        {/* <div className="estimation-weights">
          <>
            {optimizeAll ?
              <div className="estimation"><img src={TimeIcon} alt="" />
                {t('distanceEstimation')}: {`${targetTrip.distanceOpt} Km`}
              </div>
              :
              <div className="estimation"><img src={TimeIcon} alt="" />
                {t('distanceEstimation')}: {`${targetTrip.distance} Km`}
              </div>
            }
          </>
        </div> */}
      </div>
      {targetRoute !== '' &&
        <div className={`detail-data ${allDataOrder.service_name.name.toLowerCase() === 'instant delivery' || allDataOrder.service_name.name.toLowerCase() === 'superkul truck' ?
          '' : 'opt'
          }`}>
          <div className="detail-point">
            {targetRoute.map((trip, index) => {
              return (
                <div className={`detail-index ${trip.job === 'pickup' ? 'pick' : ''}`} key={index}>
                  <h1>{index === 0 ? 'P' : index}</h1>
                </div>
              )
            })}
          </div>
          <div className="detail-datas">
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <div className="detail-data-trip">
                <SortableContext
                  items={targetRoute}
                  strategy={verticalListSortingStrategy}
                // disabled
                >
                  {targetRoute.map((trip, i) => {
                    return (
                      <SortableItem
                        key={i}
                        service={allDataOrder.service_name.name}
                        tripsData={trip}
                        popoverIndex={popoverIndex}
                      />
                    )
                  })}
                </SortableContext>
              </div>
            </DndContext>
          </div>
        </div>
      }
      <div className="detail-footer">
        <button className="back btn" onClick={handleCancel}>{t('back').toUpperCase()}</button>
        <button className="save btn" disabled={targetTrip.optimize ? 'disabled' : ''} onClick={handleReRoute}>{t('save').toUpperCase()}</button>
      </div>
    </>
  )
}

export default TripDetailCorporate