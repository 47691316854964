import './Skeleton.scss'

export const LogoSkeleton = (props) => {
  return (
    <img class="skeleton" id="logo-img" alt="" />
  )
}

export const TitleSkeleton = (props) => {
  return (
    <div {...props} class="skeleton skeleton-text"></div>
  )
}

export const DetailSkeleton = (props) => {
  return (
    <div class="card__body body__text" id="card-details">
      <div class="skeleton skeleton-text skeleton-text__body"></div>
    </div>
  )
}

export const BodyImageSkeleton = () => {
  return (
    <div class="card__body body__img">
      <img class="skeleton" alt="" id="cover-img" />
    </div>
  )
}

const FooterSkeleton = () => {
  return (
    <div class="card__footer" id="card-footer">
      <div class="skeleton skeleton-text skeleton-footer"></div>
    </div>
  )
}