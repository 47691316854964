import { sendPostRequestMobile } from "./request-adapter"
import { errorMessage } from "../utils/errorMessage"
import { t } from "i18next"

export const storeHitGoogle = async (id, source) => {

  const payload = {
    customer_user_id: id,
    source
  }

  try {
    const response = await sendPostRequestMobile('/api/api-record', payload)
    console.log(source)
  } catch (error) {
    console.log(error)
    errorMessage(error, t('error'), t('somethingError'), t('close'))
  }
}