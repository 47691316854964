import React, { useEffect, useMemo, useState } from 'react';

// //Initial State and Actions
// const initialState = {
//   id: '',
//   tokenFcm: ''
// };

// const actions = {
//   ADD_ID: "ADD_ID",
// };

// //Reducer to Handle Actions
// const reducer = (state, action) => {
//   switch (action.type) {
//     case actions.ADD_ID:
//       return {
//         id: action.userId
//       };
//     // case actions.REMOVE_TODO_ITEM: {
//     //   const filteredTodoItem = state.todoList.filter(
//     //     (todoItem) => todoItem.id !== action.todoItemId
//     //   );
//     //   return { todoList: filteredTodoItem };
//     // }
//     // case actions.TOGGLE_COMPLETED: {
//     //   const updatedTodoList = state.todoList.map((todoItem) =>
//     //     todoItem.id === action.todoItemId
//     //       ? { ...todoItem, completed: !todoItem.completed }
//     //       : todoItem
//     //   );
//     //   return { todoList: updatedTodoList };
//     // }
//     default:
//       return state;
//   }
// };


// Creating the context object and passing the default values.
export const AuthContext = React.createContext();

export const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("spkl:c015d311v") === null ? false : true)
  const [id, setId] = useState('')
  const [profileId, setProfileId] = useState('')
  const [userType, setUserType] = useState('')
  const [allProfile, setAllProfile] = useState('')
  const [isPassEmpty, setIsPassEmpty] = useState(1)
  const [tokenFcm, setTokenFcm] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [dedicatedData, setDedicatedData] = useState({})
  const [dedicatedEndDate, setDedicatedEndDate] = useState('')

  // const [state, dispatch] = React.useReducer(reducer, initialState);

  // const value = useMemo(
  //   () => ({
  //     ...state,
  //     storeId: (userId) => {
  //       dispatch({ type: actions.ADD_ID, userId });
  //     },
  //   }),
  //   [state, dispatch]
  // );

  const storeUserType = (e) => {
    setUserType(e)
  }

  const storeId = (e) => {
    setId(e)
  }

  const storeProfileId = (e) => {
    setProfileId(e)
  }

  const storeProfileData = (e) => {
    setAllProfile(e)
  }

  const storePass = (e) => {
    setIsPassEmpty(e)
  }

  const storeTokenFcm = (e) => {
    setTokenFcm(e)
  }

  const storeApiKey = (e) => {
    setApiKey(e)
  }

  const storeDedicatedData = (e) => {
    setDedicatedData(e)
  }

  const storeDedicatedEndDate = (e) => {
    setDedicatedEndDate(e)
  }

  const login = () => {
    setIsLoggedIn(true)
    return
  }

  const logout = () => {
    setIsLoggedIn(false)
  }

  return (
    <AuthContext.Provider value={{
      status: isLoggedIn,
      id,
      profileId,
      userType,
      allProfile,
      tokenFcm,
      isPassEmpty,
      apiKey,
      dedicatedData,
      dedicatedEndDate,
      storeApiKey,
      storePass,
      setAllProfile,
      storeUserType,
      storeId,
      storeProfileId,
      storeProfileData,
      storeTokenFcm,
      setAllProfile,
      storeDedicatedData,
      storeDedicatedEndDate,
      login,
      logout,
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}