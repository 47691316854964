import { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import ReactGA from "react-ga4";

import ImportButton from "../../../Component/Button/ImportButton/ImportButton";
import CustomTable from "../../../Component/Table/CustomTable/CustomTable";
import EditIcon from "../../../assets/icon/crossdock/ic-edit.png"
import DeleteIcon from "../../../assets/icon/crossdock/ic-delete.png"
import OrderIcon from "../../../assets/icon/crossdock/ic-order.png"
import ExportIcon from "../../../assets/icon/ic-export.png"

import { sendDeleteRequest, sendGetDownloadRequest, sendGetRequest, sendGetRequestMobile, sendPostDownloadRequest, sendPostMultipartRequest } from "../../../services/request-adapter";
import truncateString from "../../../utils/truncateString";
import { errorMessage } from "../../../utils/errorMessage";

import './styles/index.scss'
import WarningModal from "../../../Component/Modal/WarningModal/WarningModal";
import { Link } from "react-router-dom";
import UploadFile from "../../../Component/input/UploadFile/UploadFile";
import InputText from "../../../Component/input/InputText";
import { Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import InputSelect from "../../../Component/input/InputSelect";
import PrimaryButton from "../../../Component/Button/PrimaryButton/PrimaryButton";
import { AuthContext } from "../../../Component/authContext/AuthContext";
import InputDate from "../../../Component/input/InputDate";
import InputTime from "../../../Component/input/InputTime";
import { addDays, format } from "date-fns";
import { LoadingScreen, LoadingScreenV2 } from "../../../Component/loadingScreen/loadingScreen";
import SuccessModal from "../../../Component/Modal/SuccessModal/SuccessModal";
import { Helmet } from "react-helmet";
import CustomButton from "../../../Component/Button/CustomButton/CustomButton";
import DangerButton from "../../../Component/Button/DangerButton/DangerButton";

const importMethod = [
  {
    value: 1,
    label: 'Import Manual'
  },
  {
    value: 2,
    label: 'Import dengan Alamat Tersimpan'
  }
]

const OrderPlanning = () => {
  const columns = [
    {
      // align: "end",
      title: "Lokasi Pickup",
      dataIndex: "location",
      width: '45%',
      render: (_, allData) => (
        allData?.pickup.pickAddress
      )
    },
    {
      title: "Nama HUB",
      dataIndex: "pool_name",
      width: '13rem',
      render: (_, allData) => (
        allData?.pickup.pickLabel
      )
    },
    {
      title: "Total Drop",
      dataIndex: "total_drop",
      width: '15rem',
      align: "center",
      render: (_, allData) => (
        allData?.pickup.item.length
      )
    },
    {
      title: "Tindakan",
      dataIndex: "id",
      align: 'center',
      render: (id, allData) => (
        <div style={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <img
            src={ExportIcon}
            alt="delete"
            style={{
              cursor: 'pointer',
              width: 'fit-content',
              height: 'fit-content'
            }}
            onClick={() => exportOrderPlanning(allData)}
          />
          <img
            src={DeleteIcon}
            alt="delete"
            style={{
              cursor: 'pointer'
            }}
            onClick={() => setWarningModal({
              visible: true,
              data: allData,
              message: "Apakah anda yakin ingin menghapus data order planning?",
              confirmText: ', Hapus'
            })}
          />
          {/* <PrimaryButton
            text={'Pesan'}
            loading={loading}
            onClick={() => handleOrder(allData.id)}
            icon={OrderIcon}
          // disabled={orderPlanningResult?.data[0]?.pickup?.pickLabel === "DILUAR BATAS HUB SUPERKUL" ? true : false}
          /> */}
          <CustomButton
            color="primary"
            icon={OrderIcon}
            text="Pesan"
            onClick={(e) => { handleOrder(allData.id); e.currentTarget.classList.add("inactive") }}
            loading={loading}
            // inactive={true}
            disabled={allData?.pickup?.pickLabel === "DILUAR BATAS HUB SUPERKUL" ? true : false}
          />
        </div>
      )
    },
  ]

  const auth = useContext(AuthContext)

  const [orderPlanningResult, setOrderPlanningResult] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [selectedService, setSelectedService] = useState('')
  const [vehicleList, setVehicleList] = useState([])
  const [poolList, setPoolList] = useState([])
  const [file, setFile] = useState("")
  const [selectedData, setSelectedData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingScreen, setLoadingScreen] = useState({ visible: false })
  const [successModal, setSuccessModal] = useState({ visible: false })
  const [warningModal, setWarningModal] = useState({ visible: false })
  const [params, setParams] = useState({
    page: 1,
    pageSize: 15
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    clearErrors,
    control,
    formState: { errors }
  } = useForm();

  const getAllServices = async () => {
    setLoading(true)
    try {
      const { data } = await sendGetRequestMobile('/api/general-service')
      const filterData = data.data.filter(e => e.name.toLowerCase() !== 'dedicated delivery')
      const newData = data.data.filter(e => e.name.toLowerCase() !== 'dedicated delivery').map((dat) => {
        return {
          value: dat?.id,
          label: dat?.name
        }
      })
      setServiceList(newData)
      setValue('service_id', newData[0])
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoading(false)
    }
  }

  const getAllVehicle = async () => {
    let endpoint = ''
    setVehicleList([])

    switch (getValues('service_id.label')) {
      case "Super Express":
        endpoint = '/api/vehicle-type-express'
        break;
      case "Superkul Truck":
        endpoint = '/api/vehicle-type-middle'
        break;

      default:
        endpoint = '/api/vehicle-type'
        break;
    }

    setLoading(true)
    try {
      const { data } = await sendGetRequestMobile(`${endpoint}`)
      const newData = data?.data?.map((dat) => {
        return {
          value: dat?.id,
          label: dat?.name
        }
      })
      setVehicleList(newData)
      setValue('vehicle_id', newData[newData.length - 1])
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoading(false)
    }
  }

  const getAllPool = async () => {
    setLoading(true)
    try {
      const { data } = await sendGetRequest(`/pools?limit=0&crossdock=1`)
      const newData = data?.data?.map((dat) => {
        return {
          value: dat?.id,
          label: dat?.name
        }
      })
      setPoolList(newData)
      setValue('pool_id', newData.map((dat) => dat))
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoading(false)
    }
  }

  const getAllOrderPlanningResult = async () => {
    setLoading(true)
    try {
      const { data } = await sendGetRequest(`/order-plannings?page=${params?.page}&limit=${params.pageSize}`)
      setOrderPlanningResult(data)
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoading(false)
    }
  }

  const exportOrderPlanning = async (val) => {
    setLoadingScreen({ visible: true })
    try {
      const res = await sendGetDownloadRequest(`/customer-order-plannings/${val?.id}/export?download=1`, { responseType: "blob", });

      const outputFilename = `${val?.pickup?.pickLabel} ${format(new Date(), "dd-MMM-yyyy")}.xlsx`;

      const url = URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoadingScreen({ visible: false })
    }
  }

  const handleDeleteOrderPlanning = async () => {
    const payload = {
      ids: warningModal?.data?.id ? [warningModal?.data?.id] : selectedData
    }
    setLoadingModal(true)
    try {
      await sendDeleteRequest(`/order-plannings`, payload)
      setSelectedData([])
      getAllOrderPlanningResult()
      setWarningModal({ visible: false })
      setSuccessModal({
        visible: true,
        message: 'Data order planning berhasil dihapus',
        duration: 3000
      })
    } catch (error) {
      errorMessage(error, t('error'), t('somethingWrong'), t('close'))
    } finally {
      setLoadingModal(false)
    }
  }

  const onSubmit = async (val) => {

    if (file === "") {
      errorMessage('', t('error'), 'Pilih file terlebih dahulu!', t('close'))
      return
    }

    const payload = {
      date: val.date,
      time: val.time.value,
      "pool_id[]": val.pool_id?.map((id) => id.value),
      service_id: val.service_id.value,
      service_name: val.service_id.label,
      vehicle_id: val.vehicle_id.value,
      vehicle_name: val.vehicle_id.label,
      file: file
    }

    const endPoint = watch('import_method')?.value === 1 ? `/customer-order-planning/import` : `/customer-order-planning-address/import`

    setLoading(true)
    try {
      const { data } = await sendPostMultipartRequest(endPoint, payload)
      getAllOrderPlanningResult()
      ReactGA.event({
        category: "order_category",
        action: "custweb_order_planning_apply_api_success",
        // label: "start_delivery_label", 
        // value: 99
      });
    } catch (error) {
      ReactGA.event({
        category: "order_category",
        action: "custweb_order_planning_apply_api_error",
        // label: "start_delivery_label", 
        // value: 99
      });
      errorMessage(error, t('error'), error?.response?.data?.message, t('close'))
    } finally {
      setLoading(false)
    }
  }

  const handleOrder = async (id) => {
    setLoadingScreen({
      visible: true,
      title: 'Tunggu sebentar...',
      text: 'Kami sedang memproses order Anda, mohon jangan tutup halaman ini.'
    })
    setTimeout(() => {
      window.open(`${process.env.REACT_APP_SUBWEB_URL}/delivery?crossdock=${id}`, '_blank');
      setLoadingScreen({ visible: false })
      setSuccessModal({
        visible: true,
        message: 'Order anda berhasil diproses.'
      })
    }, 2000)
  }

  const onChangePage = (page) => {
    setParams({ ...params, page: page })
  };

  useEffect(() => {
    getAllOrderPlanningResult()
  }, [params])

  useEffect(() => {
    window.onbeforeunload = () => {
      if (loadingScreen.visible) {
        return 'Are you sure?'
      }
    }
  }, [loadingScreen.visible])

  useEffect(() => {
    getAllVehicle()
  }, [watch('service_id')])

  useEffect(() => {
    getAllServices()
    getAllPool()
    setValue('date', format(addDays(new Date(), 1), 'yyyy-MM-dd'))
    setValue('time', { value: "05:00", label: "05:00" })
  }, [])

  const DeleteButton = () => {
    return (
      <DangerButton
        text={t('delete')}
        onClick={() => {
          setWarningModal({ visible: true, message: 'Yakin?' })
        }}
      // loading={loadingScreen}
      // disabled={selectedFile === null ? true : false}
      />
    )
  }

  return (
    <div className="order-planning">
      {loadingScreen.visible &&
        <LoadingScreenV2
          title={loadingScreen.title}
          text={loadingScreen.text}
        />
      }
      <div className="default-container">
        <div className="title">
          <h1>Order Planning</h1>
          <hr />
          <p>Fitur untuk mencari lokasi pickup terdekat dari titik pengiriman serta untuk mengkonversi dari satuan Stock Keeping Unit (SKU) menjadi satuan Box Keeping Unit (BKS).</p>
        </div>
      </div>
      <br />
      <div className="default-container order-planning-form">
        <div className="title">
          <h1>Formulir Order Planning</h1>
          <hr />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <InputSelect
                label={`Layanan Superkul`}
                labelName="service_id"
                placeholder={'Pilih Layanan Superkul'}
                errors={errors}
                register={register}
                control={control}
                dataList={serviceList}
                closeOnSelect={true}
              />
            </Col>
            <Col>
              <InputSelect
                label={`Kendaraan Superkul`}
                labelName="vehicle_id"
                placeholder={'Pilih Kendaraan Superkul'}
                errors={errors}
                register={register}
                control={control}
                dataList={vehicleList}
                closeOnSelect={true}
              />
            </Col>

          </Row>
          <br />

          <Row>
            <Col>
              <InputDate
                label="Tanggal Intransit"
                labelName="date"
                placeholder="Pilih tanggal"
                errors={errors}
                register={register}
                control={control}
                dataList={vehicleList}
                onChangeDate={(val) => setValue('date', val)}
                defaultDate={addDays(new Date(), 1)}
                minDate={addDays(new Date(), 1)}
              />
            </Col>
            <Col>
              <InputTime
                label={`Jam Intransit`}
                labelName="time"
                placeholder={'Pilih jam intransit'}
                errors={errors}
                register={register}
                control={control}
                closeOnSelect={true}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <InputSelect
                label={`Hub Superkul`}
                labelName="pool_id"
                placeholder={'Pilih Hub Superkul sebagai lokasi Cross Docking'}
                errors={errors}
                register={register}
                control={control}
                dataList={poolList}
                isMulti
              />
            </Col>
            <Col>
              <InputSelect
                label={`Metode Import`}
                labelName="import_method"
                placeholder={'Pilih Metode Import'}
                errors={errors}
                register={register}
                control={control}
                dataList={importMethod}
                closeOnSelect={true}
              />
            </Col>
          </Row>
          <br />
          {watch('import_method') && (
            <Row>
              <Col>
                <h3>Import Template Order Planning</h3>
                <UploadFile
                  setUploadedFile={(val) => setFile(val)}
                />
                <div className="link-order-template" style={{
                  textAlign: 'center'
                }}>
                  <a href={`${process.env.REACT_APP_IMG_URL}${watch('import_method')?.value === 1 ? `/templates/Template+Customer+Order+Planning.xlsx` : `/templates/Template+Customer+Order+Planning+Bookmark+Address.xlsx`}`} target="_blank" rel="noreferrer noopener" className="download-link">Klik disini untuk download template</a >
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col
              className="d-flex justify-content-center mt-5"
            >
              <PrimaryButton
                text={t('apply')}
                loading={loading}
              />
            </Col>
          </Row>
        </form>
      </div>
      <br />
      <div className="default-container order-planning-result">
        <CustomTable
          title="Hasil Order Planning"
          columns={columns}
          // data={orderPlanningResult?.data}
          // totalData={orderPlanningResult?.pagination?.total}
          // pageSize={15}
          data={orderPlanningResult?.data}
          totalData={orderPlanningResult?.meta?.pagination?.total || 1}
          page={orderPlanningResult?.meta?.pagination?.current_page || 1}
          setPage={(size) => setParams({ ...params, page: size })}
          pageSize={orderPlanningResult?.meta?.pagination?.per_page || 15}
          setPageSize={(size) => setParams({ ...params, pageSize: size })}
          onChangePage={onChangePage}
          withPagination="top"
          loading={loading}
          setLoading={setLoading}
          checkboxes
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          MiscButton={[...selectedData.length > 0 ? [DeleteButton] : []]}
        />
        {/* <div className="order-button d-flex justify-content-center">
          <PrimaryButton
            text={'Pesan Pengiriman'}
            loading={loading}
            onClick={handleOrder}
            icon={OrderIcon}
            disabled={orderPlanningResult?.data[0]?.pickup?.pickLabel === "DILUAR BATAS HUB SUPERKUL" ? true : false}
          />
        </div> */}
      </div>
      <SuccessModal
        isOpen={successModal}
        toggle={() => setSuccessModal({ visible: false })}
      />

      <WarningModal
        isOpen={warningModal}
        toggle={() => setWarningModal({ visible: false })}
        onFinish={handleDeleteOrderPlanning}
        loading={loadingModal}
      />
    </div>
  )
};

export default OrderPlanning;