import ImportIcon from '../../../assets/icon/table/ic-import.png'
import './importButton.scss'

const ImportButton = ({ onClick, text }) => {
  return (
    <div className="export-btn-container" onClick={onClick}>
      <img src={ImportIcon} alt="" />
      <p>{text}</p>
    </div>
  )
}

export default ImportButton