import { Button, Spinner } from "reactstrap";
import './customButton.scss'

const CustomButton = (props) => {
  return (
    <Button
      className={`custom-btn ${props.inactive && 'inactive'}`}
      {...props}
      disabled={props?.loading || props?.disabled ? true : false}
      style={{
        display: 'flex',
        gap: '0.3rem'
      }}
    >
      {props.loading &&
        <Spinner size="sm"></Spinner>
      }

      {props?.icon &&
        <img src={props?.icon} alt="" />
      }
      <p style={{ margin: 0 }}>
        {props.text}
      </p>
    </Button>
  )
}

export default CustomButton;