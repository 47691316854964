import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import './Input.scss'
import { t } from "i18next";
import { useEffect } from "react";

const InputSelect = ({ label, labelName, placeholder, errors, register, control, isMulti, dataList, closeOnSelect, onChange }) => {
  return (
    <div className="default-input input-select">
      <label htmlFor={labelName}>{label}</label>
      <Controller
        name={labelName}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            {...field}
            classNamePrefix="input-select-content"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border: 'border: 1px solid #CFD3D5',
                borderRadius: "0.5rem",
                padding: 0,
              }),
            }}
            className={`${errors[labelName] ? 'select-data-error' : 'select-data'}`}
            options={dataList}
            placeholder={placeholder}
            isMulti={isMulti}
            closeMenuOnSelect={closeOnSelect ?? false}
          // onChange={onChange}
          />
        )}
      />
      {errors[labelName]?.type === "required" && <p>{t('fieldRequired')}</p>}
    </div>
  )
}

export default InputSelect;